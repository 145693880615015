import React from 'react';
import styled from 'styled-components';

import { accentBlue, lightestGrey, lightGrey } from '../../theme/palette';

import Header from '../../ui/headers/Header';
import PageHeader from '../../ui/PageHeader';

import {
  Introduction,
  LaunchingDashboard,
  Authentication,
  NewSession,
  Overview,
  ModifySession,
  History,
  StartSession,
  ReviewSession,
  Trainee,
  Troubleshooting,
} from '../text';

const UserGuide = () => {
  const [text, setText] = React.useState(Introduction);
  const [selectedSection, setSelectedSection] = React.useState('Introduction');

  const getComponentFromSectionName = new Map([
    ['Introduction', Introduction],
    ['LaunchingDashboard', LaunchingDashboard],
    ['Authentication', Authentication],
    ['NewSession', NewSession],
    ['SessionOverview', Overview],
    ['ModifySession', ModifySession],
    ['TraineeHistory', History],
    ['StartSession', StartSession],
    ['ReviewSession', ReviewSession],
    ['Trainee', Trainee],
    ['Troubleshooting', Troubleshooting],
  ]);

  const handleClick = (section: string) => {
    // @ts-ignore
    setText(getComponentFromSectionName.get(section));
    setSelectedSection(section);
  };

  return (
    <>
      <Header back />
      <PageHeader title="Instruct IQ User Manual" />
      <Wrapper>
        <Directory>
          <SectionTitle
            value="Introduction"
            isSelected={selectedSection}
            onClick={() => handleClick('Introduction')}
          >
            Introduction
          </SectionTitle>
          <SectionTitle
            value="LaunchingDashboard"
            isSelected={selectedSection}
            onClick={() => handleClick('LaunchingDashboard')}
          >
            Launching the Dashboard
          </SectionTitle>
          <SectionTitle
            value="Authentication"
            isSelected={selectedSection}
            onClick={() => handleClick('Authentication')}
          >
            Authentication & User Management
          </SectionTitle>
          <SectionTitle
            value="NewSession"
            isSelected={selectedSection}
            onClick={() => handleClick('NewSession')}
          >
            Configuring a New Training Session
          </SectionTitle>
          <SectionTitle
            value="SessionOverview"
            isSelected={selectedSection}
            onClick={() => handleClick('SessionOverview')}
          >
            Session Overview
          </SectionTitle>
          <SectionTitle
            value="ModifySession"
            isSelected={selectedSection}
            onClick={() => handleClick('ModifySession')}
          >
            Modifying a Training Session
          </SectionTitle>
          <SectionTitle
            value="TraineeHistory"
            isSelected={selectedSection}
            onClick={() => handleClick('TraineeHistory')}
          >
            Trainee History
          </SectionTitle>
          <SectionTitle
            value="StartSession"
            isSelected={selectedSection}
            onClick={() => handleClick('StartSession')}
          >
            Starting a Training Session
          </SectionTitle>
          <SectionTitle
            value="ReviewSession"
            isSelected={selectedSection}
            onClick={() => handleClick('ReviewSession')}
          >
            Reviewing a Training Session
          </SectionTitle>
          {/* <SectionTitle */}
          {/*  value="Trainee" */}
          {/*  isSelected={selectedSection} */}
          {/*  onClick={() => handleClick('Trainee')} */}
          {/* > */}
          {/*  Trainee Profile */}
          {/* </SectionTitle> */}
          <SectionTitle
            value="Troubleshooting"
            isSelected={selectedSection}
            onClick={() => handleClick('Troubleshooting')}
          >
            Troubleshooting
          </SectionTitle>
        </Directory>
        <Content>
          {text}
        </Content>
      </Wrapper>
    </>
  );
};

export default UserGuide;

const Wrapper = styled.div`
    display: flex;
    height: calc(100% - 153px);
    width: 100%;
    position: absolute;
    left: 0;
    top: 153px;
`;

const Directory = styled.div`
    height: calc(100% - 20px);
    width: 35%;
    margin-top: 20px;
    padding-left: 20px;
    border-right: 1px solid ${lightGrey.string()};
`;

const SectionTitle = styled.div<{value?: string, isSelected: string}>`
    font-size: 16px;
    line-height: 49px;
    color: ${props => (props.value === props.isSelected ? accentBlue.string() : lightestGrey.string())}
`;

const Content = styled.div`
    height: calc(100% - 10px);
    width: 60%;
    margin-top: 10px;
    padding: 0 40px;
    overflow: scroll;
`;
