import React from 'react';
import styled from 'styled-components';
import Plot from './Plotly';
import { white } from '../theme/palette';
import { gradeColors, GradeType } from '../summary/constants';
import LogoSpinnerSVG from './svg/LogoSpinner';

const lineColor = white.darken(0.3).string();

type SpiderGraphProps = {
  competencies: Array<{ grade: number | null, name: string }>,
  instructorScores?: GradeType,
  isLoading?: boolean,
};

const SpiderGraph = (props: SpiderGraphProps) => {
  const { competencies, instructorScores } = props;

  const values: Array<number | null> = [];
  const labels: Array<string | null> = [];
  const colors: Array<string | undefined> = [];

  competencies.forEach(competency => {
    const value = instructorScores
    // @ts-ignore
    && instructorScores[`${competency.key}Grade`]
    // @ts-ignore
    && instructorScores[`${competency.key}Grade`] !== null
      // @ts-ignore
      ? instructorScores[`${competency.key}Grade`]
      : competency.grade;
    values.push(value);
    labels.push(competency.name);
  });
  values.push(
    instructorScores
    // @ts-ignore
    && instructorScores[`${competencies[0].key}Grade`]
    // @ts-ignore
    && instructorScores[`${competencies[0].key}Grade`] !== null
      // @ts-ignore
      ? instructorScores[`${competencies[0].key}Grade`]
      : competencies[0].grade,
  );
  labels.push(competencies[0].name);

  values.forEach(value => {
    colors.push(value ? gradeColors.get(value) : undefined);
  });
  colors.push(colors[0]);

  const config = {
    displayModeBar: false,
    staticPlot: true,
  };

  const data = [
    {
      type: 'scatterpolar',
      mode: 'markers',
      r: [1, 1, 1, 1, 1, 1, 1, 1, 1],
      theta: labels,
      marker: {
        color: lineColor,
        size: 6,
      },
    },
    {
      type: 'scatterpolar',
      mode: 'markers',
      r: [2, 2, 2, 2, 2, 2, 2, 2, 2],
      theta: labels,
      marker: {
        color: lineColor,
        size: 6,
      },
    },
    {
      type: 'scatterpolar',
      mode: 'markers',
      r: [3, 3, 3, 3, 3, 3, 3, 3, 3],
      theta: labels,
      marker: {
        color: lineColor,
        size: 6,
      },
    },
    {
      type: 'scatterpolar',
      mode: 'markers',
      r: [4, 4, 4, 4, 4, 4, 4, 4, 4],
      theta: labels,
      marker: {
        color: lineColor,
        size: 6,
      },
    },
    {
      type: 'scatterpolar',
      r: props.isLoading ? [] : values,
      theta: labels,
      marker: {
        size: 12,
        color: colors,
      },
      line: {
        color: lineColor,
      },
    },
  ];

  const layout = {
    polar: {
      radialaxis: {
        visible: false,
        range: [0, 4],
        showgrid: false,
      },
      angularaxis: {
        showline: false,
        linecolor: 'black',
      },
      visible: true,
      bgcolor: 'transparent',
      color: 'black',
      linecolor: 'black',
    },
    showlegend: false,
    paper_bgcolor: 'transparent',
    plot_bgcolor: 'transparent',
    clickmode: 'none',
    dragmode: false,
    hovermode: false,
    rotation: false,
    height: 600,
    width: 600,
    font: {
      size: 14,
      color: lineColor,
    },
  };

  return (
    <Wrapper>
      <Plot config={config} data={data} layout={layout} />
      {props.isLoading
      && (
      <LoadingCompetencies>
        <LogoSpinnerSVG />
      </LoadingCompetencies>
      )}
    </Wrapper>
  );
};

SpiderGraph.defaultProps = {
  instructorScores: null,
  isLoading: false,
};

export default SpiderGraph;

const Wrapper = styled.div`
  margin: auto;
  position: relative;
`;

const LoadingCompetencies = styled.div`
   height: 200px;
   width: 100%;
   position: absolute;
   top: 50%;
   transform: translateY(-50%);
`;
