import { fromJS, List, Map, Record, RecordOf } from 'immutable';
import { ActivityOptionsType, ActivitiesMapType } from './types';

export interface ActivitiesStore {
  map: ActivitiesMapType,
  currentRunId: number | null,
  currentActivityId: number | null,
  nextActivityId: number | null,
  activityOptions: ActivityOptionsType,
}

export type ImmutableActivitiesStore = RecordOf<ActivitiesStore>;

export const createActivitiesStore = Record<ActivitiesStore>({
  map: Map(),
  currentRunId: null,
  currentActivityId: null,
  nextActivityId: null,
  activityOptions: fromJS({
    approach: Map(),
    takeoff: List(),
  }),
});

const defaultState: ImmutableActivitiesStore = createActivitiesStore();

export default defaultState;
