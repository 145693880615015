import {
  getPlotIndex,
  getMaxValue,
  getMinValue,
  annotation,
  shape,
} from './utils';
import { AnnotationData, PlotlyOptions } from '../types';
import { gradeLevel1 } from '../../../theme/palette';
import { hasStickShaker } from '../../../session/utils';
import { FrameType, INITAL_PITCH_REFERENCE } from '../../../frames/constants';

// Generate flaps annotations.
export const annotationFlaps = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  plotName: string,
) => {
  const flaps: Array<{ index: number, flapPosition: string }> = [];
  const plotIndex = getPlotIndex(plotOptions, plotName);

  // @ts-ignore
  if (plotIndex < 0 || plotOptions.data[plotIndex].y.length < 1) {
    return;
  }

  // @ts-ignore
  plotOptions.data[plotIndex].y.forEach((flapPosition, index) => {
    if (index === 0 || flaps[flaps.length - 1].flapPosition !== flapPosition) {
      flaps.push({ index, flapPosition });
    }
  });

  const annotationData: AnnotationData = {
    text: '0',
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'left',
    yanchor: 'bottom',
    xPosition: 0,
    yPosition: 0,
  };

  flaps.forEach(({ index, flapPosition }) => {
    const xPaperValue = index / frames.length;

    annotationData.text = flapPosition;
    annotationData.xPosition = xPaperValue;
    annotationData.yPosition = Number(flapPosition);
    annotation(plotOptions, annotationData);
  });
};

// Generate 'GEAR DOWN' annotation.
export const annotationGearDown = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  plotName: string,
) => {
  const plotIndex = getPlotIndex(plotOptions, plotName);
  const gearIndex = frames.findIndex(
    ({ data }) => data.gearLvrPos === true || data.gearLvrPos === 1,
  );

  if (plotIndex < 0 || gearIndex < 0) {
    return;
  }

  const lineStart = Number(getMinValue(frames, plotName));
  const xPaperValue = gearIndex / frames.length;

  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'left',
    xPosition: xPaperValue,
    yPosition: lineStart,
    text: 'GEAR DN',
    xStart: xPaperValue,
    xEnd: xPaperValue,
    yStart: lineStart,
    yEnd: Number(frames[gearIndex].data[plotName]),
  };

  annotation(plotOptions, annotationData);
  shape(plotOptions, annotationData);
};

// Generate 'GEAR UP' annotation.
export const annotationGearUp = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  plotName: string,
) => {
  const plotIndex = getPlotIndex(plotOptions, plotName);
  const gearIndex = frames.findIndex(
    ({ data }) => data.gearLvrPos === 0 || data.gearLvrPos === false,
  );

  if (gearIndex < 0 || plotIndex < 0) {
    return;
  }

  const lineEnd = Number(getMaxValue(frames, plotName));
  const xPaperValue = gearIndex / frames.length;

  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'right',
    yanchor: 'bottom',
    xPosition: xPaperValue,
    yPosition: lineEnd,
    text: 'GEAR UP',
    xStart: xPaperValue,
    xEnd: xPaperValue,
    yStart: Number(frames[gearIndex].data[plotName]),
    yEnd: lineEnd,
  };

  annotation(plotOptions, annotationData);
  shape(plotOptions, annotationData);
};

// Generate 'Stick Shaker' annotation
export const annotationStickShaker = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  plotName: string,
  offset: number = 0,
) => {
  const plotIndex = getPlotIndex(plotOptions, plotName);

  // Starting point of stick shaker, relative to "offset".
  const relativeStartIndex = frames
    .slice(offset)
    .findIndex(frame => hasStickShaker(frame));

  if (relativeStartIndex < 0 || plotIndex < 0) {
    return;
  }

  // Ending point of stick shaker, relative to "offset + relativeStartIndex".
  let relativeEndIndex = frames
    .slice(offset + relativeStartIndex)
    .findIndex(frame => !hasStickShaker(frame));

  if (relativeEndIndex < 0) {
    relativeEndIndex = frames.length;
  }

  const lineEnd = Number(getMaxValue(frames, plotName));
  const xStart = (offset + relativeStartIndex) / frames.length;
  const xEnd = (offset + relativeStartIndex + relativeEndIndex) / frames.length;

  // Stick shaker area
  plotOptions.layout.shapes.push({
    fillcolor: gradeLevel1.fade(0.5).string(),
    layer: 'below',
    line: {
      color: gradeLevel1.string(),
      width: 2,
    },
    type: 'rect',
    x0: xStart,
    x1: xEnd,
    xref: 'paper',
    y0: 0,
    y1: lineEnd,
    // @ts-ignore
    yref: plotOptions.data[plotIndex].yaxis,
  });

  plotOptions.layout.annotations.push({
    font: { color: 'white' },
    showarrow: false,
    text: 'SHAKER',
    x: xStart,
    xanchor: 'left',
    xref: 'paper',
    y: lineEnd,
    yanchor: 'bottom',
    // @ts-ignore
    yref: plotOptions.data[plotIndex].yaxis,
  });

  // Recursively add stick-shaker annotations for each occurrence.
  annotationStickShaker(
    frames,
    plotOptions,
    plotName,
    offset + relativeStartIndex + relativeEndIndex,
  );
};

// Generate TR annotation.
export const annotationTR = (frames: FrameType[], plotOptions: PlotlyOptions, aircraftDesignator: string) => {
  const plotIndex = getPlotIndex(plotOptions, 'eng_1N1');
  const trIndex = aircraftDesignator === 'A320'
    ? frames.findIndex(
      ({ data }) => data.eng_1Tla <= -1 || data.eng_2Tla <= -1,
    )
    : frames.findIndex(
      ({ data }) => data.eng_1ThrustRevLeverPos > 0
      || data.eng_2ThrustRevLeverPos > 0,
    );

  if (trIndex < 0 || plotIndex < 0) {
    return;
  }

  const eng1MaxValue = Number(getMaxValue(frames, 'eng_1N1'));
  const eng2MaxValue = Number(getMaxValue(frames, 'eng_2N1'));
  const lineEnd = eng1MaxValue > eng2MaxValue ? eng1MaxValue : eng2MaxValue;

  const lineStart = frames[trIndex].data.eng_1N1 > frames[trIndex].data.eng_2N1
    ? frames[trIndex].data.eng_1N1
    : frames[trIndex].data.eng_2N1;

  const xPaperValue = trIndex / frames.length;

  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'left',
    xPosition: xPaperValue,
    yPosition: lineEnd,
    text: 'TR',
    xStart: xPaperValue,
    xEnd: xPaperValue,
    yStart: lineStart,
    yEnd: lineEnd,
  };

  annotation(plotOptions, annotationData);
  shape(plotOptions, annotationData);
};

// Generate V1 annotation.
export const annotationV1 = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
) => {
  const plotIndex = getPlotIndex(plotOptions, 'eng_1N1');
  const v1Index = frames.findIndex(({ data }) => data.cas > data.v1);

  if (v1Index < 0 || plotIndex < 0) {
    return;
  }

  const eng1MinValue = getMinValue(frames, 'eng_1N1');
  const eng2MinValue = getMinValue(frames, 'eng_2N1');
  const lineStart = Number(eng1MinValue < eng2MinValue ? eng1MinValue : eng2MinValue);

  const lineEnd = frames[v1Index].data.eng_1N1 > frames[v1Index].data.eng_2N1
    ? frames[v1Index].data.eng_1N1
    : frames[v1Index].data.eng_2N1;

  const xPaperValue = v1Index / frames.length;

  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'right',
    yanchor: 'bottom',
    xPosition: xPaperValue,
    yPosition: lineStart,
    text: 'V1',
    xStart: xPaperValue,
    xEnd: xPaperValue,
    yStart: lineStart,
    yEnd: lineEnd,
    color: 'red',
  };

  annotation(plotOptions, annotationData);
  shape(plotOptions, annotationData);
};

// Generate V2 annotation.
export const annotationV2 = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  plotName: string,
) => {
  const plotIndex = getPlotIndex(plotOptions, plotName);
  const flapIndex = frames.findIndex(({ data }) => data.flapLeverPos < 0.1);
  const wowIndex = frames.findIndex(({ data }) => !data.wowAny);

  if (wowIndex < 0 || plotIndex < 0) {
    return;
  }

  const xEnd = flapIndex < 0 ? 1 : flapIndex / frames.length;

  const xPaperValue = wowIndex / frames.length;
  const { v2 } = frames[wowIndex].data;

  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'left',
    yanchor: 'top',
    xPosition: xPaperValue,
    yPosition: v2,
    text: 'V2',
    xStart: xPaperValue,
    xEnd,
    yStart: v2,
    yEnd: v2,
  };

  annotation(plotOptions, annotationData);
  shape(plotOptions, annotationData);
};

// Generate VR annotation.
export const annotationVR = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  plotName: string,
) => {
  const plotIndex = getPlotIndex(plotOptions, plotName);
  const vrIndex = frames.findIndex(({ data }) => data.cas > data.vr);

  if (vrIndex < 0 || plotIndex < 0) {
    return;
  }

  const lineEnd = Number(getMaxValue(frames, plotName));
  const xPaperValue = vrIndex / frames.length;

  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'right',
    xPosition: xPaperValue,
    yPosition: lineEnd,
    text: 'VR',
    xStart: xPaperValue,
    xEnd: xPaperValue,
    yStart: Number(frames[vrIndex].data[plotName]),
    yEnd: lineEnd,
  };

  annotation(plotOptions, annotationData);
  shape(plotOptions, annotationData);
};

// Generate TD annotation.
export const annotationTD = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  plotName: string,
) => {
  const plotIndex = getPlotIndex(plotOptions, plotName);
  const wowIndex = frames.findIndex(({ data }) => data.wowAny === true || data.wowAny === 1.0);
  const lineStart = Number(getMinValue(frames, plotName));
  if (wowIndex < 0 || plotIndex < 0) {
    return;
  }
  const lineEnd = Number(getMinValue(frames, plotName));
  const xPaperValue = wowIndex / frames.length;
  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'right',
    xPosition: xPaperValue,
    yPosition: lineStart,
    text: 'TD',
    xStart: xPaperValue,
    xEnd: xPaperValue,
    yStart: 0,
    yEnd: lineEnd,
  };

  annotation(plotOptions, annotationData);
  shape(plotOptions, annotationData);
};

// Generate VRef annotation.
export const annotationVRef = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  plotName: string,
) => {
  const plotIndex = getPlotIndex(plotOptions, plotName);
  const wowIndex = frames.findIndex(({ data }) => data.wowAny === true || data.wowAny === 1.0);

  if (plotIndex < 0 || wowIndex < 0) {
    return;
  }

  const xPaperValue = wowIndex / frames.length;

  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'right',
    yanchor: 'bottom',
    xPosition: xPaperValue,
    yPosition: frames[0].data.vref,
    text: 'VREF',
    xStart: 0,
    xEnd: xPaperValue,
    yStart: frames[0].data.vref,
    yEnd: frames[0].data.vref,
  };

  annotation(plotOptions, annotationData);
  shape(plotOptions, annotationData);
};

export const annotationVApp = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  plotName: string,
) => {
  // VApp only applies to some aircraft. As a rule of thumb, it will always be
  // near VRef.
  if (frames.length < 1
    || !frames[0].data.vapp
    || frames[0].data.vapp < 0
    || !frames[0].data.vref
    || frames[0].data.vapp > 2 * frames[0].data.vref
  ) {
    return;
  }

  const plotIndex = getPlotIndex(plotOptions, plotName);
  const wowIndex = frames.findIndex(({ data }) => data.wowAny === true || data.wowAny === 1.0);

  if (plotIndex < 0 || wowIndex < 0) {
    return;
  }

  const xPaperValue = wowIndex / frames.length;

  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    xanchor: 'right',
    yanchor: 'bottom',
    xPosition: xPaperValue,
    yPosition: frames[0].data.vapp,
    text: 'VAPP',
    xStart: 0,
    xEnd: xPaperValue,
    yStart: frames[0].data.vapp,
    yEnd: frames[0].data.vapp,
  };
  annotation(plotOptions, annotationData);
  shape(plotOptions, annotationData);
};

export const annotationInitialPitchReference = (
  plotOptions: PlotlyOptions,
  plotName: string,
) => {
  const plotIndex = getPlotIndex(plotOptions, plotName);

  const annotationData: AnnotationData = {
    yaxis: plotOptions.data[plotIndex].yaxis,
    text: 'Initial Pitch',
    xStart: 0,
    xEnd: 1,
    yStart: INITAL_PITCH_REFERENCE,
    yEnd: INITAL_PITCH_REFERENCE,
    color: '#043f9d',
    dash: 'solid',
  };

  shape(plotOptions, annotationData);
};
