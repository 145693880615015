import { call, takeLatest, put } from 'redux-saga/effects';
import * as actions from './actions';
import api from '../api';
import { getInstructorInfo } from '../instructor/actions';

export function* getUserInfo() {
  try {
    const results = yield call(api.auth.getUserInformation);
    if (results) {
      yield put(actions.setUserInfo(results.username, results.id));
      yield put(getInstructorInfo());
    }
    return results;
  } catch (error) {
    yield call(api.logError, error);
    return {};
  }
}

/**
 * Watchers.
 */
export default {
  * watchAuthenticate(): Generator<any, void, void> {
    yield takeLatest(actions.GET_USER_INFO, getUserInfo);
  },
};
