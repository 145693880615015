// @ts-nocheck
import React from 'react';
import Tippy from '@tippy.js/react';
import styled from 'styled-components';

import GradeSelector from '../GradeSelector';
import { getColor, GradeLevelType, GradingSchemeType } from '../../constants';
import { useGradingScheme } from '../../hooks';
import popoverTheme from '../../../theme/popovers';
import { TippyThemes } from '../../../ui/Tippy';
import { NoteType } from '../../../activities/types';

type GradeLevelHandlerType = (level: GradeLevelType) => void;

type GradeInputProps = {
  gradeValue?: GradeLevelType | null,
  isDisabled?: boolean,
  isPredicted?: boolean,
  activityId?: number,
  type?: string,
  isDemo?: boolean,
  onShowGradeContext?: () => void,
  onShowCompetencyDrilldown?: () => void,
  onUpdateGradeLevel?: GradeLevelHandlerType | null,
  onSelectDemo?: Function,
  onSaveNote?: Function,
  note?: NoteType | null,
};

const GradeInput = ({
  gradeValue,
  isDisabled,
  isPredicted,
  activityId,
  type,
  isDemo,
  onShowGradeContext,
  onShowCompetencyDrilldown,
  onUpdateGradeLevel,
  onSelectDemo,
  onSaveNote,
  note,
}: GradeInputProps) => {
  const popoverRef = React.useRef();
  // @ts-ignore
  const hidePopover = () => popoverRef.current && popoverRef.current.hide();
  const displayValue = (isDemo ? 'D' : '') || gradeValue || (isPredicted ? '-' : '');
  const gradingScheme = useGradingScheme();

  if (typeof onUpdateGradeLevel !== 'function') {
    return (
      <InputButton
        gradeValue={gradeValue}
        gradingScheme={gradingScheme}
        isPredicted={isPredicted}
        isDisabled
      >
        {displayValue}
      </InputButton>
    );
  }

  const handleSelectGradeLevel: GradeLevelHandlerType = updatedLevel => {
    onUpdateGradeLevel(updatedLevel);
    hidePopover();
  };

  const handleShowGradeContext = typeof onShowGradeContext === 'function'
    ? () => {
      onShowGradeContext();
      hidePopover();
    }
    : null;

  const handleShowCompetencyDrilldown = typeof onShowCompetencyDrilldown === 'function'
    ? () => {
      onShowCompetencyDrilldown();
      hidePopover();
    }
    : null;

  return (
    <>
      <TippyThemes />
      <Tippy
        {...popoverTheme}
        onCreate={ref => { popoverRef.current = ref; }}
        content={(
          <GradeSelector
            gradeValue={gradeValue}
            isLiveGrade={false}
            activityId={activityId}
            type={type}
            isDemo={isDemo}
            onSelectGrade={handleSelectGradeLevel}
            onSelectDemo={onSelectDemo}
            onShowGradeContext={handleShowGradeContext}
            onShowCompetencyDrilldown={handleShowCompetencyDrilldown}
            onSaveNote={onSaveNote}
            note={note}
          />
      )}
      >
        <InputButton
          gradeValue={gradeValue}
          gradingScheme={gradingScheme}
          isDemo={isDemo}
          isPredicted={isPredicted}
          isDisabled={isDisabled}
        >
          {displayValue}
        </InputButton>
      </Tippy>
    </>
  );
};

GradeInput.defaultProps = {
  gradeValue: null,
  isDisabled: false,
  isPredicted: false,
  activityId: undefined,
  type: undefined,
  isDemo: false,
  onShowGradeContext: null,
  onShowCompetencyDrilldown: null,
  onUpdateGradeLevel: null,
  onSelectDemo: null,
  onSaveNote: null,
  note: null,
};

type InputButtonType = GradeInputProps & {
  gradingScheme: GradingSchemeType,
};

const getBackgroundColor = (props: InputButtonType & {theme: Object}) => {
  switch (true) {
    case props.isPredicted:
      return 'transparent';

    case props.isDemo:
      return 'white';

    case !!props.gradeValue:
      return getColor(props.gradingScheme, props.gradeValue, 'transparent');

    default:
      return 'transparent';
  }
};

const getBorderColor = (props: InputButtonType) => {
  switch (true) {
    case props.isDemo:
      return 'white';

    case !!props.gradeValue:
      return getColor(props.gradingScheme, props.gradeValue, 'transparent');

    default:
      return 'gray';
  }
};

export const InputButton = styled.div<InputButtonType>`
  background-color: ${props => getBackgroundColor(props)};
  border: 1px solid ${props => getBorderColor(props)};
  color: ${props => (props.isPredicted ? props.theme.textColor.string() : 'black')};
  cursor: ${props => (props.isDisabled ? 'inherit' : 'pointer')};
  text-align: center;
  line-height: 41px;
  display: block;
  height: 41px;
  width: 41px;
  border-radius: 50%;
  margin: 0 0.3rem;
`;

export default GradeInput;
