/**
 * Various form elements.
 *
 * @note Split new elements into their own files.
 * @todo Move existing elements to their own files as well.
 */
import * as React from 'react';
import styled from 'styled-components';

interface PickerProps {
  onChange?: (event: React.ChangeEvent) => void;
  value?: string;
}

interface InputProps {
  label: string,
  type?: string,
  [key: string]: any;
}

// Grid containers.
const Column = styled.div<{ padding?: string, width?: string }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: ${props => props.width || '200px'};
  padding: ${props => props.padding || '1em'};
`;

export const Row = styled.div<{ flexGrow?: number, padding?: string }>`
  display: flex;
  flex-grow: ${props => props.flexGrow};
  align-items: center;
  padding: ${props => props.padding || '1em'};
`;

Row.defaultProps = {
  flexGrow: 1,
};

// Labels
export const Label = styled.label`
  color: ${props => props.theme.textColor.string()};
`;

// Inputs
export const Input = styled.input<{ as?: string, height?: string, resize?: string }>`
  appearance: none;
  background-color: transparent;
  border: 1px solid ${props => props.theme.textColor.darken(0.4).string()};
  border-radius: ${props => props.theme.borderRadius};
  box-sizing: border-box;
  color: ${props => props.theme.textColor.string()};
  text-align: left;
  transition: all ${props => props.theme.transitionTime};

  margin: 10px 20px 10px 0;
  padding: 0.7em 1em;

  &:disabled {
    border-color: ${props => props.theme.textColor.darken(0.7).string()};
    color: ${props => props.theme.textColor.darken(0.4).string()};
  }

  &:active,
  &:hover {
    border-color: ${props => props.theme.textColor.string()};
  }

  // Textarea-specific options
  ${props => (props.height ? `min-height: ${props.height};` : '')};
  ${props => (props.resize ? `resize: ${props.resize};` : '')};
`;

export const InputGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DatePicker = (props: PickerProps) => {
  const { value, ...inputProps } = props;

  // Control the date value.
  const [date, setDate] = React.useState(value);

  // onChange handler.
  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setDate(target.value);

    if (typeof props.onChange === 'function') {
      props.onChange(event);
    }
  };

  return (
    <Input
      {...inputProps}
      type="date"
      value={date}
      onChange={handleChange}
    />
  );
};

DatePicker.defaultProps = {
  onChange: null,
  value: '',
};

export const TimePicker = (props: PickerProps) => {
  const { value, ...inputProps } = props;

  // Control the time value.
  const [time, setTime] = React.useState(value);

  // onChange handler.
  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setTime(target.value);

    if (typeof props.onChange === 'function') {
      props.onChange(event);
    }
  };

  return (
    <Input
      {...inputProps}
      value={time}
      type="time"
      onChange={handleChange}
    />
  );
};

TimePicker.defaultProps = {
  onChange: null,
  value: '',
};

// Higher order components
export const ColumnInput = (props: InputProps) => {
  const { label, type, ...inputProps } = props;

  // Render different inputs based on the type.
  let input;

  switch (type) {
    case 'date':
      input = <DatePicker {...inputProps} />;
      break;

    case 'time':
      input = <TimePicker {...inputProps} />;
      break;

    default:
      input = <Input {...inputProps} type={type} />;
  }

  return (
    <Column>
      <Label>{label}</Label>

      {input}
    </Column>
  );
};

ColumnInput.defaultProps = {
  type: 'text',
};

type ColumnTextAreaProps = {
  label: string,
  height?: string,
  resize?: string,
};

export const ColumnTextArea = (props: ColumnTextAreaProps) => {
  const {
    label,
    height,
    resize,
    ...inputProps
  } = props;

  return (
    <Column>
      <Label>{label}</Label>

      <Input
        {...inputProps}
        height={height && height.length ? height : '160px'}
        resize={resize && resize.length ? resize : 'vertical'}
        as="textarea"
      />
    </Column>
  );
};

ColumnTextArea.defaultProps = {
  height: '',
  resize: '',
};

type OptionType = {
  id: number | string,
};

type ColumnSelectProps = {
  label: string,
  options: OptionType[],
  value?: string | number,
  getOptionValue?: Function,
  getOptionLabel?: Function,
  [key: string]: any,
};

/**
 * Default getter for retrieving the value from a select option.
 */
const defaultGetValue = (option: OptionType): string | number => option.id;

export const ColumnSelect = (props: ColumnSelectProps) => {
  const {
    label,
    options,
    getOptionLabel,
    getOptionValue,
    ...selectProps
  } = props;

  // Getters
  const getValue = typeof getOptionValue === 'function'
    ? getOptionValue
    : defaultGetValue;

  const getLabel = typeof getOptionLabel === 'function'
    ? getOptionLabel
    : getValue;

  // Select options
  const selectOptions = Array.isArray(options) ? options : [];

  return (
    <Column>
      <Label>{label}</Label>

      <Input {...selectProps} as="select">
        {selectOptions.map(option => {
          const optionValue = getValue(option);
          const optionLabel = getLabel(option);

          return (
            <option key={optionValue} value={optionValue}>
              {optionLabel}
            </option>
          );
        })}
      </Input>
    </Column>
  );
};

ColumnSelect.defaultProps = {
  value: '',
  getOptionValue: (option: OptionType): string | number => option.id,
  getOptionLabel: (option: OptionType): string | number => option.id,
};
