import { fromJS } from 'immutable';
import defaultState, { ImmutableRecommendationsStore } from './store';
import * as actions from './actions';

export default (
  state: ImmutableRecommendationsStore = defaultState,
  action: actions.RecommendationsAction,
): ImmutableRecommendationsStore => {
  switch (action.type) {
    case actions.UPDATE_ACTIVITY_RECOMMENDATIONS:
      return state.set('activityRecommendations', fromJS(action.payload.activityRecommendations));
    case actions.SUBMIT_INSTRUCTOR_FEEDBACK:
      return state.setIn(['activityRecommendations', 'instructorApproved'], action.payload.instructorFeedback);

    default:
      return state;
  }
};
