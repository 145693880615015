import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Login from './Login';
import * as actions from '../../actions';
import { AuthStore } from '../../store';
import { GeneralStoreType } from '../../../store/constants';

const mapStateToProps = (state: GeneralStoreType): AuthStore => {
  const auth = state.get('auth');

  return {
    isInstructorAdmin: auth.get('isInstructorAdmin'),
    isAuthenticating: auth.get('isAuthenticating'),
    user: auth.get('user'),
    status: auth.get('status'),
    token: auth.get('token'),
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  onAuthenticate: (username: string, password: string) => dispatch(actions.authenticate(username, password)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(Login));
