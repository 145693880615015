import { TYPE_PLOTLY, InsightType, VisualizationHandlerType } from '../constants';
import getDefaultOptions from '../../plotly';

const getDefaultVisualizations: VisualizationHandlerType<InsightType> = (
  activity,
  data,
  markers,
  metaData,
) => ([{
  type: TYPE_PLOTLY,
  data: getDefaultOptions(activity, data.frames, markers, metaData),
}]);

export default getDefaultVisualizations;
