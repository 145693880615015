import { VisualizationMetaDataType } from '../visualization/constants';
import { AircraftType } from '../../common/aircraftType';
import { AircraftDesignator } from '../../common/aircraftDesignator';

export const getEngineParam = (aircraftDesignator: string, aircraftType: string | null | undefined) => {
  if ((aircraftDesignator === AircraftDesignator.HELICOPTER_H145_135)
    || (aircraftType && aircraftType === AircraftType.prop)) {
    return ['eng_1Trq', 'eng_2Trq'];
  }
  return ['eng_1N1', 'eng_2N1'];
};

export const getRejectedTakeoffBrakeParam = (aircraftDesignator: string) => (aircraftDesignator === 'A320'
  ? ['brakePedPositionPilotL', 'brakePedPositionPilotR']
  : ['brakePedForcePilotL', 'brakePedForcePilotR']);

export const getLandingPlotParams = (metaData: VisualizationMetaDataType) => {
  const plotParamsMap = new Map([
    [AircraftDesignator.HELICOPTER_H145_135, ['radalt', 'gndSpeed', 'pitch', getEngineParam(metaData.aircraftDesignator, metaData.aircraftType)]],
    ['A320', [
      'gndSpeed',
      ['brakeTemp_1Ind', 'brakeTemp_2Ind', 'brakeTemp_3Ind', 'brakeTemp_4Ind'],
      ['brakePedPositionPilotL', 'brakePedPositionPilotR'],
      getEngineParam(metaData.aircraftDesignator, metaData.aircraftType)],
    ],
  ]);

  return plotParamsMap.get(metaData.aircraftDesignator) || [
    'gndSpeed',
    ['brakeTempRatioL', 'brakeTempRatioR'],
    ['brakePedForcePilotL', 'brakePedForcePilotR'],
    getEngineParam(metaData.aircraftDesignator, metaData.aircraftType),
  ];
};
