import { List, Record, RecordOf } from 'immutable';

import { EquipmentListType } from './constants';
import { defaultGradingScheme, GradingSchemeType } from '../summary/constants';

export type DashboardStore = {
  equipment: EquipmentListType,
  gradingScheme: GradingSchemeType,
  trainingCenter: string | null,
};

export type ImmutableDashboardStore = RecordOf<DashboardStore>;

const createDashboardStore = Record<DashboardStore>({
  equipment: List(),
  gradingScheme: defaultGradingScheme,
  trainingCenter: null,
});

const defaultState: ImmutableDashboardStore = createDashboardStore();

export default defaultState;
