import moment from 'moment';
import * as React from 'react';
import styled, { StyledProps } from 'styled-components';

import config from '../../../config';
import {
  textColor,
  accent,
  mediumGrey,
  lightGrey,
  lightestGrey, mediumLightGrey,
} from '../../../theme/palette';
import SelectableGrades from '../../../summary/components/GradeSelector';
// noinspection TypeScriptPreferShortImport
import { DynamicColumnSelect } from '../../../ui/form/DynamicSelect';
import MarkerButton from './MarkerButton';
import MalfunctionTag from './MalfunctionTag';
import Repeat from './Repeat';
import Next from './Next';
import Timer from './Timer';
import { NotesTextArea } from '../../../ui/Notes';

import { Props } from './types';
import { ActivityOptionsType, ActivityOptionType, NoteType } from '../../types';
import { GradeLevelType } from '../../../summary/constants';

type State = {
  isTiming: boolean,
  isRepeat: boolean,
};

export default class Activity extends React.Component<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state: State = {
    isTiming: false,
    isRepeat: false,
  };

  componentWillUnmount = () => {
    this.handleCancelTimer(true);
  };

  timerID: ReturnType<typeof setTimeout> | null = null;

  /**
   * Starts the timer for launching a new activity.
   */
  handleStartTimer = () => {
    this.setState(() => ({ isTiming: true }));
    this.timerID = setTimeout(
      this.handleLaunchActivity,
      config.activityLaunchTimeout,
    );
  };

  /**
   * Resets the timer for launching a new activity.
   */
  handleCancelTimer = (isUnmounting: boolean = false) => {
    // Exit early if we don't have any timers to clear.
    if (!this.timerID) {
      return;
    }

    // Clear the timeout and set it to null, so we can easily check whether it
    // has been cleared or not.
    clearTimeout(this.timerID);
    this.timerID = null;

    // Update the state flag.
    if (isUnmounting !== true) {
      this.setState(() => ({ isTiming: false }));
    }
  };

  plainCancelTimer = () => this.handleCancelTimer();

  handleLaunchActivity = () => {
    this.handleCancelTimer();

    if (this.props.startedAt) {
      this.props.onRepeatActivity(this.props.id, moment().toISOString());
    } else if (this.props.id) {
      this.props.onLaunchActivity(this.props.id);
    } else {
      this.props.onCreateActivity(
        this.props.currentRunId,
        this.props.placeholderId,
        {
          name: this.props.name,
          startedAt: moment().toISOString(),
        },
      );
    }
  };

  handleRepeatActivity = () => {
    this.setState({ isRepeat: true });
    this.handleLaunchActivity();
  };

  handleChangeActivity = (activity: ActivityOptionType) => {
    this.props.onChangeActivity(activity, this.props.id, this.props.currentRunId);
  };

  getLabel = (activity: ActivityOptionType): string => activity.name;

  getValue = (activity: ActivityOptionType): string => activity.code;

  getOptions = (options: ActivityOptionsType, activityCode: string) => {
    if (activityCode.substr(0, 8) === 'APP_ILS_'
        || activityCode.substr(0, 8) === 'APP_LPV_'
        || activityCode.substr(0, 14) === 'APP_PRECISION_') {
      return options.approach.precision;
    }
    if (activityCode.substr(0, 8) === 'TAKEOFF_') {
      return options.takeoff;
    }
    return options.approach.nonPrecision;
  };

  getActiveHeader = () => {
    const {
      activityCode,
      activityOptions,
      name,
      onLaunchNextActivity,
    } = this.props;
    return (
      <ActiveHeading data-active>
        <HeadingSpan>
          {Object.keys(activityOptions).length > 0
            && (
              (activityCode && activityCode.substr(0, 4) === 'APP_')
                || (activityCode && activityCode.substr(0, 8) === 'TAKEOFF_')
            ) ? (
              <DynamicColumnSelect
                options={this.getOptions(activityOptions, activityCode)}
                defaultValue={{ name, code: activityCode }}
                getOptionLabel={this.getLabel}
                getOptionValue={this.getValue}
                isSearchable={false}
                maxMenuHeight={250}
                isLive
                onChange={this.handleChangeActivity}
              />
            )
            : name}
        </HeadingSpan>
        <Next onClick={onLaunchNextActivity} />
      </ActiveHeading>
    );
  };

  getInactiveHeader = () => {
    const isEnded = !!this.props.endedAt;
    const { isTiming } = this.state;
    const { isPlaceholder, id, predictedActivityId } = this.props;
    const isPredicted = id === predictedActivityId;
    let displayName = this.props.name;

    if (isPlaceholder && isTiming) {
      // While pressing and holding on the activity header, show no
      // display name at all for placeholders.
      displayName = '';
    } else if (isPlaceholder && !isEnded) {
      // If a placeholder activity has ended, show the "Unlabeled Activity"
      // label. Else, show the "Add Activity" label.
      displayName = '-- Add Activity --';
    }

    return (
      <InactiveHeading
        isEnded={isEnded}
        isPlaceholder={isPlaceholder}
        isPredicted={isPredicted}
        isTiming={isTiming}
        onMouseDown={this.handleStartTimer}
        onMouseUp={this.plainCancelTimer}
        onMouseLeave={this.plainCancelTimer}
        onTouchStart={this.handleStartTimer}
        onTouchEnd={this.plainCancelTimer}
        onTouchCancel={this.plainCancelTimer}
        data-active={false}
      >
        <HeadingSpan>
          {displayName}
        </HeadingSpan>

        {this.state.isTiming && (<Timer repeat={isEnded} />)}
      </InactiveHeading>
    );
  };

  render = () => {
    const {
      isActive,
      malfunctions,
      onSelectGrade,
      onSelectDemo,
      grade,
      isDemo,
      currentRunId,
      repeatCount,
      onSaveNote,
    } = this.props;

    const handleSelectGradeLevel = (level: GradeLevelType) => {
      if (typeof onSelectGrade === 'function') {
        onSelectGrade(grade, level);
      }
    };

    const handleSelectDemo = (activityId: number) => {
      if (typeof onSelectDemo === 'function') {
        onSelectDemo(activityId);
      }
    };

    const handleSaveNote = (text: string, note: NoteType) => {
      if (typeof onSaveNote === 'function') {
        onSaveNote(grade.activityId, text, note);
      }
    };

    // Render header based on whether activity is running or not.
    const header = isActive
      ? this.getActiveHeader()
      : this.getInactiveHeader();

    return (
      <Wrapper
        isActive={isActive}
        data-active-card={isActive}
      >
        {header}

        {isActive
          && (
          <VisualsWrapper>
            {malfunctions && (
            <MalfunctionsWrapper>
              <MalfunctionsTitle>
                <div>Status</div>
                <div>{`Attempt #${this.props.repeatCount + 1}`}</div>
              </MalfunctionsTitle>
              <Line />
              {malfunctions.map((name, index) => (
                <>
                  <MalfunctionTag key={name} name={name} />
                  {index !== malfunctions.length - 1 && <Line />}
                </>
              ))}
            </MalfunctionsWrapper>
            )}

            <ActionsWrapper>
              <EventsWrapper>
                <Repeat
                  onClick={this.handleRepeatActivity}
                  repeatCount={repeatCount}
                  isRepeat={this.state.isRepeat}
                />
                <MarkerButton sessionRunId={currentRunId} />
              </EventsWrapper>
              <AssessmentWrapper>
                <InstructorGradesWrapper>
                  <SelectableGrades
                    gradeValue={grade.gradeValue}
                    activityId={grade.activityId}
                    type="activity"
                    isDemo={isDemo}
                    onSelectDemo={handleSelectDemo}
                    onSelectGrade={handleSelectGradeLevel}
                    onShowGradeContext={null}
                    onShowCompetencyDrilldown={null}
                    isLiveGrade
                  />
                </InstructorGradesWrapper>
                <NotesTextArea onSaveNote={handleSaveNote} note={this.props.note} />
              </AssessmentWrapper>
            </ActionsWrapper>
          </VisualsWrapper>
          )}
      </Wrapper>
    );
  };
}

// Supporting components.
const Wrapper = styled.div<{ isActive?: boolean }>`
  width: ${props => (props.isActive ? '718px' : '658px')};
  margin: 10px auto;
  user-select: none;
  border: 2px solid transparent;
  border-radius: 5px;
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0px 5px 0 20px;
  user-select: none;
`;

const ActiveHeading = styled(Heading)`
  background:
    linear-gradient(
      to right,
      ${props => props.theme.primary.toString()},
      ${props => props.theme.tertiary.toString()}
    ) padding-box,
    linear-gradient(
      to right,
      ${props => props.theme.primary.string()} 20%,
      ${props => props.theme.tertiary.string()}
    ) border-box;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

type InactiveHeadingProps = {
  isEnded: boolean,
  isTiming: boolean,
  isPlaceholder?: boolean,
  isPredicted?: boolean,
  onMouseDown?: () => void,
  onMouseUp?: () => void,
  onMouseLeave?: () => void,
  onTouchStart?: () => void,
  onTouchEnd?: () => void,
  onTouchCancel?: () => void,
};

const getBackgroundColor = (
  { isPredicted, isTiming, isEnded, theme }: StyledProps<InactiveHeadingProps>,
) => {
  let color = textColor.darken(0.95).string();

  if (isEnded) {
    color = textColor.darken(0.9).string();
  }

  if (isTiming) {
    color = accent.string();
  }

  if (isPredicted) {
    color = `
      linear-gradient(${color}, ${color}) padding-box,
      linear-gradient(to right, ${theme.primary.string()}, ${theme.secondary.string()}) border-box;
    `;
  }

  return color;
};

export const InactiveHeading = styled(Heading)<InactiveHeadingProps>`
  background: ${props => getBackgroundColor(props)};
  border: 4px solid transparent;
  border-radius: 2px;
  color: ${props => (props.isTiming
    ? props.theme.textColor.darken(0.9).string()
    : props.theme.textColor.darken(0.4).string())
};
  cursor: pointer;
  transition: background ${props => props.theme.transitionTime},
    color ${props => props.theme.transitionTime};
  text-align: ${props => (props.isPlaceholder ? 'center' : '')}
`;

const HeadingSpan = styled.span`
  flex-grow: 1;
`;

const VisualsWrapper = styled.div`
  background-color: ${mediumGrey.string()};
  display: flex;
  position: relative;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const MalfunctionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 16px;
  color: ${lightestGrey.string()};
  width: 329px;
  min-height: 224px;
  background-color: ${mediumLightGrey.string()};
  border-radius: 5px;
  margin: 20px;
`;

const MalfunctionsTitle = styled.div`
  line-height: 44px;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
`;

const Line = styled.hr`
  width: 100%;
  padding: 0;
  margin: 0;
  border: 0;
  height: 1px;
  background-color: ${lightGrey.string()}
`;

const ActionsWrapper = styled.div`
  width: 329px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0;
`;

const EventsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AssessmentWrapper = styled.div`
  background-color: ${mediumLightGrey.string()};
  border-radius: 5px;
  height: 139px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 15px 0 15px;
`;

const InstructorGradesWrapper = styled.div`
  color: ${props => props.theme.textColor.string()};
  text-align: center;
  display: flex;
  width: auto;
`;
