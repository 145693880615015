import { Map } from 'immutable';
import { GradeLevelType } from '../../summary/constants';
import { SessionEventType } from '../../session/constants';
import { FrameType } from '../../frames/constants';

export const TYPE_PLOTLY = 'plotly';
export const TYPE_SUMMARY = 'summary';
export const TYPE_STEEP_TURN_SVG = 'steep turn svg';
export const TYPE_LANDING_SVG = 'landing svg';

export type VisualizationType =
  typeof TYPE_PLOTLY |
  typeof TYPE_SUMMARY |
  typeof TYPE_STEEP_TURN_SVG |
  typeof TYPE_LANDING_SVG;

type PlotlyVisualizationType = {
  data: Object[],
  layout: {
    annotations: Object[],
    legend: Object,
    height: number,
    shapes: Object[],
    width: number,
  },
};

type SvgAndNormVisualizationType = Object;

export type NormVisualizationType = {
  name: string,
  pass: boolean | null,
  ordering: number,
};

export type NormType = {
  activityCode: string,
  normCode: string,
  score: number | null,
  pass: boolean | null,
  evidence: Object | number | null,
  envelope: Object | null | any,
};

export type NormSummaryType = {
  payload: NormType[],
};

type AnyVisualizationType =
  PlotlyVisualizationType |
  SvgAndNormVisualizationType;

type VisualizationDefinitionType = {
  type: VisualizationType,
  data: AnyVisualizationType,
};

export type ApproachInsightDataPoint = {
  altMsl: number,
  distanceFromThreshold: number,
  latDev: number,
};
export type ApproachInsightPlot = {
  airport: string,
  data: ApproachInsightDataPoint[],
  fafDistance: number,
  fafElevation: number,
  runway: string,
  thresholdCrossingHeight: number,
  thresholdCrossingIndex: number,
  tdze: number,
};
export type ApproachInsightType = {
  summary?: NormSummaryType,
  approach?: ApproachInsightPlot,
};

export type SteepTurnInsightType = {
  payload?: Array<{
    direction: number,
    initialHdg: number,
    finalHdg: number,
    finalHdgGrade: GradeLevelType,
    initialAlt: number,
    maxAlt: number,
    maxAltGrade: GradeLevelType,
    minAlt: number,
    minAltGrade: GradeLevelType,
    initialIas: number,
    maxIas: number,
    maxIasGrade: GradeLevelType,
    minIas: number,
    minIasGrade: GradeLevelType,
    averageRoll: number,
    maxRoll: number,
    maxRollGrade: GradeLevelType,
    minRoll: number,
    minRollGrade: GradeLevelType,
  }>,
};

export type GearRetractionInsightValueType = {
  altMsl: number,
  timestamp: string,
};

export type AirspeedNormType = {
  percentile_5: number[],
  percentile_25: number[],
  median: number[],
  percentile_75: number[],
  percentile_95: number[],
  timestamp: string[],
};

export type GroundRollColNormType = {
  percentile_5: number[],
  percentile_25: number[],
  percentile_75: number[],
  percentile_95: number[],
  median: number[],
  timestamp: string[],
};

export type PitchNormType = {
  percentile_5: number[],
  percentile_25: number[],
  percentile_75: number[],
  percentile_95: number[],
  median: number[],
  timestamp: string[],
};

export type LateralAccNormTypeNormType = {
  percentile_5: number[],
  percentile_25: number[],
  percentile_75: number[],
  percentile_95: number[],
  median: number[],
  timestamp: string[],
};

export type GroundRollHeadingNormType = {
  timestampEnd: string,
  timestampStart: string,
  trueHeading: number
  sd: number,

};
export type GearRetractionNormType = {
  gearLvr: GearRetractionInsightValueType,
  mean: GearRetractionInsightValueType,
  median: GearRetractionInsightValueType,
  percentile_5: GearRetractionInsightValueType,
  percentile_25: GearRetractionInsightValueType,
  percentile_75: GearRetractionInsightValueType,
  percentile_95: GearRetractionInsightValueType,
};

type BeaconType = {
  name: string,
  lat: number,
  lon: number,
};

export type CasMarkerType = {
  cas: number,
  position: number,
};

export type LandingNormType = {
  position: number,
  pass: boolean,
};

type LandingNormObjectType = {
  [key: string]: LandingNormType,
};

type LandingDataType = {
  runwayName: string,
  oppositeRunwayName: string,
  runwayLength: number,
  trajectoryEnd: number,
  windDirection: number,
  relWindDirection: number,
  windSpeed: number,
  casMarkers: CasMarkerType[]
  norms: LandingNormObjectType,
};

export type DepartureInsightType = {
  data: FrameType[],
  runway: string,
  airport: string,
  runwayLat: number,
  runwayLon: number,
  runwayLat_2: number,
  runwayLon_2: number,
  beacons: BeaconType[],
};

export type TakeoffInsightType = {
  summary?: NormSummaryType,
  departure?: DepartureInsightType,
};

export type LandingInsightType = {
  summary?: NormSummaryType,
  landing?: LandingDataType,
};

export type RejectedTakeoffInsightType = {
  summary?: NormSummaryType,
};

export type InsightType =
  | ApproachInsightType
  | SteepTurnInsightType
  | TakeoffInsightType
  | LandingInsightType
  | RejectedTakeoffInsightType;

export type InsightDataMapType = Map<string, string | null>;

export type InsightResponseType = {
  activityCode: string | null,
  ksaComponentCode: string | null,
  insight: InsightType,
};

export type VisualizationDataType<T> = {
  frames: FrameType[],
  insight: T,
};

export type VisualizationMetaDataType = {
  aircraftType: string | null | undefined,
  aircraftDesignator: string,
  trainingCenter: string,
  flapSettings: number[],
};

export type VisualizationHandlerType<T extends InsightType | void> = (
  activityCode: string,
  data: VisualizationDataType<T>,
  markers: SessionEventType[],
  metaData: VisualizationMetaDataType,
  params?: Array<string | string[]>,
) => VisualizationDefinitionType[];

export const CATEGORY_AIR = 'IN-AIR';
export const CATEGORY_APPROACH = 'APPROACH';
export const CATEGORY_APPROACH_TO_STALL = 'APPROACH_TO_STALL';
export const CATEGORY_AUTOROTATION = 'AUTOROTATION';
export const CATEGORY_AUTOROTATION_LANDING = 'AUTOROTATION_LANDING';
export const CATEGORY_ENGINE_START = 'ENGINE_START';
export const CATEGORY_GO_AROUND = 'GO_AROUND';
export const CATEGORY_GROUND = 'GROUND';
export const CATEGORY_LANDING = 'LANDING';
export const CATEGORY_LOW_AIRSPEED = 'LOW_AIRSPEED';
export const CATEGORY_MISSED_APPROACH = 'MISSED_APPROACH';
export const CATEGORY_QUICKSTOP = 'QUICKSTOP';
export const CATEGORY_NONE = 'NONE';
export const CATEGORY_REJECTED_LANDING = 'REJECTED_LANDING';
export const CATEGORY_REJECTED_TAKEOFF = 'REJECTED_TAKEOFF';
export const CATEGORY_SLOPE = 'SLOPE';
export const CATEGORY_STEEP_TURN = 'STEEP_TURN';
export const CATEGORY_TAKEOFF = 'TAKEOFF';
export const CATEGORY_TAKEOFF_OEI = 'TAKEOFF_OEI';
export const CATEGORY_TAXI = 'TAXI';
export const CATEGORY_UNUSUAL_ATTITUDE_RECOVERY = 'UNUSUAL_ATTITUDE_RECOVERY';

export type VisualizationCategory =
  typeof CATEGORY_AIR |
  typeof CATEGORY_APPROACH |
  typeof CATEGORY_APPROACH_TO_STALL |
  typeof CATEGORY_AUTOROTATION |
  typeof CATEGORY_AUTOROTATION_LANDING |
  typeof CATEGORY_ENGINE_START |
  typeof CATEGORY_GO_AROUND |
  typeof CATEGORY_GROUND |
  typeof CATEGORY_LANDING |
  typeof CATEGORY_LOW_AIRSPEED |
  typeof CATEGORY_MISSED_APPROACH |
  typeof CATEGORY_QUICKSTOP |
  typeof CATEGORY_NONE |
  typeof CATEGORY_REJECTED_LANDING |
  typeof CATEGORY_REJECTED_TAKEOFF |
  typeof CATEGORY_SLOPE |
  typeof CATEGORY_STEEP_TURN |
  typeof CATEGORY_TAKEOFF |
  typeof CATEGORY_TAKEOFF_OEI |
  typeof CATEGORY_TAXI |
  typeof CATEGORY_UNUSUAL_ATTITUDE_RECOVERY;
