import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import CompetencyRadarPlot from './CompetencyRadarPlot';
import { CompetencyRadarPlotProps } from './types';
import * as actions from '../../actions';
import { GeneralStoreType } from '../../../store/constants';
import { GradeLevelType, GradeType } from '../../../summary/constants';

const mapStateToProps = (
  state: GeneralStoreType,
  { runId, onShowGradeContext, edit }: CompetencyRadarPlotProps,
) => {
  const scores = state.getIn(['competency', 'scoresByRun', runId]);
  const instructorScores = state.getIn(['competency', 'instructorScores', runId]) || {};
  const competencyDrilldown = state.getIn(['competency', 'drilldown']);
  const isLoading = state.getIn(['competency', 'isLoading']);
  const isLoadingCompetency = state.getIn(['competency', 'isLoadingCompetency']);

  return { scores, instructorScores, competencyDrilldown, isLoading, isLoadingCompetency, onShowGradeContext, edit };
};

const mapDispatchToProps = (
  dispatch: Function,
  { runId }: CompetencyRadarPlotProps,
) => ({
  onComponentMounted: () => dispatch(actions.radarPlotMounted(runId)),
  onUpdateCompetencyScore: (competency: string, score: GradeType, level: GradeLevelType) => dispatch(
    actions.updateCompetencyScore(runId, competency, score, level),
  ),
  onShowCompetencyDrilldown: (competency: string) => dispatch(
    actions.loadingCompetencyDrilldown(runId, competency),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(CompetencyRadarPlot));
