import React from 'react';
import styled from 'styled-components';

import {
  getColor,
  getNormMarkers,
  getRunwayCenterLine,
  getRunwayLines,
  RunwayContaminantNameMap,
  RunwayContaminantXMap,
} from './utils';
import { CasMarkerType } from '../../../utils/visualization/constants';

// @ts-ignore
const LandingSVG = data => {
  const {
    insight: {
      landing: {
        runwayName,
        oppositeRunwayName,
        runwayLength,
        casMarkers,
        norms,
        trajectoryEnd,
        windSpeed,
        windDirection,
        relWindDirection,
      },
      summary: {
        payload,
      },
    },
    frames,
  } = data;

  const SVGRunwayLength = 738;

  const getPosition = (value: number) => {
    const percent = value / runwayLength;
    const position = percent * SVGRunwayLength;

    return SVGRunwayLength - position;
  };

  const getWindAnnotation = (speed: number) => {
    if (speed < 2) {
      return 'CALM WIND';
    }

    return `${Math.round(windDirection)} @ ${Math.round(speed)} KTS`;
  };

  // @ts-ignore
  const touchdownRodIndex = payload.findIndex(({ normCode }) => normCode === 'TOUCHDOWN_ROD');
  // @ts-ignore
  const touchdownCasIndex = payload.findIndex(({ normCode }) => normCode === 'TOUCHDOWN_CAS');
  // @ts-ignore
  const touchdownDistIndex = payload.findIndex(({ normCode }) => normCode === 'TOUCHDOWN_DIST');
  // @ts-ignore
  const rolloutHdgIndex = payload.findIndex(({ normCode }) => normCode === 'ROLLOUT_HDG');

  const lineEnd = trajectoryEnd ? getPosition(trajectoryEnd) : 220;

  return (
    <>
      <LandingVisualization>

        {/* Runway Border */}
        <rect stroke="#979797" strokeWidth="2" x="310" y="21" width="98" height={SVGRunwayLength} />

        {/* Top Runway Lines */}
        {getRunwayLines(319.5, 30.3278689, 69.6721311, 10, 5, 8, 15)}
        {getRunwayLines(332, 113, 131.136364, 6, 2, 4, 38)}

        {/* Bottom Runway Lines */}
        {getRunwayLines(319.5, 709.327869, 748.672131, 10, 5, 8, 15)}
        {getRunwayLines(332, 647, 665.136364, 6, 2, 4, 38)}

        {/* Runway Center Line */}
        {getRunwayCenterLine(358.5, 130.447504, 157.297762)}

        {/* Runway Name and Opposite Runway Name */}
        <text fontFamily="Roboto-Bold, Roboto" fontSize="24" fontWeight="bold" fill="#979797">
          <tspan x={runwayName.length === 3 ? '339' : '344'} y="694">{runwayName}</tspan>
        </text>
        <text
          transform="translate(360.000000, 94.000000) rotate(-180.000000) translate(-360.000000, -94.000000)"
          fontFamily="Roboto-Bold, Roboto"
          fontSize="24"
          fontWeight="bold"
          fill="#979797"
        >
          <tspan x={oppositeRunwayName.length === 3 ? '339' : '349'} y="102">{oppositeRunwayName}</tspan>
        </text>

        {/* Touchdown Norms */}
        {touchdownDistIndex >= 0
          && payload[touchdownDistIndex].evidence !== null
          && touchdownRodIndex >= 0
          && payload[touchdownRodIndex].evidence !== null
          && (
            <>
              {trajectoryEnd && (
                <>
                  <line
                    x1="358.5"
                    y1={getPosition(payload[touchdownDistIndex].evidence)}
                    x2="358.5"
                    y2={lineEnd}
                    stroke={getColor(rolloutHdgIndex >= 0 && payload[rolloutHdgIndex].pass)}
                    strokeLinecap="square"
                  />
                  <rect x="353.5" y={lineEnd} width="10" height="10" fill="#9C9C9C" />
                </>
              )}
              <circle
                cx="358.5"
                cy={getPosition(payload[touchdownDistIndex].evidence)}
                r="5"
                fill={getColor(payload[touchdownDistIndex].pass)}
              />
              <text
                fontFamily="Roboto-Medium, Roboto"
                fontSize="12"
                fontWeight="400"
                fill={getColor(payload[touchdownRodIndex].pass)}
              >
                <tspan x="206" y={getPosition(payload[touchdownDistIndex].evidence)}>TOUCHDOWN</tspan>
                <tspan
                  x="219.368164"
                  y={getPosition(payload[touchdownDistIndex].evidence) + 16}
                >{Math.round(payload[touchdownRodIndex].evidence)} FPM
                </tspan>
              </text>
            </>
          )}

        {/* Heading Deviation Norm */}
        {rolloutHdgIndex >= 0 && payload[rolloutHdgIndex].evidence !== null && (
          <>
            <rect x="285" y="387" width="49" height="39" />
            <text
              fontFamily="Roboto-Medium, Roboto"
              fontSize="12"
              fontWeight="400"
              line-spacing="10"
              fill={getColor(payload[rolloutHdgIndex].pass)}
            >
              <tspan x="284.5" y="403">HDG DEV</tspan>
              <tspan x="294.009766" y="419">{Math.round(payload[rolloutHdgIndex].evidence)} Deg</tspan>
            </text>
          </>
        )}

        {/* CAS Markers */}
        <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#979797">
          <tspan x="430" y="787">IAS</tspan>
        </text>
        <line
          x1="439"
          y1={casMarkers.length > 0 ? getPosition(casMarkers[casMarkers.length - 1].position) : lineEnd}
          x2="439"
          y2="760"
          stroke="#979797"
          strokeLinecap="square"
        />
        {touchdownCasIndex >= 0
          && payload[touchdownCasIndex].evidence !== null
          && touchdownDistIndex >= 0
          && payload[touchdownDistIndex].evidence !== null
          && (
            <>
              <line
                x1="439"
                y1={getPosition(payload[touchdownDistIndex].evidence) - 13}
                x2="439"
                y2={getPosition(payload[touchdownDistIndex].evidence) + 5}
                stroke="#000000"
                strokeLinecap="square"
              />
              <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#ffffff">
                <tspan x="430" y={getPosition(payload[touchdownDistIndex].evidence)}>
                  {Math.round(payload[touchdownCasIndex].evidence)}
                </tspan>
              </text>
            </>
          )}
        {casMarkers.length > 0 && casMarkers.map((marker: CasMarkerType) => (
          <span key={marker.position}>
            <line
              x1="439"
              y1={getPosition(marker.position) - 13}
              x2="439"
              y2={getPosition(marker.position) + 5}
              stroke="#000000"
              strokeLinecap="square"
            />
            <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#ffffff">
              <tspan x="430" y={getPosition(marker.position)}>{marker.cas}</tspan>
            </text>
          </span>
        ))}

        {/* Speed Brake Norms */}
        <line x1="514" y1={lineEnd} x2="514" y2="760" stroke="#979797" strokeLinecap="square" />
        {norms.spdBrkDeployed && getNormMarkers(norms.spdBrkDeployed, 514, getPosition, lineEnd, norms.spdBrkRetracted)}
        <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#979797">
          <tspan x="503.124023" y="780">SPD </tspan>
          <tspan x="503.188477" y="794">BRK</tspan>
        </text>

        {/* Reverse Thruster Norms */}
        <line x1="589" y1={lineEnd} x2="589" y2="760" stroke="#979797" strokeLinecap="square" />
        {norms.revDeployed && getNormMarkers(norms.revDeployed, 589, getPosition, lineEnd, norms.revIdle)}
        <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#979797">
          <tspan x="577.981445" y="780">REV </tspan>
          <tspan x="577.351562" y="794">THR</tspan>
        </text>

        {/* Brake Norms */}
        <line x1="664" y1={lineEnd} x2="664" y2="760" stroke="#979797" strokeLinecap="square" />
        {norms.brakesApplied && getNormMarkers(norms.brakesApplied, 664, getPosition, lineEnd, norms.brakesReleased)}
        <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#979797">
          <tspan x="642" y="787">BRAKES</tspan>
        </text>

        {/* Runway and Aircraft Conditions */}
        <rect stroke="#979797" x="84.5" y="20.5" width="149" height="213" rx="10" />
        <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#979797">
          <tspan x="144.347656" y="50">WIND</tspan>
        </text>
        <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#979797">
          <tspan x="121.054688" y="69">{getWindAnnotation(windSpeed)}</tspan>
        </text>
        {windSpeed >= 2 && (
          <g transform={`translate(132.000000, 79.000000) rotate(${relWindDirection} 24.5 24.5)`}>
            <circle cx="24.5" cy="24.5" r="24.5" />
            <path d="M25,6 L25,40 L29,40 L24.5,49 L20,40 L24,40 L24,6 L25,6 Z" fill="#979797" fillRule="nonzero" />
            <polyline stroke="#979797" strokeLinecap="square" points="19 1 24.5 6.53882769 29 1" />
            <polyline stroke="#979797" strokeLinecap="square" points="19 6 24.5 11.5388277 29 6" />
          </g>
        )}
        <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#ffbf00">
          <tspan x={RunwayContaminantXMap.get(frames[0].data.rwyContaminantType)} y="148">
            {RunwayContaminantNameMap.get(frames[0].data.rwyContaminantType)}
          </tspan>
        </text>
        <line x1="86" y1="168" x2="233" y2="168" id="Line-7" stroke="#979797" strokeLinecap="square" />
        <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#979797">
          <tspan x="104.833008" y="193">WEIGHT: {Math.round(frames[0].data.massAc)} Kg</tspan>
        </text>
        <text fontFamily="Roboto-Medium, Roboto" fontSize="12" fontWeight="400" fill="#979797">
          <tspan x="131.103516" y="215">FLAPS: {frames[0].data.flapLeverPos}</tspan>
        </text>
      </LandingVisualization>
    </>
  );
};

const LandingVisualization = styled.svg`
  height: 800px;
  width: 100%;
`;

export default LandingSVG;
