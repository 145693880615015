import * as React from 'react';
import ReactSelect, { MenuPlacement, OptionsType, OptionTypeBase, Styles } from 'react-select';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';
import { Label } from './Label';
import * as grid from '../grid';
import inputStyles from '../../theme/input';

interface Props extends OptionTypeBase {
  loadOptions?: (input: string, cb: (options: OptionsType<OptionTypeBase>) => void) => void;
  menuPlacement?: MenuPlacement;
  formatCreateLabel?: Function;
  label?: string;
}

const styles: Styles = {
  menu: base => ({ ...base, backgroundColor: inputStyles.background.string() }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isFocused
      ? inputStyles.optionBackgroundFocused.string()
      : inputStyles.optionBackground.string(),
    color: inputStyles.textColor.string(),
  }),
  control: base => ({
    ...base,
    backgroundColor: 'transparent',
    border: `1px solid ${inputStyles.borderColor.string()}`,
    borderRadius: '4px',
    boxSizing: 'border-box',
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  input: base => ({ ...base, color: inputStyles.textColor.string() }),
  singleValue: base => ({ ...base, color: inputStyles.textColor.string() }),
};

export const DynamicSelect = (props: Props) => {
  // Determine if the select component should be asynchronous.
  const isAsync = typeof props.loadOptions === 'function';

  // Determine if the select component should allow creating new options.
  const isCreatable = typeof props.formatCreateLabel === 'function';

  const selectProps = {
    // Styling.
    styles,

    // Default props.
    delimiter: ',',
    maxMenuHeight: 550,
    menuPlacement: 'auto',
    openMenuOnFocus: true,

    // Override props.
    ...props,
  };

  switch (true) {
    case isCreatable:
      // @ts-ignore
      return <Creatable {...selectProps} />;

    case isAsync:
      // @ts-ignore
      return <AsyncSelect {...selectProps} />;

    default:
      // @ts-ignore
      return <ReactSelect {...selectProps} />;
  }
};

DynamicSelect.defaultProps = {
  loadOptions: null,
  menuPlacement: 'auto',
  formatCreateLabel: null,
  label: '',
};

export const DynamicColumnSelect = (props: Props) => {
  const { label, ...inputProps } = props;

  return (
    <grid.Column>
      <Label>{label}</Label>

      <DynamicSelect {...inputProps} />
    </grid.Column>
  );
};

DynamicColumnSelect.defaultProps = {
  loadOptions: null,
  menuPlacement: 'auto',
  formatCreateLabel: null,
  label: '',
};
