import { AnnotationData, PlotlyOptions } from '../types';
import { FrameType } from '../../../frames/constants';

// Helper function that pushes annotations to layout.
export const annotation = (plotOptions: PlotlyOptions, annotationData: AnnotationData) => {
  plotOptions.layout.annotations.push({
    xref: 'paper',
    // @ts-ignore
    yref: annotationData.yaxis,
    xanchor: annotationData.xanchor,
    yanchor: annotationData.yanchor || 'middle',
    x: annotationData.xPosition,
    y: annotationData.yPosition,
    text: annotationData.text,
    showarrow: false,
    font: {
      color: annotationData.color || 'white',
    },
  });
};

// Helper function that loops through specified data frames and returns maximum value.
export const getMaxValue = (frames: FrameType[], name: string) => {
  let maxValue = frames && frames.length ? frames[0].data[name] : 0;

  for (let i = 0; i < frames.length; i++) {
    if (Math.abs(Number(frames[i].data[name])) > maxValue) {
      maxValue = frames[i].data[name];
    }
  }

  return maxValue;
};

// Helper function that loops through specified data frames and returns minimum value.
export const getMinValue = (frames: FrameType[], name: string) => {
  let minValue = frames && frames.length ? frames[0].data[name] : 0;

  for (let i = 0; i < frames.length; i++) {
    if (frames[i].data[name] < minValue) {
      minValue = frames[i].data[name];
    }
  }

  return minValue;
};

// Helper function that returns index of plot.
export const getPlotIndex = (
  plotOptions: PlotlyOptions,
  plotName: string,
) => plotOptions.data.findIndex(({ name }) => name === plotName);

// Helper function that pushes shapes to layout.
export const shape = (plotOptions: PlotlyOptions, annotationData: AnnotationData) => {
  plotOptions.layout.shapes.push({
    xref: 'paper',
    // @ts-ignore
    yref: annotationData.yaxis,
    type: 'line',
    x0: annotationData.xStart,
    x1: annotationData.xEnd,
    y0: annotationData.yStart,
    y1: annotationData.yEnd,
    line: {
      color: annotationData.color || 'white',
      width: annotationData.width || 2,
      dash: annotationData.dash || 'dot',
    },
  });
};
