import { take, call, select, put, Effect } from 'redux-saga/effects';
import api from '../api';
import * as actions from './actions';
import * as activitySelectors from '../activities/selectors';
import { FetchFramesProps, FetchFramesType } from './constants';

export function* fetchFrames({ payload }: FetchFramesProps): FetchFramesType {
  const {
    runId,
    activityCode,
    activityId,
    fromTimestamp,
    toTimestamp,
  } = payload;

  if (!fromTimestamp || !toTimestamp) {
    return [];
  }

  try {
    // TODO: retrieve session frames using activity ID instead of timestamps.
    const { results } = yield call(api.frames.getFrames, runId, 0, 1000, {
      activityCode,
      activityId,
      timestamp__gte: fromTimestamp,
      timestamp__lt: toTimestamp,
    });

    return results;
  } catch (error) {
    api.logError(error);

    return [];
  }
}

export default {
  * expectsFetchFrames(): Generator<Effect, void, any> {
    while (true) {
      const action = yield take(actions.FETCH_FRAMES);

      const { runId, activityId } = action.payload;

      const activity = yield select(activitySelectors.selectActivityById, runId, activityId);

      const frames = activity
        ? yield call(fetchFrames, {
          payload: {
            activityCode: activity.activityCode,
            activityId: activity.id,
            runId,
            fromTimestamp: activity.startedAt,
            toTimestamp: activity.endedAt,
          },
        })
        : [];

      yield put(actions.updateFrames(frames));
    }
  },
};
