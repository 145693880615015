import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import { NavLink } from 'react-router-dom';
import Header from '../../../ui/headers/Header';
import PageHeader from '../../../ui/PageHeader';
import {
  Button,
  BUTTON_BACKGROUND_GRADIENT,
  BUTTON_BORDER_GRADIENT,
  ButtonVariationType,
  Wrapper as ButtonWrapper,
} from '../../../ui/Button';
import {
  accentBlue,
  lightestGrey,
  lightGrey,
  mediumGrey,
} from '../../../theme/palette';
import { SessionOverviewPropsType } from './index';
import Loading from '../../../ui/Loading';
import { gradingSheet, liveSessionRun, traineeProfile, viewSession } from '../../../utils/routes';
import * as utils from '../../../utils';
import { Trainee } from '../../../ui/TraineeCard';
import ContentContainer from '../../../ui/ContentContainer';

const SessionOverview = ({
  match,
  currentSession,
  runs,
  sessions,
  isLoading,
  equipment,
  onComponentMounted,
}: SessionOverviewPropsType) => {
  const sessionId = parseInt(match.params.sessionId, 10);

  React.useEffect(() => {
    onComponentMounted(sessionId);
  }, [onComponentMounted, sessionId]);

  if (Object.values(currentSession).length < 1 || runs.length < 1 || equipment.length < 1 || isLoading) {
    return <Loading />;
  }

  const date = moment(String(currentSession.scheduledFor)).format('MMMM Do, h:mm A');
  const course = currentSession.courseName || '';
  const lessonPlan = currentSession.lessonPlanName || currentSession.name || '';
  const aircraft = equipment[0].aircraftDetails.aircraft[0].designator;
  const device = equipment[0].aircraftDetails.aircraft[0].name;

  const sessionIndex = sessions.findIndex(({ id }) => id === sessionId);
  const session = sessions[sessionIndex];

  let btnText = 'START PART 1';
  let startSessionBtn: ButtonVariationType = BUTTON_BACKGROUND_GRADIENT;
  let disabled = false;
  let btnRoute = liveSessionRun(
    session.id,
    (session.plannedRuns && session.plannedRuns[0]) || 0,
  );

  if (utils.isRunning(session)) {
    btnText = 'START PART 2';
    disabled = true;
    btnRoute = liveSessionRun(session.id, session.activeRunId);
  } else if (utils.isEnded(session)) {
    btnText = 'REVIEW';
    startSessionBtn = BUTTON_BORDER_GRADIENT;
    disabled = true;
    btnRoute = gradingSheet(session.id);
  } else if (session.plannedRuns && session.plannedRuns[0]) {
    btnText = 'START PART 1';
    disabled = false;
    btnRoute = liveSessionRun(session.id, session.plannedRuns[0]);
  } else {
    return null;
  }

  return (
    <>
      <Header back="schedule" />
      <PageHeader title={course} />
      <ContentContainer>
        <ButtonWrapper>
          <NavLink to={btnRoute}>
            <Button
              width="290px"
              margin="auto"
              marginTop="165px"
              marginBottom="0px"
              variation={startSessionBtn}
              backgroundColor="#000000"
            >
              {btnText}
            </Button>
          </NavLink>
        </ButtonWrapper>
        <Wrapper>
          <Section>
            <Tile>
              <Title>
                Lesson Plan
                <NavLink to={viewSession(session.id)}>
                  <EditButton disabled={disabled}>
                    Edit
                  </EditButton>
                </NavLink>
              </Title>
              <Content>
                {lessonPlan}
              </Content>
            </Tile>
            <Tile>
              <Title>
                Pilots
              </Title>
              <PilotContent>
                <NavLink to={traineeProfile(session.trainees.pilotFlying.id)}>
                  <Trainee trainee={session.trainees.pilotFlying.name} hideLine />
                </NavLink>
                {session.trainees.pilotMonitoring && (
                  <>
                    <Line />
                    <NavLink to={traineeProfile(session.trainees.pilotMonitoring.id)}>
                      <Trainee trainee={session.trainees.pilotMonitoring.name} hideLine />
                    </NavLink>
                  </>
                )}
              </PilotContent>
            </Tile>
          </Section>
          <Section>
            <Tile>
              <Title>
                Date Scheduled
              </Title>
              <Content>
                {date}
              </Content>
            </Tile>
            <Tile>
              <Title>
                Aircraft Type
              </Title>
              <Content>
                {aircraft}
              </Content>
            </Tile>
            <Tile>
              <Title>
                Device
              </Title>
              <Content>
                {device}
              </Content>
            </Tile>
          </Section>
        </Wrapper>
      </ContentContainer>
    </>
  );
};

export default SessionOverview;

const Wrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-evenly;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
`;

const Tile = styled.div`
  background-color: ${mediumGrey.string()};
  border-radius: 5px;
  margin-bottom: 20px;
`;

const Title = styled.div`
  font-size: 16px;
  color: ${lightestGrey.string()};
  line-height: 19px;
  padding: 15px 20px;
  border-bottom: 1px solid ${lightGrey.string()};
  display: flex;
  justify-content: space-between;
  font-weight: 400;
`;

const EditButton = styled.div<{ disabled: boolean }>`
  font-size: 16px;
  color: ${accentBlue.string()};
  font-weight: 400;
  visibility: ${props => (props.disabled ? 'hidden' : 'visible')};
`;

const Content = styled.div`
  padding: 20px;
  font-weight: 400;
`;

const PilotContent = styled(Content)`
  padding: 20px 0;
`;

const Line = styled.div`
  width: 100%;
  margin: 20px 0;
  border-bottom: 1px solid ${lightGrey.string()};
`;
