import moment from 'moment';
import * as React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Select, { components } from 'react-select';
import Header from '../../../ui/headers/Header';
import PageHeader from '../../../ui/PageHeader';
import Body from '../../../ui/Body';
import Title from '../../../ui/head/Title';
import {
  CreateSessionButton,
  CreateSessionIcon,
  SessionCardRectangle,
} from '../../../ui/SessionCard';
import { createSession, sessionOverview } from '../../../utils/routes';
import { Button, BUTTON_TAB, BUTTON_TAB_GRADIENT, Wrapper as ButtonWrapper } from '../../../ui/Button';
import { lightestGrey } from '../../../theme/palette';
import Loading from '../../../ui/Loading';
import { ListScreenPropsType } from './index';
import { FullSessionType } from '../../../session/constants';
import { ToggleSwitchRow } from '../../../ui/form/ToggleSwitch';
import config from '../../../config';
import userIcon from '../../../assets/icons/loupe.svg';
import ContentContainer from '../../../ui/ContentContainer';

const List = ({
  onComponentMounted,
  isLoading,
  isExtendedLoading,
  sessions,
  count,
  onLogout: handleLogout,
  getFilteredSessions,
  getUserInfo,
  extendSessionList,
}: ListScreenPropsType) => {
  const [
    viewFilteredSessions,
    setViewFilteredSessions,
  ] = React.useState<FullSessionType[]>([]);

  React.useEffect(() => {
    if (config.isDemo) {
      getUserInfo();
    } else {
      onComponentMounted();
    }
  }, [onComponentMounted]);

  React.useEffect(() => {
    setViewFilteredSessions(sessions);
  }, [sessions]);

  const [viewPastSessions, setViewPastSessions] = React.useState(false);
  const [viewAllSessions, setViewAllSessions] = React.useState(false);
  const [allSessionsFetched, setAllSessionsFetched] = React.useState(false);

  React.useEffect(() => setAllSessionsFetched(sessions.length === count), [sessions, count]);

  const upcomingSessions: FullSessionType[] = [];
  const pastSessions: FullSessionType[] = [];
  const currentDate = new Date().toISOString().substr(0, 10);

  viewFilteredSessions.forEach(session => {
    if (!session.scheduledFor) {
      return;
    }

    const sessionDate = session.scheduledFor.substr(0, 10);

    if (currentDate <= sessionDate) {
      upcomingSessions.push(session);
    } else {
      pastSessions.push(session);
    }
  });

  const title = 'Training Sessions';
  const newSessionRoute = createSession();

  if (isLoading) {
    return <Loading />;
  }

  const sessionsList = viewPastSessions
    ? pastSessions
    : upcomingSessions.reverse();

  const handleClick = (timeFrame: string) => {
    if (timeFrame === 'upcoming' && viewPastSessions) {
      setViewPastSessions(false);
    } else if (!viewPastSessions) {
      setViewPastSessions(true);
    }
  };

  let month: string | null = null;
  let previousMonth: string | null = null;

  const handleClickToggle = (event: React.ChangeEvent) => {
    setViewAllSessions((event.target as HTMLInputElement).checked);
    getFilteredSessions(viewAllSessions);
  };

  const traineesOption: any[] = [];
  sessions.forEach(session => {
    traineesOption.push({
      value: session.trainees.pilotFlying.name,
      label: session.trainees.pilotFlying.name,
    });
    return traineesOption;
  });

  const DropdownIndicator = (props: any) => (
    <components.DropdownIndicator {...props}>
      <Magnifier />
    </components.DropdownIndicator>
  );

  const customStyles = {
    menu: () => ({
      width: 718,
      paddingTop: 0,
      marginTop: 0,
    }),
    option: (base: any, styles: any) => ({
      ...base,
      height: '100%',
      backgroundColor: 'black',
      color: 'lightgrey',
      fontWeight: '300',
      fontSize: 16,
      ':active': {
        ...styles[':active'],
        backgroundColor: 'rgb(20,74,112)',
        opacity: 0.8,
        color: 'white',
      },
    }),
    menuList: (styles: any) => ({ ...styles, overflow: 'hidden' }),
    control: (styles: any) => ({
      ...styles,
      backgroundColor: 'rgb(20,74,112)',
      paddingLeft: '5',
      border: '0px solid rgb(20,74,112)',
      opacity: 0.8,
      minHeight: 50,
      ':active': {
        ...styles[':active'],
        border: '0px solid rgb(20,74,112)',
      },
      ':hover': {
        border: '0px solid rgb(20,74,112)',
      },
    }),

    clearIndicator: (styles: any) => ({
      ...styles,
      color: 'white',
      opacity: 0.7,
      ':hover': {
        color: 'white',
      },
    }),

    placeholder: (styles: any) => ({
      ...styles,
      color: 'white',
      fontWeight: '300',
      opacity: 0.7,
      paddingLeft: 10,
      fontSize: 14,
      ':hover': {
        color: 'white',
      },
    }),

    multiValue: (styles: any) => ({
      ...styles,
      opacity: 0.9,
      border: '1px solid black',
      borderRadius: 50,
      marginTop: 7,
      marginBottom: 7,
      backgroundColor: 'rgb(8,30,43)',
    }),

    multiValueLabel: (styles: any) => ({
      ...styles,
      color: 'white',
      opacity: 1,
      paddingLeft: 15,
      fontWeight: '300',
      fontSize: 14,
    }),
    multiValueRemove: (styles: any) => ({
      ...styles,
      color: 'white',
      paddingRight: 8,
      borderRadius: 50,
      ':hover': {
        color: 'white',
      },
    }),
  };

  const handleChange = (selectedOptions: any) => {
    let filteredSessions = [];
    if (selectedOptions.length === 0) {
      setViewFilteredSessions(sessions);
    } else {
      filteredSessions = sessions.filter(s => selectedOptions.some((o: any) => s.trainees.pilotFlying.name === o.value));
      setViewFilteredSessions(filteredSessions);
    }
  };

  const NoOptionsMessage = (props: any) => (
    <components.NoOptionsMessage {...props}>
      <span>No More Trainees Available</span>
    </components.NoOptionsMessage>
  );

  return (
    <>
      <Title title={title} />
      <Header handleLogout={handleLogout} />
      <PageHeader title={title} />
      <ContentContainer>
        <TabsWrapper>
          <Button
            onClick={() => handleClick('upcoming')}
            margin="0.4em"
            variation={viewPastSessions ? BUTTON_TAB : BUTTON_TAB_GRADIENT}
            width="50%"
          >
            UPCOMING
          </Button>

          <Button
            onClick={() => handleClick('past')}
            margin="0.4em"
            variation={viewPastSessions ? BUTTON_TAB_GRADIENT : BUTTON_TAB}
            width="50%"
          >
            PAST
          </Button>
        </TabsWrapper>

        <Body>
          {!config.isDemo ? (
            <ToggleWrapper>
              <ToggleSwitchRow
                name="sessionFiltering"
                labelLeft="All Sessions"
                labelRight="My Sessions"
                disabled={false}
                checked={viewAllSessions}
                onChange={handleClickToggle}
              />
            </ToggleWrapper>
          ) : null}
          <SearchBarWrapper>
            <Select
              components={{ DropdownIndicator, NoOptionsMessage }}
              closeMenuOnSelect={false}
              isMulti
              options={traineesOption}
              styles={customStyles}
              placeholder="Filtering Session By Trainee"
              onChange={handleChange}
            />
          </SearchBarWrapper>
          {sessionsList.map(session => {
            const dt = moment(String(session.scheduledFor));
            const courseName = session.courseName || '';
            const lessonName = session.lessonPlanName || '';
            const pilotFlyingName = session.trainees.pilotFlying.name;
            const pilotMonitoringName = session.trainees.pilotMonitoring.name;

            if (month === null || dt.format('MMMM YYYY') !== previousMonth) {
              month = dt.format('MMMM YYYY');
              previousMonth = dt.format('MMMM YYYY');
            } else {
              month = '';
              previousMonth = dt.format('MMMM YYYY');
            }

            return (
              <div key={session.id} data-session-id={session.id}>
                <MonthWrapper>{month}</MonthWrapper>
                <SessionCardRectangle
                  date={dt}
                  buttonRoute={sessionOverview(session.id)}
                  courseName={courseName}
                  lessonName={lessonName}
                  pilotFlying={pilotFlyingName}
                  pilotMonitoring={pilotMonitoringName}
                />
              </div>
            );
          })}

          { viewPastSessions && !allSessionsFetched ? (
            <ButtonWrapper>
              <Button onClick={() => extendSessionList(!viewAllSessions)}>{isExtendedLoading ? 'LOADING...' : 'SEE MORE'}</Button>
            </ButtonWrapper>
          ) : null }

          <CreateSessionButton data-button="create-session" fixed>
            <NavLink to={newSessionRoute}>
              <CreateSessionIcon />
            </NavLink>
          </CreateSessionButton>
        </Body>
      </ContentContainer>
    </>
  );
};

export default List;

const TabsWrapper = styled.div`
  width: 100%;
  position: relative;
  top: 153px;
  z-index: 2;
`;

const MonthWrapper = styled.div`
  width: 718px;
  margin: 20px auto 26px auto;
  font-size: 16px;
  color: ${lightestGrey.string()};
`;
const SearchBarWrapper = styled.div`
  width: 718px;
  margin: 20px auto 26px auto;
  font-size: 16px;
  padding: 20px;
  color: ${lightestGrey.string()};
`;
const ToggleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 460px;
  margin-bottom: -40px;
  justify-content: center;
  align-items: center;
  color: ${lightestGrey.string()};
`;
const Magnifier = styled.div`
  background: center center transparent no-repeat url('${userIcon}');
  background-size: contain;
  height: 23px;
  width: 30px;
  filter: invert(100%) sepia(0%) saturate(7461%) hue-rotate(7deg)
    brightness(94%) contrast(102%);
`;
