import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider as ReduxProvider } from 'react-redux';
import { client } from './api';
import AuthGuard from './auth/components/Guard';
import config from './config';
import App from './dashboard/components/App';
import ErrorBoundary from './dashboard/components/ErrorBoundary';
import reporter from './utils/reporter';
import * as serviceWorker from './serviceWorker';
import store from './store';
import theme from './theme';

import '../node_modules/normalize.css/normalize.css';

// TODO: use a global style instead of this CSS file.
// https://styled-components.com/docs/api#createglobalstyle
import './assets/base.css';

// Inject store dispatcher into API client.
client.setDispatcher(store.dispatch);

if (config.isDev || config.debugEnvs.includes(config.deployEnv)) {
  // eslint-disable-next-line no-underscore-dangle
  window.__REDUX_STORE = store;
}

const render = () => {
  ReactDOM.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <ErrorBoundary>
          <AuthGuard>
            <App />
          </AuthGuard>
        </ErrorBoundary>
      </ThemeProvider>
    </ReduxProvider>,
    document.getElementById('root'),
  );
};

render();

// Handle hot reloading
if (module.hot) {
  module.hot.accept('./auth/components/Guard', render);
}

// Register service worker.
serviceWorker.register({
  log: reporter.info,
  error: reporter.error,
});
