import logError from './logError';
import { ClientInterface } from './client';

// API
export default class Authentication {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  authenticate = async (
    username: string,
    password: string,
  ): Promise<any> => this.client.post('/auth/login/', { username, password });

  refresh = async (
    token: string,
  ): Promise<any> => this.client.post('/auth/refresh/', { token });

  verify = async (
    token?: string,
  ): Promise<any> => this.client.post('/auth/verify/', { token });

  invalidate = async (): Promise<Record<string, any> | void> => {
    try {
      return await this.client.post('/auth/logout/');
    } catch (error) {
      // Only alert unexpected responses.
      return error.response && [401].includes(error.response.status)
        ? Promise.resolve()
        : logError(error);
    }
  };

  getUserInformation = async (): Promise<any> => this.client.getResource('/user/');
}
