import * as Plotly from 'plotly.js';
import { pickColor } from '../data';
import { ApproachInsightDataPoint } from '../../visualization/constants';

const getApproachDataPoints = (simParamTags: string[], dataPoints: ApproachInsightDataPoint[], tdze: number) => {
  const traces: Plotly.Data[] = [];
  const simParamPlotIndexMap = new Map();
  let numPlots = 0;

  simParamTags.forEach((plotName, index) => {
    traces.push({
      mode: 'lines',
      name: plotName,
      type: 'scatter',
      xaxis: `x${numPlots + 2}`,
      yaxis: `y${numPlots + 2}`,
      x: [],
      y: [],
      showlegend: false,
      line: {
        color: pickColor(index),
        width: 4,
      },
    });
    simParamPlotIndexMap.set(plotName, traces.length - 1);
    numPlots++;
  });

  const xAxis: number[] = [];

  dataPoints.forEach(dataPoint => {
    xAxis.push(dataPoint.distanceFromThreshold);

    simParamTags.forEach(param => {
      // @ts-ignore
      traces[simParamPlotIndexMap.get(param)].y.push(param === 'altMsl' ? dataPoint[param] - tdze : dataPoint[param]);
    });
  });

  traces.forEach((trace, index) => {
    traces[index].x = xAxis;
  });

  return traces;
};

export default getApproachDataPoints;
