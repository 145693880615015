import { RejectedTakeoffInsightType, TYPE_PLOTLY, TYPE_SUMMARY, VisualizationHandlerType } from '../constants';
import getRejectedTakeoffPlotOptions from '../../plotly/plotOptions/rejectedTakeoff';

// @ts-ignore
const getRejectedTakeoffVisualizations: VisualizationHandlerType<RejectedTakeoffInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const visualizations = [];

  if (data.insight && data.insight.summary && data.insight.summary.payload !== null) {
    const exists = data.insight.summary.payload.some(a => a.score !== null);
    if (exists) {
      visualizations.push({
        type: TYPE_SUMMARY,
        data: data.insight.summary,
      });
    }
  }

  visualizations.push({
    type: TYPE_PLOTLY,
    data: getRejectedTakeoffPlotOptions(data.frames, markers, activity, metaData),
  });

  return visualizations;
};

export default getRejectedTakeoffVisualizations;
