import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import EditScreen from './EditSessionScreen';
import * as actions from '../../actions';
import { getScheduleProps } from '../../utils';
import { ScheduleStore } from '../../store';
import { GeneralStoreType } from '../../../store/constants';

// Props provided by this container
export type EditSessionScreenProps = RouteComponentProps<{sessionId: string}> & ScheduleStore & {
  onComponentMounted: Function,
  onSaveLessonPlanVariation: Function,
  onDone: () => void,
};

const mapStateToProps = (state: GeneralStoreType): ScheduleStore => getScheduleProps(state);

const mapDispatchToProps = (dispatch: Function) => ({
  onComponentMounted: (sessionId: number) => dispatch(actions.editSessionScreenLoaded(sessionId)),
  // @ts-ignore
  onSaveLessonPlanVariation: (data: object) => dispatch(actions.createLessonPlanVariation(data)),
  onDone: () => {
    // @ts-ignore
    dispatch(actions.setLessonPlan({}));
    dispatch(actions.setScheduleActivityOptions([]));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(EditScreen));
