import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

type DatadogClientOptions = {
  applicationId: string;
  appVersion: string,
  clientName: string;
  clientToken: string;
  debugEnvs: string[];
  deployEnv: string;
  enabled: boolean;
  forwardErrorsToLogs?: boolean;
  resourceSampleRate?: number;
  sampleRate?: number;
};

class DatadogClient {
  isConfigured: boolean;

  logger?: typeof datadogLogs.logger;

  Rum?: typeof datadogRum;

  constructor(options: DatadogClientOptions) {
    const {
      applicationId,
      appVersion,
      clientName,
      clientToken,
      debugEnvs,
      deployEnv,
      enabled,
      forwardErrorsToLogs,
      resourceSampleRate,
      sampleRate,
    } = options;

    const datacenter = 'us';

    const globalContexts = {
      app_version: appVersion,
      client_name: clientName,
      env: deployEnv,
    };
    this.isConfigured = enabled && !!applicationId && !!clientToken;

    if (this.isConfigured) {
      this.Rum = datadogRum;
      this.Rum.init({
        applicationId,
        clientToken,
        // @ts-ignore
        datacenter,
        resourceSampleRate,
        sampleRate,
      });

      datadogLogs.init({
        clientToken,
        // @ts-ignore
        datacenter,
        forwardErrorsToLogs,
        sampleRate,
      });
      this.logger = datadogLogs.logger;

      for (const [key, value] of Object.entries(globalContexts)) {
        this.Rum.addRumGlobalContext(key, value);
        datadogLogs.addLoggerGlobalContext(key, value);
      }
      debugEnvs.includes(deployEnv) ? this.logger.setLevel(StatusType.debug) : this.logger.setLevel(StatusType.info);
    }
  }
}

export default DatadogClient;
