import { List, Record, RecordOf } from 'immutable';
import { FullSessionListType } from '../session/constants';

export type SessionListStore = {
  isLoading: boolean,
  isExtendedLoading: boolean,
  sessions: FullSessionListType,
  count: number
};

export type ImmutableSessionListStore = RecordOf<SessionListStore>;

const createSessionListStore = Record<SessionListStore>({
  isLoading: true,
  isExtendedLoading: false,
  sessions: List(),
  count: 0,
});

const defaultState: ImmutableSessionListStore = createSessionListStore();

export default defaultState;
