import {
  LandingInsightType,
  TYPE_PLOTLY,
  TYPE_SUMMARY,
  TYPE_LANDING_SVG,
  VisualizationHandlerType,
} from '../constants';
import getLandingPlotOptions from '../../plotly/plotOptions/landing';
import getLandingFlarePhasePlotOptions from '../../plotly/plotOptions/landingFlarePhase';

// @ts-ignore
const getLandingVisualizations: VisualizationHandlerType<LandingInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const visualizations = [];

  if (data.insight && data.insight.summary && data.insight.summary.payload !== null) {
    const exists = data.insight.summary.payload.some(a => a.score !== null);
    if (exists) {
      visualizations.push({
        type: TYPE_SUMMARY,
        data: data.insight.summary,
      });
    }
  }

  visualizations.push({
    type: TYPE_PLOTLY,
    data: getLandingPlotOptions(data.frames, markers, activity, metaData),
    page: 'Brake Application',
  },
  {
    type: TYPE_PLOTLY,
    data: getLandingFlarePhasePlotOptions(data.frames, markers, activity, metaData),
    page: 'Flare to Touchdown',
  });

  if (data.insight && data.insight.landing && data.insight.summary && data.insight.summary.payload !== null) {
    visualizations.push({
      type: TYPE_LANDING_SVG,
      data,
      page: 'Rollout',
    });
  }

  return visualizations;
};

export default getLandingVisualizations;
