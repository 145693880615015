import { CompetencyCode } from './types';
import { ActivityType } from '../activities/types';

export const COMPETENCY_AFM_A = 'afmA';
export const COMPETENCY_AFM_M = 'afmM';
export const COMPETENCY_AOP = 'aop';
export const COMPETENCY_COM = 'com';
export const COMPETENCY_KNO = 'kno';
export const COMPETENCY_LTW = 'ltw';
export const COMPETENCY_PSD = 'psd';
export const COMPETENCY_SAW = 'saw';
export const COMPETENCY_WLM = 'wlm';

export const competencyShortNameMap: Map<CompetencyCode, string> = new Map([
  [COMPETENCY_SAW, 'Awareness'],
  [COMPETENCY_COM, 'Communication'],
  [COMPETENCY_AFM_A, 'Flight Path (Automatic)'],
  [COMPETENCY_AFM_M, 'Flight Path (Manual)'],
  [COMPETENCY_KNO, 'Knowledge'],
  [COMPETENCY_LTW, 'Leadership'],
  [COMPETENCY_PSD, 'Problem Solving'],
  [COMPETENCY_AOP, 'Procedures'],
  [COMPETENCY_WLM, 'Workload'],
]);

export const competencyShortNameMapTable: Map<CompetencyCode, string> = new Map([
  [COMPETENCY_SAW, 'Situational Awareness (SAW)'],
  [COMPETENCY_COM, 'Communication (COM)'],
  [COMPETENCY_AFM_A, 'Flight Path Management, Automation (FPM/A)'],
  [COMPETENCY_AFM_M, 'Flight Path Management, Manual Control (FPM/M)'],
  [COMPETENCY_KNO, 'Application of Knowledge (KNO)'],
  [COMPETENCY_LTW, 'Leadership and Teamwork (LTM)'],
  [COMPETENCY_PSD, 'Problem Solving and Decision Making (PDM)'],
  [COMPETENCY_AOP, 'Application of Procedures (AOP)'],
  [COMPETENCY_WLM, 'Workload Management (WLM)'],
]);

export type CompetencyDrilldownType = {
  activityId: number,
  activityCode: string,
  pass: boolean,
  normCode: string,
  normName: string,
};

export type CompetencyDrilldownProps = {
  name: string | null,
  norms: CompetencyDrilldownType[],
  activities: ActivityType[],
  onShowGradeContext: Function,
  onCloseDrilldown: () => void,
};

type DrilldownNormType = {
  name: string,
  pass: boolean[],
};

type DrilldownType = {
  activityName: string,
  activityIds: number[],
  activityNorms: DrilldownNormType[],
  pass: number,
  fail: number,
};

export interface DrilldownObjectType {
  [key: string]: DrilldownType,
}
