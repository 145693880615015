import React from 'react';
import styled from 'styled-components';
import { Droppable } from 'react-beautiful-dnd';
import Activity from './Activity';
import { ActivityType } from '../../../activities/types';

type Props = {
  activities: Array<Partial<ActivityType>>,
};

const ActivitiesColumn = ({ activities }: Props) => (
  <Droppable droppableId="droppable" type="ACTIVITY">
    {(provided, snapshot) => (
      <ActivityColumn
        ref={provided.innerRef}
        {...provided.droppableProps}
        isDraggingOver={snapshot.isDraggingOver}
      >
        <ActivityList>
          {activities.map((activity, index) => (
            <Activity
              key={activity.ordering}
              activity={activity}
              index={index}
            />
          ))}
          {provided.placeholder}
        </ActivityList>
      </ActivityColumn>
    )}
  </Droppable>
);

export default ActivitiesColumn;

const ActivityColumn = styled.div<any>``;

const ActivityList = styled.div``;
