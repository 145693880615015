import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import styled from 'styled-components';
import Header from '../../../ui/headers/Header';
import { ContainerProps } from './types';
import { FrameType } from '../../../frames/constants';
import { ActivityIdType, ActivitiesMapType, NoteType } from '../../../activities/types';
import Loading from '../../../ui/Loading';
import {
  ActivityGradesMapType,
  GradeLevelType,
  GradingSheetRunMapType,
  KsaGradeMapType,
} from '../../constants';
import { SessionMarkerMapType } from '../../../session/constants';
import { getGradingContext } from '../../utils';
import { getDate } from './utils';
import { lightestGrey, lightGrey } from '../../../theme/palette';
import ActivityPlot from '../ActivityPlot';
import { InsightType } from '../../../utils/visualization/constants';
import ContextOverlayNavigation from './Navigation';
import { DispatchMapProps } from './index';
import { NotesTextArea } from '../../../ui/Notes';
import Recommendations from '../../../recommendations/components';
import { RecommendationsType } from '../../../recommendations/store';
import ContentContainer from '../../../ui/ContentContainer';

type Props = ContainerProps
& RouteComponentProps<{ sessionId: string, runId: string, activityId: string }>
& DispatchMapProps
& {
  activitiesMap: ActivitiesMapType,
  currentRunId: number,
  currentActivityId: number,
  framesList: FrameType[],
  isFetchingFrames: boolean,
  gradingSheets: GradingSheetRunMapType,
  activityGrades: ActivityGradesMapType,
  ksaComponentGrades: KsaGradeMapType,
  markersMap: SessionMarkerMapType,
  insight: InsightType | null,
  isLoading: boolean,
  activityRecommendations: RecommendationsType,
};

const GradeContext = ({
  onComponentMounted,
  match,
  onDone,
  gradingSheets,
  activityGrades,
  ksaComponentGrades,
  markersMap,
  activitiesMap,
  currentRunId,
  currentActivityId,
  framesList,
  isFetchingFrames,
  insight,
  isLoading,
  onSaveNote,
  onUpdateGradeLevel,
  onSelectDemo,
  activityRecommendations,
  onSubmitInstructorFeedback,
}: Props) => {
  const history = useHistory();
  const { sessionId, runId, activityId } = match.params;

  React.useEffect(() => {
    onComponentMounted(Number(sessionId), Number(runId), Number(activityId));
  }, [onComponentMounted, sessionId, runId, activityId]);

  if (isFetchingFrames || isLoading) {
    return <Loading />;
  }

  const gradingSheet = gradingSheets && currentRunId && gradingSheets[currentRunId]
    ? gradingSheets[currentRunId]
    : null;

  // @ts-ignore
  const activities = activitiesMap && currentRunId ? activitiesMap[currentRunId] : [];
  // @ts-ignore
  const markers = currentRunId && markersMap ? (markersMap[currentRunId] || []) : [];

  let gradeContextItem = null;

  gradingSheet && gradingSheet.sections.forEach(({ items }) => {
    items.forEach(item => {
      if (item.activities.includes(currentActivityId)) {
        gradeContextItem = item;
      }
    });
  });

  const gradeContext = getGradingContext(
    gradeContextItem,
    activityGrades,
    ksaComponentGrades,
    markers,
    activities,
  );

  const { instructorGrade, predictedGrade } = gradeContext.grades.get(currentActivityId) || {};
  const activity = gradeContext.activities.get(currentActivityId);
  const isDemo = (activity && activity.isDemo) || false;
  const date = framesList.length > 0 && getDate(framesList);

  const handleUpdateGrade = (
    level: GradeLevelType,
  ) => instructorGrade && onUpdateGradeLevel(instructorGrade, level, currentRunId);

  const handleSaveNote = (
    text: string,
    note: NoteType,
  ) => currentActivityId && onSaveNote(currentActivityId, text, note);

  const handleChangeActivity = (
    id: ActivityIdType,
  ) => {
    onDone();
    history.push(`/context/${sessionId}/${runId}/${id}`);
  };

  const handleGoBack = () => {
    onDone();
    history.push(`/summary/${Number(sessionId)}`);
  };

  const handleSubmitInstructorFeedback = (instructorFeedback: boolean) => {
    onSubmitInstructorFeedback('activity', activityId, instructorFeedback);
  };

  return (
    <>
      <Header back={handleGoBack} />
      <div>
        <Title>{activity && activity.name}</Title>
        <ContentContainer>
          {framesList.length >= 2
            ? (
              <>
                <DateWrapper>{date}</DateWrapper>
                <PlotWrapper id="wrapper">
                  {gradeContextItem !== null && (
                    <ActivityPlot
                      frames={framesList}
                      insight={insight}
                      item={gradeContextItem}
                      markers={markers}
                    />
                  )}
                </PlotWrapper>
              </>
            )
            : (
              <MessageWrapper>No data available.</MessageWrapper>
            )}

          {activityRecommendations.recommendations && activityRecommendations.recommendations.length > 0 && (
            <TrainingRecommendationsWrapper>
              <Recommendations
                recommendations={activityRecommendations}
                onSubmitInstructorFeedback={handleSubmitInstructorFeedback}
              />
            </TrainingRecommendationsWrapper>
          )}

          <InstructorNoteWrapper>
            <Label>COMMENTS</Label>
            <NotesTextArea onSaveNote={handleSaveNote} note={activity ? activity.note : null} light />
          </InstructorNoteWrapper>

          <ContextOverlayNavigation
            onUpdateGrade={handleUpdateGrade}
            onSelectDemo={onSelectDemo}
            isDemo={isDemo}
            gradingContext={gradeContext}
            gradeableId={currentActivityId}
            instructorGradeLevel={instructorGrade && instructorGrade.gradeValue}
            predictedGradeLevel={predictedGrade && predictedGrade.gradeValue}
            onChangeActivity={handleChangeActivity}
          />
        </ContentContainer>
      </div>
    </>
  );
};

export default GradeContext;

const MessageWrapper = styled.div`
  margin: 100px 5vw;
`;

const Title = styled.div`
  font-size: 24px;
  text-align: center;
  margin-top: 100px;
  width: 100%;
  text-transform: uppercase;
  font-weight: 400;
`;

const DateWrapper = styled.div`
  font-size: 12px;
  color: ${lightestGrey.string()};
  width: 100%;
  text-align: center;
  letter-spacing: 0.75px;
`;

const PlotWrapper = styled.div`
  @media only screen and (orientation: portrait) {
    padding: 0 25px;
  }
`;

const TrainingRecommendationsWrapper = styled.div`
  padding: 25px;
  max-width: 100%;
  border-top: 1px solid ${lightGrey.string()};
`;

const InstructorNoteWrapper = styled(TrainingRecommendationsWrapper)`
  border-bottom: 1px solid ${lightGrey.string()};
  margin-bottom: 25px;
`;

export const Label = styled.div`
  font-size: 12px;
  color: ${lightestGrey.string()};
  letter-spacing: 0.75px;
  margin-bottom: 5px;
`;
