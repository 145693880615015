import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import ListScreen from './ListScreen';
import * as actions from '../../actions';
import { logout } from '../../../auth/actions';
import { getInstructorInfo } from '../../../instructor/actions';
import { SessionListStore } from '../../store';
import { FullSessionType } from '../../../session/constants';
import { GeneralStoreType } from '../../../store/constants';
import { getUserInfo } from '../../../userProfile/actions';

// Props provided by this container
export type ListScreenPropsType = {
  isLoading: boolean,
  isExtendedLoading: boolean,
  sessions: FullSessionType[],
  count: number,
  onComponentMounted: () => void,
  onLogout: () => void,
  getInstructorInfo: () => void,
  getFilteredSessions : (showInstructorSessions: boolean) => void,
  getUserInfo : () => void,
  extendSessionList: (showInstructorSessions: boolean) => void
};

const mapStateToProps = (state: GeneralStoreType): SessionListStore => {
  const sessionList = state.get('sessionList');

  return {
    isLoading: sessionList.get('isLoading'),
    isExtendedLoading: sessionList.get('isExtendedLoading'),
    sessions: sessionList.get('sessions'),
    count: sessionList.get('count'),
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  onLogout: () => dispatch(logout()),
  onComponentMounted: () => dispatch(actions.sessionListScreenLoaded()),
  getInstructorInfo: () => dispatch(getInstructorInfo()),
  getFilteredSessions:
    (showInstructorSessions: boolean) => dispatch(actions.getFilteredSessions(showInstructorSessions)),
  getUserInfo: () => dispatch(getUserInfo()),
  extendSessionList: (showInstructorSessions: boolean) => dispatch(actions.extendSessionList(showInstructorSessions)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
// @ts-ignore
)(withImmutablePropsToJS(ListScreen));
