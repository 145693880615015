import * as React from 'react';
import styled from 'styled-components';

type Props = {
  title?: string | null,
};

const PageHeader = ({ title }: Props) => (
  <Header>{title && title.toUpperCase()}</Header>
);

const Header = styled.section<Props>`
  background-color: ${props => props.theme.background.string()};
  position: fixed;
  top: 85px;
  height: 68px;
  width: 100%;
  z-index: 11;
  margin: auto;
  text-align: center;
  font-size: 24px;
  line-height: 68px;
  letter-spacing: 2px;
`;

PageHeader.defaultProps = {
  title: '',
};

export default PageHeader;
