import React from 'react';
import styled from 'styled-components';

import { Control, ControlBody, ControlTitle } from './Navigation';
import { GradeableIdType, GradingContextType } from '../../constants';

type MarkersProps = {
  context: GradingContextType,
  gradeableId: GradeableIdType,
  onChangeActivity: Function,
};

type ActivitiesProps = MarkersProps & {
  gradeableId: GradeableIdType,
};

export const ActivitiesController = ({ context, gradeableId, onChangeActivity }: ActivitiesProps) => {
  const repeats = Array.from(context.grades.keys());

  if (repeats.length < 1) {
    return null;
  }

  return (
    <Control>
      <ControlBody>
        {repeats.map((id, index) => {
          const label = `#${index + 1}`;
          const onClick = () => id !== gradeableId && onChangeActivity(id);

          return (
            <ActivitySelector
              key={id}
              active={id === gradeableId}
              onClick={onClick}
            >
              {label}
            </ActivitySelector>
          );
        })}
      </ControlBody>

      <ControlTitle>Activities</ControlTitle>
    </Control>
  );
};

export const MarkersController = ({ context, gradeableId, onChangeActivity }: MarkersProps) => {
  let counter = 1;
  const markers: React.ReactNode[] = [];

  context.markers.forEach((activityMarkers, activityId) => {
    if (activityMarkers.length < 1) {
      return;
    }

    activityMarkers.forEach(marker => {
      const label = `#${counter}`;
      const onClick = () => activityId !== gradeableId && onChangeActivity(activityId);

      markers.push(
        <MarkerSelector key={marker.id} onClick={onClick}>
          {label}
        </MarkerSelector>,
      );

      counter++;
    });
  });

  if (markers.length < 1) {
    return null;
  }

  return (
    <Control>
      <ControlBody>
        {markers}
      </ControlBody>

      <ControlTitle>Markers</ControlTitle>
    </Control>
  );
};

const Selector = styled.button`
  border: none;
  border-radius: ${props => props.theme.borderRadius};
  color: ${props => props.theme.textColor.darken(0.4).string()};
  cursor: pointer;

  height: 2rem;
  width: 2.4rem;
  margin: auto 0.2rem;
  padding: 0;
`;

const ActivitySelector = styled(Selector)<{active: boolean}>`
  background-color: ${props => (props.active
    ? props.theme.textColor.darken(0.8).string()
    : props.theme.textColor.darken(0.9).string()
  )};
  color: ${props => (props.active ? 'inherit' : props.theme.textColor.string())}
`;

const MarkerSelector = styled(Selector)`
  background-color: ${props => props.theme.primary.string()};
  border-radius: 50%;
  color: ${props => props.theme.textColor.string()};

  height: 2.2rem;
  width: 2.2rem;
`;
