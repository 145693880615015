import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import Guard from './Guard';
import * as actions from '../../actions';
import { AuthStore } from '../../store';
import { GeneralStoreType } from '../../../store/constants';
import { MapDispatchType } from './types';

const mapStateToProps = (state: GeneralStoreType): AuthStore => {
  const auth = state.get('auth');

  return {
    isInstructorAdmin: auth.get('isInstructorAdmin'),
    isAuthenticating: auth.get('isAuthenticating'),
    user: auth.get('user'),
    status: auth.get('status'),
    token: auth.get('token'),
  };
};

const mapDispatchToProps = (dispatch: Function): MapDispatchType => ({
  handleLogout: redirectTo => dispatch(actions.logout(redirectTo)),
  requireToken: handleRedirect => dispatch(actions.requireToken(handleRedirect)),
  verifyToken: token => dispatch(actions.verifyToken(token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(withImmutablePropsToJS(Guard));
