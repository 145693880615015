// @ts-nocheck
import * as React from 'react';
import styled from 'styled-components';

import { getGradingContext, getGradeableIdForMarker } from '../utils';
import GradeInputList, { GradeInputContextSelectorType } from './GradeInputList';
import {
  ActivityGradesMapType,
  GradeLevelUpdateHandlerType,
  GradingContextHandlerType,
  GradingSheetType,
  KsaGradeMapType,
} from '../constants';
import { SessionEventType } from '../../session/constants';
import { ActivityType } from '../../activities/types';
import { lightGrey, black } from '../../theme/palette';

type Props = {
  gradingSheet: GradingSheetType | null,
  activityGrades: ActivityGradesMapType,
  ksaComponentGrades: KsaGradeMapType,
  markers: SessionEventType[],
  activities: ActivityType[],
  onShowGradeContext: GradingContextHandlerType,
  onUpdateGradeLevel: GradeLevelUpdateHandlerType,
  onSelectDemo: Function,
  onSaveNote: Function,
};

const TaskList = ({
  gradingSheet,
  activityGrades,
  ksaComponentGrades,
  markers,
  activities,
  onShowGradeContext,
  onUpdateGradeLevel,
  onSelectDemo,
  onSaveNote,
}: Props) => {
  // The item list is simply a list of table rows. Most rows are for grading
  // sheet items, but some rows will be for section headings.
  // TODO: memoize this operation.
  const rows: React.ReactNode[] = [];

  gradingSheet.sections.forEach(({ id: gradingSheetId, name, items }) => {
    if (!items.length) return;

    rows.push(<SectionTitle key={`gs-section-${gradingSheetId}`}>{name}</SectionTitle>);

    items.forEach((item, index) => {
      const gradingContext = getGradingContext(
        item,
        activityGrades,
        ksaComponentGrades,
        markers,
        activities,
      );

      const handleShowGradeContext: GradeInputContextSelectorType = gradeableId => {
        onShowGradeContext(gradeableId);
      };

      const activityGradeableIds = Array.from(gradingContext.activities.keys());

      const handleShowActivityGradeContext = activityGradeableIds[0]
        ? () => onShowGradeContext(activityGradeableIds[0])
        : null;

      const markerGradeableId = getGradeableIdForMarker(gradingContext.markers);
      const handleShowMarkerGradeContext = markerGradeableId
        ? () => onShowGradeContext(markerGradeableId)
        : null;

      rows.push(
        <Row key={`gs-item-${item.id}`} line={index !== items.length - 1}>
          <LabelColumn onClick={handleShowActivityGradeContext}>
            {item.name}
          </LabelColumn>

          <GradeColumn>
            <GradeInputList
              gradingContext={gradingContext}
              onUpdateGradeLevel={onUpdateGradeLevel}
              onSelectDemo={onSelectDemo}
              onShowGradeContext={handleShowGradeContext}
              onSaveNote={onSaveNote}
            />

            {handleShowMarkerGradeContext ? (
              <Marker onClick={handleShowMarkerGradeContext} />
            ) : null}
          </GradeColumn>
        </Row>,
      );
    });
  });

  return rows;
};

export const Row = styled.div<{ line?: boolean }>`
  display: flex;
  padding: 15px 20px;
  line-height: 30px;
  border-bottom: ${props => (props.line ? `1px solid ${lightGrey.string()}` : '')};
`;

export const SectionTitle = styled(Row)<{ top?: boolean }>`
  background-image: linear-gradient(
    to right,
    ${props => props.theme.primary.toString()},
    ${props => props.theme.tertiary.toString()}
  );
  ${props => props.top && `
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    position: fixed;
    width: calc(100% - 40px)
  `};
  border-top: 40px solid ${black.string()};
  font-size: 1.25rem;
  font-family: 'Oswald', sans-serif;
`;

export const LabelColumn = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const GradeColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${props => props.theme.fontWeightBold};
  min-width: 20vw;
  position: relative;
  margin-right: 1rem;
`;

const Marker = styled.button`
  background-color: ${props => props.theme.tertiary.string()};
  border: none;
  border-radius: 50%;
  cursor: pointer;

  display: block;
  width: 1.8rem;
  height: 1.8rem;
  position: absolute;
  right: -1.5rem;
`;

export default TaskList;
