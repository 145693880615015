import * as React from 'react';
import styled from 'styled-components';

import { Button, BUTTON_BLACK } from '../../../../ui/Button';
import IconBookmark from '../../../../ui/svg/Bookmark.svg';
import borderAnimationRule from '../animations';
import config from '../../../../config';

import { MarkerButtonProps } from './types';
import { EVENT_TYPE_MARKER } from '../../../../session/constants';

type Props = MarkerButtonProps & {
  sessionRunId: number,
};

const MakerButton = (props: Props) => {
  let timerID: ReturnType<typeof setTimeout> | null = null;
  const [isTiming, setIsTiming] = React.useState(false);

  const cancelTimer = (isUnmounting: boolean = false) => {
    if (!timerID) {
      return;
    }

    clearTimeout(timerID);
    timerID = null;

    // Update the state flag.
    if (!isUnmounting) {
      setIsTiming(false);
    }
  };

  const enableEventMarker = () => {
    cancelTimer();
    setIsTiming(false);
  };

  const disableEventMarker = () => {
    setIsTiming(true);
    // eslint-disable-next-line @typescript-eslint/no-implied-eval
    timerID = setTimeout(enableEventMarker, config.eventMarkerTimeout);
  };

  const handleClick = () => {
    if (isTiming) {
      return;
    }

    // Record marker event
    props.onRecordEvent({
      eventType: EVENT_TYPE_MARKER,
      sessionRunId: props.sessionRunId,
    });

    disableEventMarker();
  };

  return (
    <ButtonWrapper isTiming={isTiming}>
      <Button
        onClick={handleClick}
        variation={BUTTON_BLACK}
        width="180px"
        margin="none"
      >
        <IconWrapper>
          <BookmarkIcon />
          MARK EVENT
        </IconWrapper>
      </Button>
    </ButtonWrapper>
  );
};

export default MakerButton;

type RepeatProps = {
  isTiming: boolean,
};

const ButtonWrapper = styled.div<RepeatProps>`
  animation: ${props => (props.isTiming ? borderAnimationRule : 'none')};
  border: 1px solid ${props => (props.isTiming ? '#f7d747' : 'black')};
  border-radius: 3px;
  box-shadow: ${props => (props.isTiming ? '0 0 20px #f7d747' : '')};
`;

const BookmarkIcon = styled.div`
  background: center center transparent no-repeat url('${IconBookmark}');
  height: 20px;
  width: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  line-height: 20px;
`;
