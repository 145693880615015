import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import * as actions from '../../actions';
import * as trainingActions from '../../../training/actions';
import * as activityActions from '../../../activities/actions';
import SessionRunScreen from './Screen';

import { SessionStore } from '../../store';
import { GeneralStoreType } from '../../../store/constants';
import { ActivitiesStore } from '../../../activities/store';
import { SimulatorStatus } from '../../../training/types';

export type LiveTrainingProps = SessionStore & ActivitiesStore & {
  onComponentMounted: (sessionId: number, runId: number) => void,
  onUpdateActiveView: Function,
  onUpdateFlightPhase: Function,
  onEndRun: (id: number, callback?: Function) => void,
  onDone: Function,
  simulatorStatus: SimulatorStatus | null
};

const mapStateToProps = (state: GeneralStoreType) => ({
  currentSession: state.getIn(['session', 'currentSession']),
  currentRunId: state.getIn(['activities', 'currentRunId']),
  simulatorStatus: state.getIn(['training', 'simulatorStatus']),
  currentActivityId: state.getIn(['activities', 'currentActivityId']),
  nextActivityId: state.getIn(['activities', 'nextActivityId']),
  runs: state.getIn(['session', 'runs']),
  activities: state.getIn(['activities', 'activities']),
  spiderGraphCompetency: state.getIn(['session', 'spiderGraphCompetency']),
});

const mapDispatchToProps = (dispatch: Function) => ({
  onComponentMounted: (sessionId: number, runId: number) => dispatch(trainingActions.trainingScreenLoaded(
    sessionId,
    runId,
  )),
  onEndRun: (id: number, callback?: () => void) => dispatch(trainingActions.endRun(id, callback)),
  onDone: () => {
    dispatch(actions.updateRuns([]));
    dispatch(activityActions.clearActivities());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(withImmutablePropsToJS(SessionRunScreen));
