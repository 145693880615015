import React from 'react';
import styled from 'styled-components';
import { makePopup as makeTypeformPopup } from '@typeform/embed';
import { NavLink } from 'react-router-dom';
import { home, schedule, userGuide } from '../utils/routes';

import LogoSVG from './svg/PaladinAILogoLight.svg';
import homeSVG from './svg/icon-home.svg';
import logoutSVG from './svg/icon-logout.svg';
import sessionsSVG from './svg/icon-sessions.svg';
import supportSVG from './svg/icon-support.svg';
import userguideSVG from './svg/icon-userguide.svg';

type Props = {
  showNavBar: boolean,
  handleLogout?: Function,
};

const NavBar = ({ showNavBar, handleLogout }: Props) => {
  const onLogout = () => {
    if (handleLogout) {
      handleLogout();
    }
  };

  const typeformPopup = makeTypeformPopup(
    'https://mikhailklassen.typeform.com/to/YkdIGe',
    {
      mode: 'popup',
      autoClose: 3,
      hideHeaders: true,
      hideFooter: true,
    },
  );

  return (
    <NavWrapper open={showNavBar}>
      <Wrapper>
        <Logo />
        <NavLink to={home()}>
          <NavItem>
            <Icon icon={homeSVG} />
            Home
          </NavItem>
        </NavLink>
        <NavLink to={schedule()}>
          <NavItem>
            <Icon icon={sessionsSVG} />
            Sessions
          </NavItem>
        </NavLink>
        <NavItem onClick={() => typeformPopup.open()}>
          <Icon icon={supportSVG} />
          Contact Us
        </NavItem>
      </Wrapper>
      <Wrapper>
        <NavLink to={userGuide()}>
          <NavItem>
            <Icon icon={userguideSVG} />
            InstructIQ&trade; User Guide
          </NavItem>
        </NavLink>
        <NavItem onClick={onLogout}>
          <Icon icon={logoutSVG} />
          Log Out
        </NavItem>
      </Wrapper>
    </NavWrapper>
  );
};

NavBar.defaultProps = {
  handleLogout: null,
};

export default NavBar;

const NavWrapper = styled.div<{ open: boolean }>`
  position: fixed;
  left: 0px;
  top: 0px;
  height: calc(100% - 64px);
  width: calc(40vw - 50px);
  background-color: black;
  z-index: 13;
  padding: 32px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
`;

const Wrapper = styled.div`
`;

const Logo = styled.div`
  background: center center transparent no-repeat url('${LogoSVG}');
  background-size: contain;
  height: 51px;
  width: 188px;
  margin-bottom: 32px;
`;

const NavItem = styled.div`
  display: flex;
  margin: 22px 0;
  color: white;
`;

const Icon = styled.div<{ icon: string }>`
  height: 29px;
  width: 25px;
  background: center center transparent no-repeat url('${props => props.icon}');
  size: contain;
  margin-right: 20px;
`;
