import { ClientInterface } from './client';
import { Response } from './types';
import { CompetencyScoresResponse } from '../competency/types';
import { SimulatorStatusResponse, TrainingSessionEvent } from '../training/types';
import * as constants from '../session/constants';

// API
export default class Sessions {
  client: ClientInterface;

  constructor({ client }: { client: ClientInterface }) {
    this.client = client;
  }

  /**
   * Creates a FullSession record.
   */
  createFullSession = async (
    equipment: number,
    scheduledFor: string,
    lessonPlan: number,
    lessonPlanVariation?: number,
  ): Promise<Object | false> => this.client.postResource('/sessions/', {
    equipment,
    scheduledFor,
    lessonPlan,
    lessonPlanVariation,
  });

  /**
   * Creates a Session Run record. The Pilot Flying and Pilot Monitoring should
   * be encrypted.
   */
  createRun = async (
    fullSession: number,
    pilotFlying: string,
    pilotMonitoring: string,
    scheduledFor: string,
  ): Promise<Object | false> => this.client.postResource('/session-runs/', {
    fullSession,
    pilotFlying,
    pilotMonitoring,
    scheduledFor,
  });

  //  /**
  //    *filters the session runs and only returns training session runs associated with the provided instructor ID..
  //    */
  //   instructorInfo = async (
  //     userId?: number,
  //   ): Promise<Object | false> => this.client.postResource('/session-runs/', {
  //     instructor:userId,
  //   });

  /**
   * Retrieves a full session by ID.
   */
  get = async (
    id: number,
    expand: string[] = ['equipment', 'lesson_plan.course'],
  ): Promise<constants.FullSessionType | false> => this.client.getResource(`/sessions/${id}/`, {
    expand: expand
      && expand.join(),
  });

  /**
   * Retrieves the session runs for a given session.
   */
  getRuns = async (
    id: number,
    expand: string[] = ['activities', 'pilot_flying', 'pilot_monitoring'],
  ): Promise<any> => this.client.getResource('/session-runs/', { fullSession: id, expand: expand.join() });

  /**
   * Lists sessions according to the given parameters.
   */
  list = async (
    limit?: number,
    offset?: number,
    ordering?: string,
    expand?: string[],
    instructor?: number,
  ): Promise<any> => this.client.getResource('/sessions/',
    { instructor, limit, offset, ordering, expand: expand && expand.join() });

  /**
   * Lists sessions after the given session ID.
   */
  listSince = async (id__gt: number = 0): Promise<any> => this.client.getResource('/sessions/',
    { id__gt, limit: 500, offset: 0, expand: 'exercise' });

  /**
   * Marks a session run as started.
   */
  startRun = async (id: number, startedAt?: string): Promise<any> => this.client.post(`/session-runs/${id}/start/`, {
    startedAt,
  });

  /**
   * Adds an activity to the given run, ending the previous activity if
   * 'startedAt' is provided and the given run is active.
   */
  createActivity = async (
    sessionRun: number,
    activity?: Object,
  ): Promise<any> => this.client.postResource('/activities/', {
    ...activity,
    sessionRun,
  });

  poll = async (id: number): Response<TrainingSessionEvent> => this.client.get(`/session-runs/${id}/poll/`);

  pollSimulatorStatus = async (id: number): Response<SimulatorStatusResponse> => this.client.get(`/session-runs/${id}/poll-simulator-status/`);

  /**
   * Marks a session run as ended.
   */
  endRun = async (id: number, endedAt?: string): Promise<any> => this.client.post(`/session-runs/${id}/end/`, {
    endedAt,
  });

  getCompetencyScores = async (
    id: number,
  ): Response<CompetencyScoresResponse> => this.client.get(`/session-runs/${id}/competency-scores/`);

  getGradingSheet = async (id: number):
  Promise<object | false> => this.client.getResource(`/session-runs/${id}/grading-sheet/`);

  getActivityOptions = async (
    fullSessionId: constants.FullSessionIdType,
  ): Promise<any> => this.client.getResource('/session-options/', { fullSessionId });
}
