import { Map as ImmutableMap, RecordOf } from 'immutable';
import { KsaCodeType, KsaIdType, RunIdType } from '../../session/constants';
import { ActivityCodeType, ActivityIdType } from '../../activities/types';

import * as palette from '../../theme/palette';

export const GRADE_LEVEL_NULL = 0;
export const GRADE_LEVEL_1 = 1;
export const GRADE_LEVEL_2 = 1.25;
export const GRADE_LEVEL_3 = 1.5;
export const GRADE_LEVEL_4 = 1.75;
export const GRADE_LEVEL_5 = 2;
export const GRADE_LEVEL_6 = 2.25;
export const GRADE_LEVEL_7 = 2.5;
export const GRADE_LEVEL_8 = 2.75;
export const GRADE_LEVEL_9 = 3;
export const GRADE_LEVEL_10 = 3.25;
export const GRADE_LEVEL_11 = 3.5;
export const GRADE_LEVEL_12 = 3.75;
export const GRADE_LEVEL_13 = 4;

export const GRADE_LEVEL_14 = 4.25;
export const GRADE_LEVEL_15 = 4.5;
export const GRADE_LEVEL_16 = 4.75;
export const GRADE_LEVEL_17 = 5;

// The ordering here matters. It is hard-coded for the Simcom trials for now.
export const GRADE_LEVELS = [
  GRADE_LEVEL_1,
  GRADE_LEVEL_5,
  GRADE_LEVEL_9,
  GRADE_LEVEL_13,
  GRADE_LEVEL_17,
];

export type GradeLevelType =
  typeof GRADE_LEVEL_1 |
  typeof GRADE_LEVEL_5 |
  typeof GRADE_LEVEL_9 |
  typeof GRADE_LEVEL_11 |
  typeof GRADE_LEVEL_13 |
  typeof GRADE_LEVEL_17;

export type GradeColorType =
  typeof GRADE_LEVEL_NULL |
  typeof GRADE_LEVEL_1 |
  typeof GRADE_LEVEL_2 |
  typeof GRADE_LEVEL_3 |
  typeof GRADE_LEVEL_4 |
  typeof GRADE_LEVEL_5 |
  typeof GRADE_LEVEL_6 |
  typeof GRADE_LEVEL_7 |
  typeof GRADE_LEVEL_8 |
  typeof GRADE_LEVEL_9 |
  typeof GRADE_LEVEL_10 |
  typeof GRADE_LEVEL_11 |
  typeof GRADE_LEVEL_12 |
  typeof GRADE_LEVEL_13 |
  typeof GRADE_LEVEL_14 |
  typeof GRADE_LEVEL_15 |
  typeof GRADE_LEVEL_16 |
  typeof GRADE_LEVEL_17;

export const gradeColors: Map<number, string> = new Map([
  [GRADE_LEVEL_NULL, palette.gradeLevelNull.string()],
  [GRADE_LEVEL_1, palette.gradeLevel1.string()],
  [GRADE_LEVEL_2, palette.gradeLevel2.string()],
  [GRADE_LEVEL_3, palette.gradeLevel3.string()],
  [GRADE_LEVEL_4, palette.gradeLevel4.string()],
  [GRADE_LEVEL_5, palette.gradeLevel5.string()],
  [GRADE_LEVEL_6, palette.gradeLevel6.string()],
  [GRADE_LEVEL_7, palette.gradeLevel7.string()],
  [GRADE_LEVEL_8, palette.gradeLevel8.string()],
  [GRADE_LEVEL_9, palette.gradeLevel9.string()],
  [GRADE_LEVEL_10, palette.gradeLevel10.string()],
  [GRADE_LEVEL_11, palette.gradeLevel11.string()],
  [GRADE_LEVEL_12, palette.gradeLevel12.string()],
  [GRADE_LEVEL_13, palette.gradeLevel13.string()],
  [GRADE_LEVEL_14, palette.gradeLevel17.string()],
  [GRADE_LEVEL_15, palette.gradeLevel17.string()],
  [GRADE_LEVEL_16, palette.gradeLevel17.string()],
  [GRADE_LEVEL_17, palette.gradeLevel17.string()],
]);
export const GRADEABLE_ACTIVITY = 'activity';
export const GRADEABLE_KSA = 'ksaComponent';

export type GradeableType = typeof GRADEABLE_ACTIVITY | typeof GRADEABLE_KSA;

export type GradeableIdType = ActivityIdType | KsaIdType;

// The grade value is the value that will be displayed to the instructor.
export type GradeValueType = number;

export type GradeIdType = number;

export type GradeType = {
  id: GradeIdType,
  activityCode: ActivityCodeType | null,
  activityId: ActivityIdType | null,
  ksaComponentCode: KsaCodeType | null,
  ksaComponentId: KsaIdType | null,
  gradeValue: GradeLevelType | null,
  afmAGrade: GradeLevelType | null,
  afmMGrade: GradeLevelType | null,
  aopGrade: GradeLevelType | null,
  comGrade: GradeLevelType | null,
  ltwGrade: GradeLevelType | null,
  pwdGrade: GradeLevelType | null,
  sawGrade: GradeLevelType | null,
  wlmGrade: GradeLevelType | null,
  reasonCode?: string,
  comment?: string,
  isAutomatic: boolean,
  updatedAt: string,

  // The temporary ID is used for placeholder grades.
  tempId?: number,
};

export type GradeLevelUpdateHandlerType = (
  grade: GradeType,
  level: GradeLevelType,
  type?: GradeableType,
) => void;

export type ActivityGradesMapType = ImmutableMap<RunIdType, Array<RecordOf<GradeType>>>;

export type KsaGradeMapType = ImmutableMap<RunIdType, Array<RecordOf<GradeType>>>;

export type GradeableGradeMapType = ActivityGradesMapType | KsaGradeMapType;

export type GradingSchemeType = '1-4' | '1-5' | '4-1' | '5-1';

export const defaultGradingScheme: GradingSchemeType = '1-4';

export const getColor = (
  gradingScheme: GradingSchemeType,
  gradeValue: GradeLevelType | null,
  defaultColor: string = 'transparent',
): string => {
  if (!gradeValue) {
    return defaultColor;
  }

  if (gradingScheme === '4-1') {
    return gradeColors.get(5 - gradeValue) || defaultColor;
  }

  return gradeColors.get(gradeValue) || defaultColor;
};
