import { call, put, select, takeEvery } from 'redux-saga/effects';
import * as actions from './actions';
import api from '../api';
// import * as authSelectors from '../auth/selectors';
import * as userProfileSelectors from '../userProfile/selectors';
import config from '../config';
import { getFilteredSessions } from '../sessionList/actions';

export function* getInstructorInfo() {
  try {
    // const userId = yield select(authSelectors.selectUserId);
    const userId = yield select(userProfileSelectors.selectUserInfo);
    if (userId !== null) {
      const { results } = yield call(api.instructors.getInstructorByUserId, userId);
      if (results) yield put(actions.setInstructorInfo(results[0].id));
      if (config.isDemo) {
        yield put(getFilteredSessions(true));
      }
      return results;
    }
    return {};
  } catch (error) {
    yield call(api.logError, error);
    return {};
  }
}

/**
 * Watchers.
 */
export default {
  * watchAuthenticate(): Generator<any, void, void> {
    yield takeEvery(actions.GET_INSTRUCTOR_INFO, getInstructorInfo);
  },
};
