import { useSelector } from 'react-redux';
import { GeneralStoreType } from '../store/constants';

export const useTrainingCenter = () => useSelector((state: GeneralStoreType) => state.getIn(['dashboard', 'trainingCenterName']));

export const useGradingScheme = () => useSelector((state: GeneralStoreType) => state.getIn(['dashboard', 'gradingScheme']));

export const useEquipment = () => {
  const equipmentId = useSelector((state: GeneralStoreType) => state.getIn(['session', 'currentSession', 'equipmentId']));

  const equipments = useSelector((state: GeneralStoreType) => state.getIn(['dashboard', 'equipment'])).toJS();

  return equipments.find((e: any) => e.equipmentId === equipmentId);
};
