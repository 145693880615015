import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import {
  schedule,
  sessionOverview,
  traineeProfile,
} from '../../../utils/routes';

import Header from '../../../ui/headers/Header';
import PageHeader from '../../../ui/PageHeader';
import Loading from '../../../ui/Loading';
import {
  SessionCardSquare,
  SessionCardSquareEmpty,
} from '../../../ui/SessionCard';
import { Title, Trainee } from '../../../ui/TraineeCard';
import {
  accentBlue,
  lightestGrey,
  mediumGrey,
} from '../../../theme/palette';

import { LandingPageProps } from './index';
import { FullSessionType, TraineeType } from '../../../session/constants';
import ContentContainer from '../../../ui/ContentContainer';
//  import { ToggleSwitchRow } from "../../../ui/form/ToggleSwitch";

const LandingPage = ({
  isLoading,
  sessions,
  onComponentMounted,
  onLogout,
}: LandingPageProps) => {
  React.useEffect(() => {
    onComponentMounted();
  }, [onComponentMounted]);

  const upcomingSessions: FullSessionType[] = [];
  const trainees: { [key: string]: TraineeType } = {};
  const currentDate = new Date().toISOString().substr(0, 10);
  localStorage.setItem('url', (window.location.href));
  sessions.forEach(session => {
    if (!session.scheduledFor) {
      return;
    }

    const sessionDate = session.scheduledFor.substr(0, 10);

    if (currentDate <= sessionDate) {
      upcomingSessions.push(session);
    }

    if (
      session.trainees.pilotFlying.name !== ''
      && !trainees[session.trainees.pilotFlying.id]
    ) {
      trainees[session.trainees.pilotFlying.id] = session.trainees.pilotFlying;
    }

    if (
      session.trainees.pilotMonitoring.name !== ''
      && !trainees[session.trainees.pilotMonitoring.id]
    ) {
      trainees[session.trainees.pilotMonitoring.id] = session.trainees.pilotMonitoring;
    }
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <Header handleLogout={onLogout} />
      <PageHeader title="Welcome" />
      <Body>
        {/* <ToggleSwitchRow
                name="isCrossTrainingSession"
                labelLeft={"My Sessions"}
                labelRight={"All Sessions"}
                disabled={false}
                checked={false}
                //onChange={handleClickToggle}
              /> */}
        <ContentContainer>
          <SessionsHeader>
            Upcoming Training Sessions
            <NavLink to={schedule()}>
              <ViewButton>View all</ViewButton>
            </NavLink>
          </SessionsHeader>
          <SessionsWrapper>
            {upcomingSessions.length > 0 ? (
              upcomingSessions.reverse().map(session => {
                const date = moment(String(session.scheduledFor));
                const courseName = session.courseName || '';
                const lessonName = session.lessonPlanShortName || '';
                const pilotFlyingName = session.trainees.pilotFlying.name;
                const pilotMonitoringName = session.trainees.pilotMonitoring.name;

                return (
                  <SessionCardSquare
                    key={session.id}
                    date={date}
                    buttonRoute={sessionOverview(session.id)}
                    courseName={courseName}
                    lessonName={lessonName}
                    pilotFlying={pilotFlyingName}
                    pilotMonitoring={pilotMonitoringName}
                  />
                );
              })
            ) : (
              <SessionCardSquareEmpty />
            )}
          </SessionsWrapper>
          <LowerWrapper>
            <Trainees>
              <Title>Your Trainees</Title>
              {Object.values(trainees).map((trainee: TraineeType) => (
                <NavLink key={trainee.id} to={traineeProfile(trainee.id)}>
                  <Trainee trainee={trainee.name} />
                </NavLink>
              ))}
            </Trainees>
          </LowerWrapper>
        </ContentContainer>
      </Body>
    </>
  );
};

export default LandingPage;

const Body = styled.div`
  display: absolute;
  margin-top: 145px;
  padding: 25px;
  height: calc(100% - 145px);
`;

const SessionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: ${lightestGrey.string()};
  line-height: 19px;
`;

const ViewButton = styled.div`
  color: ${accentBlue.string()};
`;

const SessionsWrapper = styled.div`
  margin-top: 30px;
  display: flex;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const LowerWrapper = styled.div`
  margin-top: 30px;
  height: calc(100% - 435px);
  display: flex;
  justify-content: space-between;
`;

const Trainees = styled.div`
  height: 100%;
  width: calc(100% - 10px);
  background-color: ${mediumGrey.string()};
  border-radius: 5px;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;
