import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components';
import Plotly from '../../../ui/Plotly';
import getVisualizationsForActivity from '../../../utils/visualization/activity';
import SteepTurnSVG from '../../../ui/svg/SteepTurns';
import LandingSVG from '../../../ui/svg/Landing';
import NormSummary from '../../../utils/normSummary';
import {
  InsightType,
  TYPE_LANDING_SVG,
  TYPE_PLOTLY,
  TYPE_STEEP_TURN_SVG,
  TYPE_SUMMARY,
} from '../../../utils/visualization/constants';
import { GradingSheetItemType } from '../../constants';
import { SessionEventType } from '../../../session/constants';
import { FrameType } from '../../../frames/constants';
import { useEquipment, useTrainingCenter } from '../../hooks';

type Props = {
  frames: FrameType[],
  insight: InsightType | null,
  item: GradingSheetItemType,
  markers: SessionEventType[],
};

const ActivityPlot = ({ insight, frames, item, markers }: Props) => {
  const [position, setPosition] = React.useState(0);
  const activityCode = item.activityCodes[0] || '';
  const trainingCenter = useTrainingCenter();
  const equipment = useEquipment();
  const flapSettings = (equipment
    && equipment.aircraftDetails
    && equipment.aircraftDetails.aircraft
    && equipment.aircraftDetails.aircraft[0].flapSettings) || [];
  const aircraftDesignator = (equipment
    && equipment.aircraftDetails
    && equipment.aircraftDetails.aircraft
    && equipment.aircraftDetails.aircraft[0].designator);

  const aircraftType = (equipment
    && equipment.aircraftDetails?.aircraft
    && equipment.aircraftDetails.aircraft[0].type);

  const visualizations = getVisualizationsForActivity(
    activityCode,
    // @ts-ignore
    { frames, insight },
    markers,
    { aircraftDesignator, flapSettings, trainingCenter, aircraftType },
  );

  // @ts-ignore
  const plots: React.ReactNode[] = visualizations.map(({ type, data: plotData, page }, id) => {
    switch (type) {
      case TYPE_PLOTLY: {
        const layout = {
          ...plotData.layout,
          height: 800,
        };

        return ( // eslint-disable-next-line react/no-array-index-key
          <SwipeableWrapper key={id}>
            <SubTitle>{page}</SubTitle>
            <Plotly {...plotData} layout={layout} />
          </SwipeableWrapper>
        );
      }
      case TYPE_SUMMARY: {
        return ( // eslint-disable-next-line react/no-array-index-key
          <SwipeableWrapper key={id}>
            <NormSummary {...plotData} />
          </SwipeableWrapper>
        );
      }
      case TYPE_STEEP_TURN_SVG: {
        return ( // eslint-disable-next-line react/no-array-index-key
          <SwipeableWrapper key={id}>
            <SteepTurnSVG {...plotData} />
          </SwipeableWrapper>
        );
      }
      case TYPE_LANDING_SVG: {
        return ( // eslint-disable-next-line react/no-array-index-key
          <SwipeableWrapper key={id}>
            <SubTitle>{page}</SubTitle>
            <LandingSVG {...plotData} />
          </SwipeableWrapper>
        );
      }

      default:
        return null;
    }
  });

  const handleChange = (index: number) => {
    setPosition(index);
  };

  return (
    <PlotWrapper>
      <SwipeableViews enableMouseEvents index={position} onChangeIndex={handleChange}>
        {plots}
      </SwipeableViews>
      {plots.length > 1
        ? plots.map((_, id: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <Position currentView={position === id} key={id + plots.length}>&#8226;</Position>
        ))
        : ''}
    </PlotWrapper>
  );
};

const PlotWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const SwipeableWrapper = styled.div`
  width: 100%;
  position: reletive;
  margin-top: 15px;

`;
const SubTitle = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 300;
  text-align: left;
  margin-left: 60px;
`;
const Position = styled.span<{ currentView: boolean }>`
  color: ${props => (props.currentView
    ? props.theme.textColor.string()
    : props.theme.textColor.darken(0.6).string())
};
  font-size: 32px;
  margin: 0 5px;
`;

export default ActivityPlot;
