import React from 'react';
import * as utils from '../utils';
// noinspection TypeScriptPreferShortImport
import { DynamicColumnSelect } from '../../ui/form/DynamicSelect';

type Props = {
  onChange: (option: any) => void,
  [key: string]: any,
};

/**
 * Handler for displaying the "create new option" label.
 */
const handleCreateLabel = (label: string) => (
  <span>
    {`Add trainee "${label}"`}
  </span>
);

/**
 * Helper function for retrieving the value of a Select option.
 */
const getValue = (option: { value: string } | string): string => (
  typeof option === 'object' ? option.value : option
);

const TraineeSelect = (props: Props) => {
  const { onChange, ...inputProps } = props;

  // List of trainees.
  const [trainees, setTrainees] = React.useState<Array<{label: string, value: string}>>([]);

  // Retrieve list of trainees from the local database.
  React.useEffect(() => {
    // We need to keep track of whether the component is mounted or not, as we
    // cannot set state otherwise.
    let isMounted = true;

    // Retrieve trainee names from the local database and create a list of
    // Select option objects.
    utils
      .getTrainees()
      .then(names => isMounted && setTrainees(names.map(name => ({
        label: name,
        value: name,
      }))))
      .catch(() => {});

    return () => {
      isMounted = false;
    };
  }, []);

  // Change handler.
  const handleChange = (trainee: {label: string, value: string}, { action }: { action: string }) => {
    // If we have a new trainee, add them to the local database.
    if (action === 'create-option') {
      utils.addTrainee(trainee.value);
    }

    onChange(trainee);
  };

  return (
    <DynamicColumnSelect
      {...inputProps}
      formatCreateLabel={handleCreateLabel}
      getOptionLabel={getValue}
      getOptionValue={getValue}
      onChange={handleChange}
      options={trainees}
    />
  );
};

export default TraineeSelect;
