import { call, put, takeLatest, Effect } from 'redux-saga/effects';
import * as actions from './actions';
import api from '../api';

function* getTrainingCenterDetails(): Generator<Effect, void, any> {
  try {
    const details = yield call(api.trainingCenter.get);

    if (details) {
      yield put(actions.trainingCenterDetailsUpdated(details));
    }
  } catch (error) {
    yield call(api.logError, error);
  }
}

export default {
  * expectsTrainingCenterDetails(): Generator<Effect, void, void> {
    yield takeLatest(actions.DASHBOARD_LOADED, getTrainingCenterDetails);
  },
};
