import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { GeneralStoreType } from '../../../store/constants';
import { StateMapProps } from './types';
import GradeContext from './GradeContext';
import * as activityActions from '../../../activities/actions';
import * as frameActions from '../../../frames/actions';
import * as actions from '../../actions';
import * as insightActions from '../../../insight/actions';
import * as recommendationActions from '../../../recommendations/actions';
import { NoteType } from '../../../activities/types';
import { GradeLevelType, GradeType } from '../../constants';

const mapStateToProps = (state: GeneralStoreType): StateMapProps => {
  const summary = state.get('summary');
  const frames = state.get('frames');
  const activities = state.get('activities');
  const insight = state.get('insight');
  const recommendations = state.get('recommendations');

  const { gradingSheets, activityGrades, ksaComponentGrades, markers } = summary;
  const { list: framesList, isFetchingFrames } = frames;
  const { map: activitiesMap, currentRunId, currentActivityId } = activities;
  const { data, isLoading } = insight;
  const { activityRecommendations } = recommendations;

  return {
    // @ts-ignore
    framesList,
    isFetchingFrames,
    gradingSheets,
    activityGrades,
    ksaComponentGrades,
    markersMap: markers,
    // @ts-ignore
    insight: data,
    isLoading,
    activitiesMap,
    currentRunId,
    currentActivityId,
    activityRecommendations,
  };
};

export type DispatchMapProps = {
  onComponentMounted: Function,
  onUpdateGradeLevel: Function,
  onSaveNote: Function,
  onSelectDemo: Function,
  onDone: Function,
  onSubmitInstructorFeedback: Function,
};

const mapDispatchToProps = (dispatch: Function): DispatchMapProps => ({
  onComponentMounted: (sessionId: number, runId: number, activityId: number) => {
    dispatch(insightActions.gradeContextLoaded(sessionId, runId, activityId));
  },
  onUpdateGradeLevel: (
    grade: GradeType,
    level: GradeLevelType,
    runId: number,
  ) => dispatch(actions.updateInstructorGrade(grade, level, runId)),
  onSaveNote: (
    activityId: number,
    text: string,
    note: NoteType | null,
  ) => dispatch(activityActions.saveInstructorNote(activityId, text, note)),
  onSelectDemo: (activityId: number) => dispatch(activityActions.setActivityDemo(activityId)),
  onDone: () => {
    dispatch(insightActions.clearInsightData());
    dispatch(frameActions.clearFrames());
    dispatch(activityActions.updateCurrentActivity(null));
  },
  onSubmitInstructorFeedback: (type: string, id: number, instructorFeedback: boolean) => {
    dispatch(recommendationActions.submitInstructorFeedback(type, id, instructorFeedback));
  },
});

const GradeContextContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(GradeContext));

export default GradeContextContainer;
