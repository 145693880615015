import { fromJS } from 'immutable';
import defaultState, { ImmutableDashboardStore } from './store';
import * as actions from './actions';
import * as sessionActions from '../session/actions';

export default (
  state: ImmutableDashboardStore = defaultState,
  action: actions.DashboardAction | sessionActions.UpdateSessionAction,
): ImmutableDashboardStore => {
  switch (action.type) {
    case actions.DASHBOARD_LOADED:
    {
      return state;
    }

    case actions.TRAINING_CENTER_DETAILS_UPDATED:
    {
      const { name: trainingCenter, dashboardConfig } = action.payload;
      const gradingScheme = dashboardConfig && dashboardConfig.gradingScheme
        ? dashboardConfig.gradingScheme
        : state.gradingScheme;

      return state.merge({ gradingScheme, trainingCenter });
    }

    case actions.EQUIPMENT_DETAILS_UPDATED:
    {
      const { equipment } = action.payload;

      if (!equipment) {
        return state;
      }

      return state.set('equipment', fromJS(equipment));
    }

    default:
      return state;
  }
};
