import * as constants from './constants';
import { FullSessionType } from '../session/constants';

export const CREATE_SESSION_SCREEN_LOADED = '"Create session" screen loaded.';
export const VIEW_SESSION_SCREEN_LOADED = '"View session" screen loaded.';
export const EDIT_SESSION_SCREEN_LOADED = '"Edit session" screen loaded.';
export const FLAGS = 'Updated schedule store flags.';
export const COURSE_PLANS_UPDATED = 'Received new course plans.';
export const SCHEDULE_SESSION = 'Scheduling new session...';
export const SCHEDULE_SESSION_COMPLETED = 'Session scheduled.';
export const LESSON_PLAN_UPDATED = 'Received lesson plan.';
export const SCHEDULE_ACTIVITY_OPTIONS_UPDATED = 'Received schedule activity options.';
export const CREATE_LESSON_PLAN_VARIATION = 'Creating new lesson plan variation...';
export const LESSON_PLAN_VARIATION_CREATED = 'Lesson plan variation created.';

// Action creators
export const createSessionScreenLoaded = () => ({
  type: CREATE_SESSION_SCREEN_LOADED,
} as const);

export const viewSessionScreenLoaded = (sessionId: number) => ({
  type: VIEW_SESSION_SCREEN_LOADED,
  payload: { sessionId },
} as const);

export const editSessionScreenLoaded = (sessionId: number) => ({
  type: EDIT_SESSION_SCREEN_LOADED,
  payload: { sessionId },
} as const);

export const setFlags = (isLoading: boolean = true, isReady: boolean = false) => ({
  type: FLAGS,
  payload: { isLoading, isReady },
} as const);

export const setCoursePlans = (plans: constants.CourseType[]) => ({
  type: COURSE_PLANS_UPDATED,
  payload: { plans },
} as const);

export const setLessonPlan = (fullSession: FullSessionType) => ({
  type: LESSON_PLAN_UPDATED,
  payload: { fullSession },
} as const);

type CreateVariationPayloadType = constants.LessonPlanVariationType & { onComplete?: () => void };
export const createLessonPlanVariation = (data: CreateVariationPayloadType) => ({
  type: CREATE_LESSON_PLAN_VARIATION,
  payload: { ...data },
} as const);

export const setLessonPlanVariation = (data: constants.LessonPlanVariationType | { error: true }) => ({
  type: LESSON_PLAN_VARIATION_CREATED,
  payload: { ...data },
} as const);

export type ScheduleSessionActionProps = {
  lessonPlanId: number,
  lessonPlanVariationId?: number,
  pilotFlying: string,
  pilotMonitoring: string,
  equipmentId: number,
  scheduledDate: string,
  scheduledTime: string,
  shouldCreateRelatedRun: boolean,
  onComplete: () => void,
};
export const scheduleSession = (sessionData: ScheduleSessionActionProps) => ({
  type: SCHEDULE_SESSION,
  payload: { ...sessionData },
} as const);

type ScheduleSessionCompletedActionProps = {
  error?: any,
  fullSession?: Object,
};
export const scheduleSessionCompleted = (response: ScheduleSessionCompletedActionProps) => ({
  type: SCHEDULE_SESSION_COMPLETED,
  payload: { ...response },
} as const);

export const setScheduleActivityOptions = (activityOptions: constants.LessonPlanActivityType[]) => ({
  type: SCHEDULE_ACTIVITY_OPTIONS_UPDATED,
  payload: { activityOptions },
} as const);

export type CreateSessionScreenLoadedAction = ReturnType<typeof createSessionScreenLoaded>;
export type ViewSessionScreenLoadedAction = ReturnType<typeof viewSessionScreenLoaded>;
export type EditSessionScreenLoadedAction = ReturnType<typeof editSessionScreenLoaded>;
export type SetFlagsActions = ReturnType<typeof setFlags>;
export type SetCoursePlansAction = ReturnType<typeof setCoursePlans>;
export type SetLessonPlanAction = ReturnType<typeof setLessonPlan>;
export type CreateLessonPlanVariationAction = ReturnType<typeof createLessonPlanVariation>;
export type SetLessonPlanVariationAction = ReturnType<typeof setLessonPlanVariation>;
export type ScheduleSessionAction = ReturnType<typeof scheduleSession>;
export type ScheduleSessionCompletedAction = ReturnType<typeof scheduleSessionCompleted>;
export type SetActivityOptionsAction = ReturnType<typeof setScheduleActivityOptions>;

export type ScheduleActions =
  | CreateSessionScreenLoadedAction
  | ViewSessionScreenLoadedAction
  | EditSessionScreenLoadedAction
  | SetFlagsActions
  | SetCoursePlansAction
  | SetLessonPlanAction
  | CreateLessonPlanVariationAction
  | SetLessonPlanVariationAction
  | ScheduleSessionAction
  | ScheduleSessionCompletedAction
  | SetActivityOptionsAction;
