import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import LandingPage from './LandingPage';
import { logout } from '../../../auth/actions';
import * as sessionListActions from '../../../sessionList/actions';
import { GeneralStoreType } from '../../../store/constants';
import { FullSessionType } from '../../../session/constants';

export type LandingPageProps = {
  isLoading: boolean,
  sessions: FullSessionType[],
  onComponentMounted: Function,
  onLogout: Function,
};

const mapStateToProps = (state: GeneralStoreType) => {
  const sessionList = state.get('sessionList');

  const { isLoading, sessions } = sessionList;

  return {
    isLoading,
    sessions,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  onComponentMounted: () => { dispatch(sessionListActions.sessionListScreenLoaded()); },
  onLogout: () => { dispatch(logout()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(withImmutablePropsToJS(LandingPage));
