import * as React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { LoginProps } from './types';
import { Input, InputWrapper } from '../../../ui/components/Input';
import { Button } from '../../../ui/Button';
import PreviewRibbon from '../../../ui/PreviewRibbon';
import paladinAiImage from '../../../assets/logo/name.white.svg';
import backgroundImage from '../../../assets/background.jpg';
import userIcon from '../../../assets/icons/user.png';
import lockIcon from '../../../assets/icons/lock.png';
import config from '../../../config';
// noinspection TypeScriptPreferShortImport
import { sanitizeUsername } from '../../utils';
import LogoSVG from '../../../ui/svg/InstructIQLogo.svg';

type Props = LoginProps & {
  error?: string,
  isAuthenticating?: boolean,
  isPreview?: boolean,
};

const Login = ({ onAuthenticate, isAuthenticating, isPreview, error }: Props) => {
  const [sanitizedUsername, setSanitizedUsername] = React.useState('');

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();

    if (typeof onAuthenticate !== 'function') {
      return;
    }

    const target = event.target as HTMLFormElement;
    const username = target.username.value;
    const password = target.password.value;
    // const isAdmin = target.isAdmin.checked;

    if (username.length < 1 || password.length < 1) {
      return;
    }

    onAuthenticate(username, password);
  };

  const handleChange = (event: React.ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    setSanitizedUsername(sanitizeUsername(target.value));
  };

  const handleReloadPage = () => window.location.reload(true);

  return (
    <PageBackground>
      {isPreview && <PreviewRibbon />}

      <Page>
        <Header>
          <LogoWrapper onClick={handleReloadPage}>
            <Logo />
          </LogoWrapper>
        </Header>

        <Form onSubmit={handleLogin} noValidate>
          {error && <ErrorMessage>{error}</ErrorMessage>}

          <InputWrapper>
            <User />
            <Input
              name="username"
              type="email"
              placeholder="USERNAME"
              value={sanitizedUsername}
              onChange={handleChange}
              disabled={isAuthenticating}
            />
          </InputWrapper>

          <InputWrapper>
            <Lock />
            <Input
              name="password"
              type="password"
              placeholder="PASSWORD"
              disabled={isAuthenticating}
              required
            />
          </InputWrapper>

          <Button
            disabled={isAuthenticating}
            width="320px"
            marginTop="40px"
          >
            {isAuthenticating ? 'AUTHENTICATING...' : 'LOGIN'}
          </Button>

        </Form>

        {isPreview && <PreviewNotice>Not For Training</PreviewNotice>}

        <Footer>
          <FooterLogo />

          <Copyright>
            &copy;
            {moment().format(' Y ')}
            Paladin AI Inc. All Rights Reserved.
          </Copyright>
        </Footer>
      </Page>
    </PageBackground>
  );
};

export default Login;

Login.defaultProps = {
  error: '',
  isAuthenticating: false,
  isPreview: config.deployEnv === 'preview',
};

// Supporting components
const PageBackground = styled.div`
  background: center center no-repeat url('${backgroundImage}');
  background-size: cover;
  font-family: 'Lato', sans-serif;
  height: 100vh;
  width: auto;
`;

const Page = styled.main`
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

const Header = styled.section`
  margin: 12vh auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Logo = styled.div`
  background: center center transparent no-repeat url('${LogoSVG}');
  background-size: contain;
  display: inline-block;
  height: 61px;
  width: 259px;
`;

const LogoWrapper = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`;

const Form = styled.form`
  width: 100%;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
`;

const PreviewNotice = styled.div`
  flex-grow: 1;
  font-size: 1.3em;
  text-align: center;
  text-transform: uppercase;
`;

const Footer = styled.footer`
  box-sizing: border-box;
  color: ${props => props.theme.textColor.string()};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0 auto 5vh auto;
  padding: auto 2em;
`;

const FooterLogo = styled.div`
  background: transparent center center no-repeat url('${paladinAiImage}');
  background-size: contain;
  width: 300px;
  height: 60px;
`;

const Copyright = styled.span`
  font-weight: 100;
`;

const ErrorMessage = styled.div`
  background-color: rgba(255, 255, 255, 0.7);
  color: ${p => p.theme.secondary.lighten(0.5).hex()};
  font-weight: bold;
  margin: auto auto 40px;
  padding: 10px 20px;
`;

const User = styled.div`
  background: center center transparent no-repeat url('${userIcon}');
  background-size: contain;
  height: 30px;
  width: 30px;
  margin-top: 10px;
`;

const Lock = styled.div`
  background: center center transparent no-repeat url('${lockIcon}');
  background-size: contain;
  height: 25px;
  width: 25px;
  margin-top: 12px;
`;
