import * as constants from '../constants';
import getDefaultVisualizations from './default';
import getSteepTurnVisualizations from './steepTurn';
import getEngineStartVisualizations from './engineStart';
import getApproachVisualizations from './approach';
import getTakeoffVisualizations from './takeoff';
import getTakeoffOEIVisualizations from './takeoffOEI';
import getLandingVisualizations from './landing';
import getRejectedTakeoffVisualizations from './rejectedTakeoff';
import { getVisualizationCategory } from '../activityCategory';

import {
  ApproachInsightType,
  SteepTurnInsightType,
  TakeoffInsightType,
  LandingInsightType,
  VisualizationCategory,
  VisualizationHandlerType,
} from '../constants';

// TODO: why can't we use a generic of the union type inside the Map type?
type HandlerType =
  | VisualizationHandlerType<ApproachInsightType>
  | VisualizationHandlerType<SteepTurnInsightType>
  | VisualizationHandlerType<TakeoffInsightType>
  | VisualizationHandlerType<LandingInsightType>
  | VisualizationHandlerType<void>;

// @ts-ignore
const handlers: Map<VisualizationCategory, HandlerType> = new Map([
  [constants.CATEGORY_APPROACH, getApproachVisualizations],
  [constants.CATEGORY_ENGINE_START, getEngineStartVisualizations],
  [constants.CATEGORY_STEEP_TURN, getSteepTurnVisualizations],
  [constants.CATEGORY_TAKEOFF_OEI, getTakeoffOEIVisualizations],
  [constants.CATEGORY_TAKEOFF, getTakeoffVisualizations],
  [constants.CATEGORY_LANDING, getLandingVisualizations],
  [constants.CATEGORY_REJECTED_TAKEOFF, getRejectedTakeoffVisualizations],
]);

// @ts-ignore
const getVisualizationsForActivity: HandlerType = (activity, data, markers, metaData) => {
  const category = getVisualizationCategory(activity);
  const handler = handlers.get(category) || getDefaultVisualizations;

  return handler(activity, data, markers, metaData);
};

export default getVisualizationsForActivity;
