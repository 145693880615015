import getAirPlotOptions from './air';
import {
  annotationVR,
} from '../annotations';
import {
  normGroundRollColumn,
  normGroundRollHeading,
} from '../annotations/norms';
import {
  TakeoffInsightType,
  VisualizationHandlerType,
} from '../../visualization/constants';

// @ts-ignore
const getTakeoffGroundRollPlotOptions: VisualizationHandlerType<TakeoffInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const { insight, frames } = data;

  const wowIndex = frames.findIndex(({ data: framesData }) => framesData.wowAny === false || framesData.wowAny === 0.0);
  const framesFrombeginingToLiftoff = wowIndex !== 0 ? frames.slice(0, wowIndex + 1) : frames;
  let plotOptions;

  if (metaData.aircraftDesignator === 'A320') {
    plotOptions = getAirPlotOptions(framesFrombeginingToLiftoff, markers, activity, metaData,
      ['hdgTrue', ['fcInceptorLPosPitch', 'fcInceptorRPosPitch'], 'cas']);
  } else {
    plotOptions = getAirPlotOptions(framesFrombeginingToLiftoff, markers, activity, metaData,
      ['hdgTrue', 'colPosPilot', 'cas']);
  }

  if (metaData.trainingCenter !== 'NLA') {
    annotationVR(framesFrombeginingToLiftoff, plotOptions, 'cas');

    let groundRollColumn; let groundRollHeading;
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    if (insight && insight.summary) {
      insight.summary.payload.forEach((e, i) => {
        if (e && e.normCode === 'GNDROLL_FWD_COL') {
          groundRollColumn = insight.summary?.payload[i].envelope;
        }
        if (e && e.normCode === 'GNDROLL_HDG') {
          groundRollHeading = insight.summary?.payload[i].envelope;
        }
      });

      if (groundRollColumn) {
        normGroundRollColumn(framesFrombeginingToLiftoff, plotOptions, groundRollColumn, 'colPosPilot');
      }
      if (groundRollHeading) {
        normGroundRollHeading(framesFrombeginingToLiftoff, plotOptions, groundRollHeading, 'hdgTrue');
      }
    }
  }

  return plotOptions;
};
export default getTakeoffGroundRollPlotOptions;
