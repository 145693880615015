import * as React from 'react';
import styled from 'styled-components';
import {
  Button,
  BUTTON_TAB,
  BUTTON_TAB_GRADIENT,
  ButtonVariationType,
} from '../../../ui/Button';
import { RunIdType } from '../../../session/constants';

type RunSelectorType = (id: number) => void;

type Props = {
  runIds: RunIdType[],
  selectedRunId: RunIdType | null,
  pilotFlyingName: string,
  pilotMonitoringName?: string,
  onSelectRun: RunSelectorType,
};

const TraineeTabs = (props: Props) => {
  const {
    onSelectRun,
    runIds,
    pilotFlyingName,
    pilotMonitoringName,
    selectedRunId,
  } = props;

  return (
    <Wrapper>
      {runIds.map(id => (
        <RunSelector
          key={id}
          id={id}
          pilotFlying={id === selectedRunId ? pilotFlyingName : (pilotMonitoringName || '')}
          onSelectRun={onSelectRun}
          btnVariation={id === selectedRunId ? BUTTON_TAB_GRADIENT : BUTTON_TAB}
          selected={id === selectedRunId}
          singleSession={runIds.length === 1}
        />
      ))}
    </Wrapper>
  );
};

TraineeTabs.defaultProps = {
  pilotMonitoringName: '',
};

const Wrapper = styled.div`
  box-sizing: border-box;
  color: ${props => props.theme.inverseTextColor.string()};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  top: 153px;
  z-index: 11;
`;

const Text = styled.div<{ selected?: boolean }>`
  color: ${props => (props.selected ? 'white' : props.theme.textColor.darken(0.6).string())};
  font-size: 16px;
`;

type RunSelectorProps = {
  btnVariation: ButtonVariationType,
  id: number,
  pilotFlying: string,
  onSelectRun: RunSelectorType,
  selected?: boolean,
  singleSession: boolean,
};

const RunSelector = (props: RunSelectorProps) => {
  const onClick = () => props.onSelectRun(props.id);

  return (
    <Button
      margin="0.4em"
      onClick={onClick}
      variation={props.btnVariation}
      width={props.singleSession ? '100%' : '50%'}
    >
      <Text selected={props.selected} data-selected-pilot={props.selected}>
        {props.pilotFlying}
      </Text>
    </Button>
  );
};

RunSelector.defaultProps = {
  selected: false,
};

export default TraineeTabs;
