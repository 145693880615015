/**
 * Creates a combined reducer for the app.
 *
 * NOTE: don't forget to update the typedef in `src/store/constants.js` for the shape of
 * the store when adding new reducers here.
 */
import { combineReducers } from 'redux-immutable';

import activities from './activities/reducer';
import auth from './auth/reducer';
import competency from './competency/reducer';
import dashboard from './dashboard/reducer';
import frames from './frames/reducer';
import summary from './summary/reducer';
import insight from './insight/reducer';
import training from './training/reducer';
import recommendations from './recommendations/reducer';
import schedule from './schedule/reducer';
import session from './session/reducer';
import sessionList from './sessionList/reducer';
import instructor from './instructor/reducer';
import profile from './userProfile/reducer';

// Combine reducers.
export default combineReducers({
  activities,
  auth,
  competency,
  dashboard,
  frames,
  summary,
  insight,
  training,
  recommendations,
  schedule,
  session,
  sessionList,
  instructor,
  profile,
});
