// @ts-nocheck
import React from 'react';
import styled from 'styled-components';
import { NavLink, useHistory } from 'react-router-dom';

import { Props } from './types';
import { GradingContextHandlerType } from '../../constants';

import Body from '../../../ui/Body';
import TraineeTabs from './TraineeTabs';
import Header from '../../../ui/headers/Header';
import PageHeader from '../../../ui/PageHeader';
import { CompetencyRadarPlot } from '../../../competency';
import TaskList from '../TaskList';
import { accentBlue, lightestGrey, lightGrey, mediumGrey } from '../../../theme/palette';
import PilotIcon from '../../../ui/svg/pilot-icon.svg';
import { traineeProfile } from '../../../utils/routes';
import Loading from '../../../ui/Loading';
import ContentContainer from '../../../ui/ContentContainer';

const GradingScreen = (props: Props) => {
  const {
    fullSession,
    gradingSheets,
    activityGrades,
    ksaComponentGrades,
    onComponentMounted,
    onSelectSessionRun,
    runs,
    onSaveNote,
    sessions,
    isLoading,
  } = props;

  const sessionId = Number(props.match.params.id);

  const history = useHistory();

  const [selectedRunId, setSelectedRunId] = React.useState(null);

  React.useEffect(() => {
    onComponentMounted(sessionId);
  }, [onComponentMounted, sessionId]);

  const runIds = runs.map(run => run.id);
  const currentRun = runs.find(run => run.id && run.id === selectedRunId);
  const activities = selectedRunId ? props.activities[selectedRunId] : [];
  const markers = selectedRunId && props.markers ? (props.markers[selectedRunId] || []) : [];

  const gradingSheet = gradingSheets && selectedRunId && gradingSheets[selectedRunId]
    ? gradingSheets[selectedRunId]
    : null;

  const handleSelectRun = (runId: number) => {
    props.onUpdateRunId(runId);
    props.onClearTraineeProfile();

    // Update selected run ID in state and
    // call the session run handler.
    setSelectedRunId(runId);
    onSelectSessionRun(runId);
  };

  const { onUpdateGradeLevel, onSelectDemo } = props;
  const handleUpdateGradeLevel = (grade, level, type) => onUpdateGradeLevel(grade, level, selectedRunId, type);

  const handleOpenGradeContext: GradingContextHandlerType = activityId => {
    history.push(`/context/${sessionId}/${selectedRunId}/${activityId}`);
  };

  const handleSelectDemo = (activityId: number) => {
    if (typeof onSelectDemo === 'function') {
      onSelectDemo(activityId);
    }
  };

  const handleGoBack = () => {
    props.onClearTraineeProfile();
    props.onDone();
    history.push(`/session/${sessionId}`);
  };

  // If no run is selected, select the first available one.
  if (!selectedRunId && runs.length > 0) {
    handleSelectRun(runs[0].id);
  }

  // const handleSubmitInstructorFeedback = (instructorFeedback: boolean) => {
  //   onSubmitInstructorFeedback('session', selectedRunId, instructorFeedback);
  // };

  if (!gradingSheet || !gradingSheet.sections || gradingSheet.sections.length < 1 || !currentRun || isLoading) {
    return (
      <Loading />
    );
  }

  const sessionIndex = sessions.findIndex(({ id }) => id === sessionId);
  const session = sessions[sessionIndex];

  const pilotFlying = session.plannedRuns[0] === selectedRunId
    ? session.trainees.pilotFlying
    : session.trainees.pilotMonitoring;
  const pilotMonitoring = session.plannedRuns[0] === selectedRunId
    ? session.trainees.pilotMonitoring
    : session.trainees.pilotFlying;

  return (
    <>
      <Header back={handleGoBack} />
      <PageHeader title="Training Summary" />

      <ContentContainer>
        <TraineeTabs
          onSelectRun={handleSelectRun}
          runIds={runIds}
          selectedRunId={selectedRunId}
          pilotFlyingName={pilotFlying.name}
          pilotMonitoringName={pilotMonitoring.name}
        />

        <Body>
          <TraineeWrapper>
            <TraineeInfo>
              <TraineeIcon />
              <Content>
                {pilotFlying.name}
                <Info>PILOT FLYING</Info>
              </Content>
            </TraineeInfo>
            <NavLink to={traineeProfile(pilotFlying.id)}>
              <TraineeHistory>View History</TraineeHistory>
            </NavLink>
          </TraineeWrapper>

          <GradingSheet>
            <CourseDetails>
              {fullSession.courseName}
              <LessonName>{fullSession.name}</LessonName>
            </CourseDetails>

            <Grade>Grade</Grade>

            <TaskList
              gradingSheet={gradingSheet}
              activityGrades={activityGrades}
              ksaComponentGrades={ksaComponentGrades}
              activities={activities}
              markers={markers}
              onShowGradeContext={handleOpenGradeContext}
              onUpdateGradeLevel={handleUpdateGradeLevel}
              onSelectDemo={handleSelectDemo}
              onSaveNote={onSaveNote}
            />
          </GradingSheet>

          {selectedRunId && (
            <CompetencyRadarPlot
              runId={selectedRunId}
              activities={activities}
              onShowGradeContext={handleOpenGradeContext}
              edit
            />
          )}

          {/* {sessionRecommendations[selectedRunId]
        && sessionRecommendations[selectedRunId].recommendations.length > 0 && (
          <TrainingRecommendationsWrapper>
            <Recommendations
              recommendations={sessionRecommendations[selectedRunId]}
              onSubmitInstructorFeedback={handleSubmitInstructorFeedback}
            />
          </TrainingRecommendationsWrapper>
        )} */}
        </Body>
      </ContentContainer>
    </>
  );
};

const TraineeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  height: 50px;
  max-width: 100%;
  margin: 28px 0;
`;

const TraineeInfo = styled.div`
  font-size: 20px;
  display: flex;
`;

const TraineeIcon = styled.div`
  background: center center transparent no-repeat url('${PilotIcon}');
  background-size: contain;
  height: 50px;
  width: 50px;
`;

const Content = styled.div`
  margin-left: 15px;
`;

const Info = styled.div`
  font-size: 12px;
  color: ${lightestGrey.string()};
`;

const TraineeHistory = styled.div`
  color: ${accentBlue.string()};
  font-size: 16px;
  line-height: 50px;
`;

const GradingSheet = styled.div`
  background-color: ${mediumGrey.string()};
`;

const CourseDetails = styled.div`
  line-height: 50px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  border-bottom: 1px solid ${lightGrey.string()};
`;

const LessonName = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  color: ${lightestGrey.string()};
`;

const Grade = styled(LessonName)`
  line-height: 30px;
  padding: 10px 90px;
  text-align: right;
`;

// const TrainingRecommendationsWrapper = styled.div`
//   padding: 25px;
//   max-width: 70%;
//   margin: auto;
// `;

export default GradingScreen;
