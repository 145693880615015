import React from 'react';
import styled from 'styled-components';

import DiscLoader from '../../../ui/components/CssDiscLoader';
import { accent, textColor } from '../../../theme/palette';

const Timer = ({ repeat }: { repeat: boolean }) => (
  <TimerWrapper>
    <TimerText>
      {repeat === true ? 'HOLD TO REPEAT' : 'HOLD TO START'}
    </TimerText>

    <TimerCounterWrapper>
      <DiscLoader
        backgroundColor={accent.string()}
        progressColor={textColor.darken(0.9).string()}
        trackColor={accent.darken(0.4).string()}
        size={45}
        innerSize={25}
      />
    </TimerCounterWrapper>
  </TimerWrapper>
);

export default Timer;

const TimerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TimerText = styled.span`
  color: ${props => props.theme.textColor.darken(0.9).string()};
  display: inline-block;
  font-size: 0.8rem;
  margin: auto 10px auto auto;
`;

const TimerCounterWrapper = styled.div`
  position: relative;
  width: 45px;
  height: 45px;
  margin-right: 20px;
`;
