import React from 'react';

import { Button, BUTTON_LIGHT } from '../../../ui/Button';

const Next = ({ onClick }: { onClick: () => void }) => (
  <Button onClick={onClick} width="100px" margin="none" variation={BUTTON_LIGHT}>
    NEXT
  </Button>
);

export default Next;
