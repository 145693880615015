import { fromJS } from 'immutable';
import * as actions from './actions';
import defaultState, { ImmutableTrainingStore } from './store';

export default (
  state: ImmutableTrainingStore = defaultState,
  action: actions.LiveSessionAction,
): ImmutableTrainingStore => {
  switch (action.type) {
    case actions.TRAINING_SESSION_EVENT:
      return state.merge(action.payload);
    case actions.TRAINING_SESSION_STARTED:
    case actions.TRAINING_SESSION_ENDED:
      return state.merge({ ...action.payload, simulatorStatus: null });
    case actions.TRAINING_SESSION_SIMULATOR_STATUS_EVENT:
      return state.merge({
        simulatorStatus: fromJS(action.payload.status),
      });
    default:
      return state;
  }
};
