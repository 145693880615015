import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import { RouteComponentProps } from 'react-router';
import SessionOverview from './SessionOverview';
import * as actions from '../../actions';
import { FullSessionType, SessionRunType } from '../../../session/constants';
import { GeneralStoreType } from '../../../store/constants';
import { EquipmentType } from '../../../dashboard/constants';

// Props provided by this container
export type SessionOverviewPropsType = RouteComponentProps<{sessionId: string}> & {
  currentSession: FullSessionType,
  runs: SessionRunType[],
  sessions: FullSessionType[],
  isLoading: boolean,
  equipment: EquipmentType[],
  onComponentMounted: Function,
};

const mapStateToProps = (state: GeneralStoreType) => {
  const session = state.get('session');
  const sessionList = state.get('sessionList');
  const dashboard = state.get('dashboard');

  const { currentSession, runs } = session;
  const { sessions, isLoading } = sessionList;
  const { equipment } = dashboard;

  return {
    currentSession,
    runs,
    sessions,
    isLoading,
    equipment,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  onComponentMounted: (sessionId: string) => dispatch(actions.sessionOverviewScreenLoaded(sessionId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(withImmutablePropsToJS(SessionOverview));
