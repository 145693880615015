import * as actions from './actions';
import * as constants from './constants';
import defaultState, { ImmutableAuthStore } from './store';

export default (
  state: ImmutableAuthStore = defaultState,
  action: actions.AuthAction,
): ImmutableAuthStore => {
  switch (action.type) {
    case actions.UPDATE_AUTHENTICATION_STATUS: {
      const {
        isInstructorAdmin,
        isAuthenticating,
        status,
        token,
        user,
      } = action.payload;
      return state.merge({
        isInstructorAdmin,
        isAuthenticating,
        user,
        status,
        token,
      });
    }

    case actions.VERIFY_TOKEN:
      return state.merge({
        isAuthenticating: true,
        status: constants.AUTH_STATUS_VERIFYING,
      });

    default:
      return state;
  }
};
