/**
 * The Guard container is used to protect the dashboard from unauthorized
 * access from the client.
 */
import * as React from 'react';
import { GuardContainerProps } from './types';
import Login from '../Login';
import { home, instructorManager } from '../../../utils/routes';
import InstructorManager from '../InstructorManager';
import Loading from '../../../ui/Loading';
import * as constants from '../../constants';
import * as utils from '../../utils';

type Props = GuardContainerProps & {
  children: React.ReactNode,
};

const Guard = (props: Props) => {
  const {
    handleLogout,
    isInstructorAdmin,
    requireToken,
    status,
    token,
    verifyToken,
  } = props;

  const isAuthenticated = status === constants.AUTH_STATUS_AUTHENTICATED;

  // Retrieve JWT token from local store.
  React.useEffect(() => {
    if (!token && !isAuthenticated) {
      utils
        .getToken()
        .then(storedToken => {
          if (storedToken && storedToken.length > 0) {
            // Verify stored token.
            return verifyToken(storedToken);
          }
          // Require a new token.
          return requireToken();
        })
        .catch(() => {});
    }
  }, [token, isAuthenticated, requireToken, verifyToken]);

  // Temporarily track if authenticated user is a regular instructor or
  // an instructor administrator.
  const [isAdmin, setIsAdmin] = React.useState(isInstructorAdmin);

  React.useEffect(() => {
    // We need to keep track of whether the component is mounted or not, as we
    // cannot set state otherwise.
    let isMounted = true;

    utils
      .getIsInstructorAdmin()
      .then(result => isMounted && setIsAdmin(result))
      .catch(() => {});

    return () => {
      isMounted = false;
    };
  });

  switch (true) {
    // If we're on the logout route, invalidate the token and redirect to the
    // login page.
    case utils.isLogoutRoute():
      handleLogout(home());
      return null;

    // If we're authenticated, redirect to requested page.
    case status === constants.AUTH_STATUS_AUTHENTICATED && utils.isLoginRoute():
    {
      let redirectTo = '/';
      const { search } = window.location;

      if (search && search.indexOf('=') > 0) {
        search
          .replace('?', '')
          .split('&')
          .forEach(pair => {
            // If the "return" query parameter is set, sanitize it and use it.
            if (pair.indexOf('return=') === 0) {
              redirectTo = decodeURIComponent(pair.substring(7)).replace(
                /([^0-9a-zA-Z/_-])/,
                '',
              );
            }
          });
      }

      window.location.assign(redirectTo);

      return null;
    }

    // If we don't yet have a token, wait until we retrieve it from the store.
    case status === constants.AUTH_STATUS_AUTHENTICATED && (!token || token.length < 1):
      return <Loading />;

    // TEMPORARY: instructor administrators can only see one view.
    case status === constants.AUTH_STATUS_AUTHENTICATED && isAdmin:
      if (utils.isInstructorAdminRoute()) {
        return <InstructorManager />;
      }

      window.location.assign(instructorManager());

      return <Loading />;

    // If we're authenticated and not on the login page, render the app.
    case status === constants.AUTH_STATUS_AUTHENTICATED && !utils.isLoginRoute():
      return props.children;

    // If we're on the login page, and we're not authenticated, show the login form.
    case status !== constants.AUTH_STATUS_AUTHENTICATED && utils.isLoginRoute():
      return <Login />;

    default:
      return <Loading />;
  }
};

export default Guard;
