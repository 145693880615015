import Color from 'color';
import { getMaxValue, getMinValue, getPlotIndex } from './utils';
import { PlotlyOptions } from '../types';
import { AirspeedNormType, GearRetractionNormType, PitchNormType,
  GroundRollColNormType, LateralAccNormTypeNormType,
  GroundRollHeadingNormType } from '../../visualization/constants';
import { white } from '../../../theme/palette';
import { FrameType } from '../../../frames/constants';

/**
 * Creates a function to be used with `Array.prototype.find` or `Array.prototype.findIndex`.
 */
type FrameFinderType = (frame: FrameType) => boolean;
const findFrame = (target: string, param: keyof FrameType = 'timestamp'): FrameFinderType => frame => {
  if (!frame || !(param in frame)) {
    return false;
  }

  const paramValue: any = frame[param];

  return paramValue >= target;
};

/**
 * Draws a shaded area using SVG.
 */
const getSvgPath = (
  frames: FrameType[],
  lowerPercentile: number[],
  upperPercentile: number[],
  timestamps: string[],
) => {
  if (!lowerPercentile || !upperPercentile || !timestamps) {
    return null;
  }

  const startTimestamp = timestamps[0];
  const startIndex = frames.findIndex(findFrame(startTimestamp));

  if (startIndex < 0 || startIndex === frames.length - 1) {
    // TODO: log inconsistency.
    return null;
  }

  // Find last frame that's within the ending timestamp.
  const endTimestamp = timestamps[timestamps.length - 1];
  const relativeEndIndex = frames.slice(startIndex + 1).findIndex(findFrame(endTimestamp));
  const endIndex = relativeEndIndex > 0
    ? startIndex + relativeEndIndex - 1
    : frames.length - 1;

  // Estimate the number of percentile values we have for each frame.
  // TODO: this won't be necessary once we update the API to retrieve frames from DynamoDB.
  const percentileStartIndex = timestamps.findIndex(t => t >= frames[startIndex].timestamp);
  const percentileEndIndex = timestamps.findIndex(t => t >= frames[endIndex].timestamp);
  const valuesPerFrame = (percentileEndIndex - percentileStartIndex) / (endIndex - startIndex);

  // Number to divide a percentile value's index by to get the interpolated
  // position on the "x" axis of the plot.
  const percentileIndexDivisor = frames.length * valuesPerFrame;

  // Starting "x" value for SVG path.
  const xStart = startIndex / frames.length;

  let i = 0;
  let path = `M ${xStart} ${lowerPercentile[0]}`;

  for (i = 1; i < lowerPercentile.length; i++) {
    path += `L ${xStart + i / percentileIndexDivisor} ${lowerPercentile[i]}`;
  }

  for (i = upperPercentile.length - 1; i > -1; i--) {
    path += `L ${xStart + i / percentileIndexDivisor} ${upperPercentile[i]}`;
  }
  return path;
};

export const normAirspeed = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  insight: AirspeedNormType,
  plotName: string,
) => {
  const path = getSvgPath(
    frames,
    insight.percentile_5,
    insight.percentile_95,
    insight.timestamp,
  );

  if (!path) {
    return;
  }

  const plotIndex = getPlotIndex(plotOptions, plotName);

  plotOptions.layout.shapes.push({
    type: 'path',
    xref: 'paper',
    // @ts-ignore
    yref: plotOptions.data[plotIndex].yaxis,
    layer: 'below',
    path,
    // @ts-ignore
    fillcolor: Color(plotOptions.data[plotIndex].line.color).darken(0.6).string(),
    line: {
      // @ts-ignore
      color: Color(plotOptions.data[plotIndex].line.color).darken(0.6).string(),
      width: 2,
    },
  });
};

export const normGearUp = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  insight: GearRetractionNormType,
  plotName: string,
) => {
  const {
    percentile_5: lowerBound,
    percentile_95: upperBound,
  } = insight;

  if (lowerBound.timestamp === null || upperBound.timestamp === null) {
    return;
  }

  const plotIndex = getPlotIndex(plotOptions, plotName);
  const lowerBoundIndex = frames.findIndex(
    ({ timestamp }) => timestamp > lowerBound.timestamp,
  );
  const upperBoundIndex = frames.findIndex(
    ({ timestamp }) => timestamp > upperBound.timestamp,
  );

  if (plotIndex < 0 || lowerBoundIndex < 0 || upperBoundIndex < 0) {
    return;
  }

  const minValue = Number(getMinValue(frames, plotName));
  const maxValue = Number(getMaxValue(frames, plotName));

  plotOptions.layout.shapes.push({
    type: 'rect',
    xref: 'paper',
    // @ts-ignore
    yref: plotOptions.data[plotIndex].yaxis,
    x0: lowerBoundIndex / frames.length,
    x1: upperBoundIndex / frames.length,
    y0: minValue,
    y1: maxValue,
    fillcolor: white.fade(0.6).string(),
    layer: 'below',
  });
};

export const normPitch = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  insight: PitchNormType,
  plotName: string,
) => {
  const path = getSvgPath(
    frames,
    insight.percentile_5,
    insight.percentile_95,
    insight.timestamp,
  );

  if (!path) {
    return;
  }

  const plotIndex = getPlotIndex(plotOptions, plotName);

  plotOptions.layout.shapes.push({
    type: 'path',
    xref: 'paper',
    // @ts-ignore
    yref: plotOptions.data[plotIndex].yaxis,
    layer: 'below',
    path,
    // @ts-ignore
    fillcolor: Color(plotOptions.data[plotIndex].line.color).darken(0.6).string(),
    line: {
      // @ts-ignore
      color: Color(plotOptions.data[plotIndex].line.color).darken(0.6).string(),
      width: 2,
    },
  });
};

export const normGroundRollColumn = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  insight: GroundRollColNormType,
  plotName: string,
) => {
  const path = getSvgPath(
    frames,
    insight.percentile_5,
    insight.percentile_95,
    insight.timestamp,
  );

  if (!path) {
    return;
  }

  const plotIndex = getPlotIndex(plotOptions, plotName);
  if (plotIndex < 0) {
    return;
  }
  plotOptions.layout.shapes.push({
    type: 'path',
    xref: 'paper',
    // @ts-ignore
    yref: plotOptions.data[plotIndex].yaxis,
    layer: 'below',
    path,
    // @ts-ignore
    fillcolor: Color(plotOptions.data[plotIndex].line.color).darken(0.6).string(),
    line: {
      // @ts-ignore
      color: Color(plotOptions.data[plotIndex].line.color).darken(0.6).string(),
      width: 9,
    },
  });
};

export const normLateralAcceleration = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  insight: LateralAccNormTypeNormType,
  plotName: string,
) => {
  const path = getSvgPath(
    frames,
    insight.percentile_5,
    insight.percentile_95,
    insight.timestamp,
  );

  if (!path) {
    return;
  }

  const plotIndex = getPlotIndex(plotOptions, plotName);
  if (plotIndex < 0) {
    return;
  }

  plotOptions.layout.shapes.push({
    type: 'path',
    xref: 'paper',
    // @ts-ignore
    yref: plotOptions.data[plotIndex].yaxis,
    layer: 'below',
    path,
    // @ts-ignore
    fillcolor: Color(plotOptions.data[plotIndex].line.color).darken(0.6).string(),
    line: {
      // @ts-ignore
      color: Color(plotOptions.data[plotIndex].line.color).darken(0.6).string(),
      width: 9,
    },
  });
};
export const normGroundRollHeading = (
  frames: FrameType[],
  plotOptions: PlotlyOptions,
  insight: GroundRollHeadingNormType,
  plotName: string,
) => {
  const {
    timestampStart,
    timestampEnd,
    sd,
    trueHeading,
  } = insight;

  if (timestampStart === null || timestampEnd === null || timestampStart === undefined || timestampEnd === undefined) {
    return;
  }

  const plotIndex = getPlotIndex(plotOptions, plotName);
  const lowerBoundIndex = frames.findIndex(
    ({ timestamp }) => timestamp.split('.')[0] === timestampStart.split('.')[0],
  );

  const upperBoundIndex = frames.findIndex(
    ({ timestamp }) => timestamp.split('.')[0] === timestampEnd.split('.')[0],
  );

  if (plotIndex < 0 || lowerBoundIndex < 0 || upperBoundIndex < 0) {
    return;
  }

  plotOptions.layout.shapes.push({
    type: 'rect',
    xref: 'paper',
    // @ts-ignore
    yref: plotOptions.data[plotIndex].yaxis,
    x0: lowerBoundIndex / frames.length,
    x1: upperBoundIndex / frames.length,
    y0: trueHeading - sd,
    y1: trueHeading + sd,
    fillcolor: white.fade(0.6).string(),
    layer: 'below',
  });
};
