import { List } from 'immutable';

export * from './competency';
export * from './grade';
export * from './gradingSheet';

type FilteredActivityType = {
  code: string,
  name: string,
  flightPhase: string,
  condition: string,
  grade: number,
};

export type FilteredActivitiesType = List<FilteredActivityType>;
