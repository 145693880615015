/**
 * Time-based loader using CSS keyframes. Made for activity launcher during live
 * training sessions for now.
 */
import * as React from 'react';
import styled, { keyframes } from 'styled-components';

// Types & constants
import config from '../../config';

type Props = {
  backgroundColor?: string,
  progressColor?: string,
  trackColor?: string,

  innerSize?: number,
  size?: number,
  sizeUnit?: string,
};

function DiscLoader(props: Props) {
  const size = props.size || 50;
  const innerSize = props.innerSize || 40;
  const sizeUnit = props.sizeUnit || 'px';

  // Width of loader.
  const width = size.toString() + sizeUnit;

  // Width of inner disc
  const innerWidth = innerSize.toString() + sizeUnit;
  const innerPosition = ((size - innerSize) / 2).toString() + sizeUnit;

  return (
    <Loader width={width}>
      <Track backgroundColor={props.trackColor} />

      <Progress backgroundColor={props.progressColor}>
        <InnerProgressClip backgroundColor={props.trackColor} width={width} />
      </Progress>

      <BelowHalfProgressClip
        backgroundColor={props.trackColor}
        width={width}
      />

      <AboveHalfProgressClip
        backgroundColor={props.progressColor}
        width={width}
      />

      <InnerDisc
        backgroundColor={props.backgroundColor}
        width={innerWidth}
        position={innerPosition}
      />
    </Loader>
  );
}

DiscLoader.defaultProps = {
  backgroundColor: 'white',
  progressColor: 'mediumseagreen',
  trackColor: 'gainsboro',

  innerSize: 35,
  size: 55,
  sizeUnit: 'px',
};

export default DiscLoader;

type LoaderProps = {
  width?: string,
};

const Loader = styled.div<LoaderProps>`
  border-radius: 50%;
  display: block;
  height: ${props => props.width};
  width: ${props => props.width};
  position: relative;
`;

const Track = styled.div<Props>`
  background-color: ${props => props.backgroundColor || 'gainsboro'};
  border-radius: 50%;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
`;

const progressAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

type ProgressProps = {
  backgroundColor?: string,
};

const Progress = styled.div<ProgressProps>`
  animation: ${config.activityLaunchTimeout}ms ${progressAnimation} linear infinite;
  background-color: ${props => props.backgroundColor || 'mediumseagreen'};
  border-radius: 50%;
  height: 100%;
  width: 100%;

  position: absolute;
  top: 0;
  left: 0;
`;

type ProgressClipProps = {
  backgroundColor?: string,
  width?: string,
};

const ProgressClip = styled.div<ProgressClipProps>`
  background-color: ${props => props.backgroundColor};
  border-top-right-radius: ${props => props.width};
  border-bottom-right-radius: ${props => props.width};
  display: block;
  height: 100%;
  width: 50%;

  position: absolute;
  top: 0;
  left: 0;
`;

const InnerProgressClip = styled(ProgressClip)`
  left: 50%;
`;

const belowHalfProgressAnimation = keyframes`
  0% {
    opacity: 1;
  }

  1% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
`;

const BelowHalfProgressClip = styled(ProgressClip)`
  animation: ${config.activityLaunchTimeout / 2}ms ${belowHalfProgressAnimation} linear;
  animation-delay: ${config.activityLaunchTimeout / 2}ms;
  opacity: 1;
  transform: rotate(180deg);
`;

const aboveHalfProgressAnimation = keyframes`
  0% {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
`;

const AboveHalfProgressClip = styled(ProgressClip)`
  animation: ${config.activityLaunchTimeout / 2}ms ${aboveHalfProgressAnimation} linear;
  animation-delay: ${config.activityLaunchTimeout / 2}ms;
  background-color: ${props => props.backgroundColor || 'mediumseagreen'};
  left: 50%;
  opacity: 0;
`;

type InnerDiscProps = {
  backgroundColor?: string,
  width?: string,
  position?: string,
};

const InnerDisc = styled.div<InnerDiscProps>`
  background-color: ${props => props.backgroundColor || 'white'};
  border-radius: 50%;
  display: block;
  height: ${props => props.width};
  width: ${props => props.width};

  position: absolute;
  left: ${props => props.position};
  top: ${props => props.position};
`;
