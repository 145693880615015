import {
  SessionRunType,
  FullSessionType,
} from './constants';
import { ActivityType } from '../activities/types';

export const UPDATE_SESSION_DETAILS = 'Received meta data.';
export const UPDATE_RUNS = 'Received meta data for session runs.';
export const CREATE_ACTIVITY = 'Creating new activity...';

export const updateSession = (data: FullSessionType) => ({
  type: UPDATE_SESSION_DETAILS,
  payload: { data },
} as const);

export const updateRuns = (runs: SessionRunType[]) => ({
  type: UPDATE_RUNS,
  payload: { runs },
} as const);

export const createActivity = (
  runId?: number | null,
  placeholderId?: string,
  activity?: Partial<ActivityType>,
) => ({
  type: CREATE_ACTIVITY,
  payload: { runId, placeholderId, activity },
} as const);

export type UpdateSessionAction = ReturnType<typeof updateSession>;
export type UpdateRunsAction = ReturnType<typeof updateRuns>;
export type CreateActivityAction = ReturnType<typeof createActivity>;
export type SessionAction =
  | UpdateSessionAction
  | UpdateRunsAction
  | CreateActivityAction;
