import { Map } from 'immutable';
import { Moment } from 'moment';
import { RunIdType } from '.';

export const EVENT_TYPE_MARKER = 3;

export type EventTypeType = typeof EVENT_TYPE_MARKER;

export type SessionEventType = {
  id: number,
  sessionRunId: RunIdType,
  eventType: EventTypeType,
  data?: string,
  timestamp: Moment | null,
};

export type SessionMarkerMapType = Map<RunIdType, SessionEventType[]>;
