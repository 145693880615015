/* eslint react/jsx-filename-extension: OFF */
import React from 'react';
import styled from 'styled-components';
import * as palette from '../../theme/palette';

const colors = new Map([
  [1, palette.gradeLevel1],
  [2, palette.gradeLevel5],
  [3, palette.gradeLevel5],
  [4, palette.gradeLevel9],
]);

const getDiff = (initial, deviation) => {
  const diff = deviation - initial;
  if (diff > 0) {
    return `+${Math.round(diff)}`;
  }
  return Math.round(diff).toString();
};

const getRoll = roll => (
  roll > 0 ? `+${Math.round(roll)}` : Math.round(roll).toString()
);

const getHdg = hdg => {
  if (hdg < 100) {
    return `0${Math.round(hdg)}`;
  }
  return Math.round(hdg).toString();
};

const getLineStart = direction => {
  let d = '';
  if (direction > 0) {
    d = 'M168 270 L301.5 270 L301.5 278 L320.5 268.5 L301.5 259 L301.5 267 L168 267 Z';
  } else {
    d = 'M454 270 L320.5 270 L320.5 278 L301.5 268.5 L320.5 259 L320.5 267 L454 267 Z';
  }
  return (
    <path
      d={d}
      fill="#FFFFFF"
      fillRule="nonzero"
    />
  );
};

const getLineMiddle = direction => {
  let d = '';
  if (direction > 0) {
    d = 'M301.5 474.5 L320.5 484 L320.5 465 Z';
  } else {
    d = 'M301.5 465 L320.5 474.5 L301.5 484 Z';
  }
  return (
    <path
      id="Line"
      d={d}
      fill="#FFFFFF"
      fillRule="nonzero"
    />
  );
};

const getLineEnd = (direction, color) => {
  let d = '';
  switch (direction) {
    case 180:
      d = 'M311 476 L177.5 476 L177.5 484 L158.5 474.5 L177.5 465 L177.5 473 L311 473 Z';
      break;
    case -180:
      d = 'M311 476 L444.5 476 L444.5 484 L463.5 474.5 L444.5 465 L444.5 473 L311 473 Z';
      break;
    case -360:
      d = 'M311 270 L177.5 270 L177.5 278 L158.5 268.5 L177.5 259 L177.5 267 L311 267 Z';
      break;
    default:
      d = 'M311 270 L444.5 270 L444.5 278 L463.5 268.5 L444.5 259 L444.5 267 L311 267 Z';
  }
  return (
    <path
      id="Line"
      d={d}
      fill={color}
      fillRule="nonzero"
    />
  );
};

const getEllipse = direction => {
  switch (direction) {
    case 180:
      return (
        <path
          d="M311 268.5 C501.66 268.5, 501.66 474.5, 311 474.5"
          strokeWidth="3"
          stroke="#FFFFFF"
          fill="transparent"
        />
      );
    case -180:
      return (
        <path
          d="M311 268.5 C120.34 268.5, 120.34 474.5, 311 474.5"
          strokeWidth="3"
          stroke="#FFFFFF"
          fill="transparent"
        />
      );
    default:
      return (
        <ellipse
          id="Oval"
          stroke="#FFFFFF"
          strokeWidth="3"
          cx="311"
          cy="371.5"
          rx="143"
          ry="103"
        />
      );
  }
};

const getHdgRect = direction => {
  if (direction > 0) {
    return (
      <rect stroke="#FFFFFF" strokeWidth="2" x="68" y="247" width="100" height="43" />
    );
  }
  return (
    <rect stroke="#FFFFFF" strokeWidth="2" x="454" y="247" width="100" height="43" />
  );
};

const getText = (x, y, text, color, size) => (
  <text
    fontFamily="Arial, Arial"
    fontSize={size || '26px'}
    fill={color || palette.white}
  >
    <tspan x={x} y={y}>
      {text}
    </tspan>
  </text>
);

const getHdgX = direction => {
  switch (direction) {
    case 180:
      return 104.5;
    case -180:
      return 473.5;
    case -360:
      return 104.5;
    default:
      return 473.5;
  }
};

const getHdgY = direction => {
  switch (direction) {
    case 180:
      return 483;
    case -180:
      return 483;
    case -360:
      return 277.5;
    default:
      return 277.5;
  }
};

const getCasX = diff => {
  let x = 0;
  if (diff.substr(0, 1) === '+') {
    if (diff.length === 2) {
      x = 105;
    } else if (diff.length === 3) {
      x = 99.5;
    } else {
      x = 94.5;
    }
  } else if (diff.substr(0, 1) === '-') {
    if (diff.length === 2) {
      x = 107.5;
    } else if (diff.length === 3) {
      x = 101.5;
    } else {
      x = 96;
    }
  } else {
    x = 111;
  }
  return x;
};

const getAltX = diff => {
  let x = 0;
  if (diff.substr(0, 1) === '+') {
    if (diff.length === 2) {
      x = 478.5;
    } else if (diff.length === 3) {
      x = 473.5;
    } else {
      x = 466.5;
    }
  } else if (diff.substr(0, 1) === '-') {
    if (diff.length === 2) {
      x = 480.5;
    } else if (diff.length === 3) {
      x = 474.5;
    } else {
      x = 468.5;
    }
  } else {
    x = 484.5;
  }
  return x;
};

const SteepTurnSVG = data => {
  const {
    direction,
    initialCas,
    maxCas,
    maxCasGrade,
    minCas,
    minCasGrade,
    initialAlt,
    maxAlt,
    maxAltGrade,
    minAlt,
    minAltGrade,
    averageRoll,
    maxRoll,
    maxRollGrade,
    minRoll,
    minRollGrade,
    initialHdg,
    finalHdg,
    finalHdgGrade,
  } = data;
  return (
    <SteepTurnVisualisation stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      {getLineEnd(direction, colors.get(finalHdgGrade))}
      {getLineMiddle(direction)}
      {getLineStart(direction)}
      {getEllipse(direction)}
      {getText(288.5, 382, getRoll(averageRoll))}
      {getText(96, 125.5, Math.round(initialCas).toString())}
      {getText(
        getCasX(getDiff(initialCas, maxCas)),
        55,
        getDiff(initialCas, maxCas),
        colors.get(maxCasGrade),
        '22px',
      )}
      {getText(
        getCasX(getDiff(initialCas, minCas)),
        192,
        getDiff(initialCas, minCas),
        colors.get(minCasGrade),
        '22px',
      )}
      {getText(451.5, 125.5, Math.round(initialAlt).toLocaleString())}
      {getText(
        getAltX(getDiff(initialAlt, maxAlt)),
        55,
        getDiff(initialAlt, maxAlt),
        colors.get(maxAltGrade),
        '22px',
      )}
      {getText(
        getAltX(getDiff(initialAlt, minAlt)),
        192,
        getDiff(initialAlt, minAlt),
        colors.get(minAltGrade),
        '22px',
      )}
      {getText(205, 380, Math.round(minRoll), colors.get(minRollGrade), '22px')}
      {getText(391, 380, Math.round(maxRoll), colors.get(maxRollGrade), '22px')}
      {getText(direction > 0 ? 96 : 482.5, 277.5, getHdg(initialHdg))}
      {getText(getHdgX(direction), getHdgY(direction), getHdg(finalHdg), colors.get(finalHdgGrade))}

      <text id="IAS" fontFamily="ArialMT, Arial" fontSize="26" fill="#FFFFFF">
        <tspan x="15.5" y="127">IAS</tspan>
      </text>
      <text id="ROLL" fontFamily="ArialMT, Arial" fontSize="26" fill="#FFFFFF">
        <tspan x="276.775" y="335">ROLL</tspan>
      </text>
      <text id="HDG" fontFamily="ArialMT, Arial" fontSize="26" fill="#FFFFFF">
        <tspan x={direction > 0 ? '0' : '564'} y="279">HDG</tspan>
      </text>
      <text id="ALT" fontFamily="ArialMT, Arial" fontSize="26" fill="#FFFFFF">
        <tspan x="564" y="127">ALT</tspan>
      </text>

      <path d="M108 95 L108 65 L128 65 L128 95 Z" fill={colors.get(maxCasGrade)} />
      <path d="M108 138 L108 168 L128 168 L128 138 Z" fill={colors.get(minCasGrade)} />
      <path d="M241 383 L271 383 L271 363 L241 363 Z" fill={colors.get(minRollGrade)} />
      <path d="M351 383 L381 383 L381 363 L351 363 Z" fill={colors.get(maxRollGrade)} />
      <path d="M481.5 138 L481.5 168 L501.5 168 L501.5 138 Z" fill={colors.get(minAltGrade)} />
      <path d="M481.5 95 L481.5 65 L501.5 65 L501.5 95 Z" fill={colors.get(maxAltGrade)} />

      <rect stroke="#FFFFFF" strokeWidth="2" x="68" y="95" width="100" height="43" />
      <rect stroke="#FFFFFF" strokeWidth="2" x="429" y="95" width="125" height="43" />
      {getHdgRect(direction)}
      <rect stroke="#FFFFFF" strokeWidth="2" x="271" y="348" width="80" height="50" rx="15" />
    </SteepTurnVisualisation>
  );
};

const SteepTurnVisualisation = styled.svg`
  height: 500px;
  width: 85%;
  margin-top: 100px;
`;

export default SteepTurnSVG;
