import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import Title from '../../../ui/head/Title';
import Header from '../../../ui/headers/Header';
import PageHeader from '../../../ui/PageHeader';
import Body from '../../../ui/Body';
import Loading from '../../../ui/Loading';
import { Button, Wrapper as ButtonWrapper } from '../../../ui/Button';
import { editSession } from '../../../utils/routes';
import ScrollToTop from '../../../utils/ScrollToTop';

import { ViewSessionScreenProps } from './index';
import { LessonPlanActivityType } from '../../constants';
import ContentContainer from '../../../ui/ContentContainer';

const ViewSessionScreen = ({
  match,
  lessonPlan,
  currentVariationId,
  onComponentMounted,
  onDone,
}: ViewSessionScreenProps) => {
  const title = 'View Session';

  React.useEffect(() => {
    const getSessionId = () => parseInt(match.params.sessionId, 10);

    onComponentMounted(getSessionId());
  }, [match, onComponentMounted]);

  if (!lessonPlan || Object.entries(lessonPlan).length < 1) {
    return <Loading />;
  }

  let { name, activities } = lessonPlan;

  const currentVariation = lessonPlan.variations.find(({ id }) => id === currentVariationId);
  if (currentVariationId && currentVariation) {
    name = currentVariation.name;
    activities = currentVariation.activities;
  }

  return (
    <>
      <ScrollToTop />
      <Title title={title} />
      <Header back />
      <PageHeader title={title} />
      <ContentContainer>
        <Body>
          <Subheader>{name}</Subheader>

          {activities.map((activity: LessonPlanActivityType) => (
            <Activity key={activity.ordering}>{activity.name}</Activity>
          ))}
          <ButtonWrapper>
            <NavLink to={editSession(parseInt(match.params.sessionId, 10))}>
              <Button onClick={onDone} width="180px">EDIT</Button>
            </NavLink>
          </ButtonWrapper>
        </Body>
      </ContentContainer>
    </>
  );
};

export default ViewSessionScreen;

const Subheader = styled.div`
  color: ${props => props.theme.textColor.string()};
  text-align: center;
  position: sticky;
  top: 153px;
  background-color: black;
  padding-bottom: 10px;
`;

const Activity = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0px 20px;
  background-color: ${props => props.theme.textColor.darken(0.9).string()};
  color: ${props => props.theme.textColor.string()};
  margin: 10px 1vw;
`;
