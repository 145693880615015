import { RecordOf } from 'immutable';
import { GeneralStoreType } from '../store/constants';
import { RunIdType, SessionRunType } from '../session/constants';
import { ActivityType } from './types';

export const selectRunId = (state: GeneralStoreType) => state.getIn(['activities', 'currentRunId']);

export const selectActivityId = (state: GeneralStoreType) => state.getIn(['activities', 'currentActivityId']);

export const selectActivityById = (
  state: GeneralStoreType,
  runId: RunIdType,
  activityId: number,
): ActivityType | null => {
  const runs = state.getIn(['activities', 'map', runId.toString()]);

  if (!activityId || !runs) {
    return null;
  }

  return runs.find((run: RecordOf<SessionRunType>) => run.get('id') === activityId).toJS();
};
