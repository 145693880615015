import {
  all,
  call,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';
import moment from 'moment';

import api from '../api';
import * as actions from './actions';
import * as dashboardActions from '../dashboard/actions';
import { fetchSessionData } from '../session/sagas';
import { CourseType, LessonPlanActivityType } from './constants';
import { EquipmentType } from '../dashboard/constants';
import { ActivityOptionsParamsType } from '../activities/types';

/**
 * Retrieves course and lesson plan information from the API.
 */
type FetchCoursePlansType = Generator<any, CourseType[], any>;
export function* fetchCoursePlans(): FetchCoursePlansType {
  try {
    const { results } = yield call(api.lessonPlans.courses, { expand: 'lesson_plans.variations' });

    return results;
  } catch (error) {
    api.logError(error);

    return [];
  }
}

// TODO: this saga belongs in the "dashboard" feature.
export function* fetchEquipment(): Generator<any, EquipmentType[], any> {
  try {
    const { results } = yield call(api.equipment.list);

    return results;
  } catch (error) {
    api.logError(error);

    return [];
  }
}

/**
 * Creates a Session Run record.
 */
type CreateRunArgs = {
  payload: {
    pilotFlying: string,
    pilotMonitoring: string,
    fullSessionId: number,
    scheduledFor: string,
  },
};
function* createRun({ payload }: CreateRunArgs) {
  const {
    fullSessionId,
    pilotFlying,
    pilotMonitoring,
    scheduledFor,
  } = payload;

  try {
    return yield call(
      api.sessions.createRun,
      fullSessionId,
      pilotFlying,
      pilotMonitoring,
      scheduledFor,
    );
  } catch (error) {
    api.logError(error);

    return false;
  }
}

/**
 * Schedules a training session.
 */
function* scheduleSession({ payload }: actions.ScheduleSessionAction) {
  const {
    lessonPlanId,
    lessonPlanVariationId,
    pilotFlying,
    pilotMonitoring,
    equipmentId,
    scheduledDate,
    scheduledTime,
    shouldCreateRelatedRun,
    onComplete,
  } = payload;

  // Convert scheduled date and time into a timestamp.
  const scheduledFor = moment(`${scheduledDate} ${scheduledTime}`, 'YYYY-MM-DD HH:mm:ss');

  let fullSession;

  // Create FullSession record.
  try {
    fullSession = yield call(
      api.sessions.createFullSession,
      equipmentId,
      scheduledFor.toISOString(),
      lessonPlanId,
      lessonPlanVariationId,
    );
  } catch (error) {
    api.logError(error);

    yield put(actions.scheduleSessionCompleted({ error }));

    return;
  }

  // Create the first session run.
  const firstRun = yield createRun({
    payload: {
      pilotFlying,
      pilotMonitoring,
      fullSessionId: fullSession.id,
      scheduledFor: scheduledFor.toISOString(),
    },
  });

  if (!firstRun) {
    // TODO: rollback changes.
    yield put(actions.scheduleSessionCompleted({ error: 'Could not create session run.' }));

    return;
  }

  // Create the second session run.
  if (shouldCreateRelatedRun) {
    yield createRun({
      payload: {
        pilotFlying: pilotMonitoring,
        pilotMonitoring: pilotFlying,
        fullSessionId: fullSession.id,
        scheduledFor: scheduledFor.add(2, 'hours').toISOString(),
      },
    });
  }

  yield put(actions.scheduleSessionCompleted({ fullSession }));

  if (typeof onComplete === 'function') {
    yield call(onComplete);
  }
}

/**
 * Retrieves a list of activities for a course.
 */
export function* fetchActivityOptions(
  params: ActivityOptionsParamsType,
): Generator<any, LessonPlanActivityType[], any> {
  try {
    return yield call(api.lessonPlans.activities, params);
  } catch (error) {
    api.logError(error);

    return [];
  }
}

export function* createLessonPlanVariation(
  { payload }: actions.CreateLessonPlanVariationAction,
): Generator<any, void, any> {
  const {
    lessonPlanId,
    sessionId,
    name,
    activities,
    onComplete,
  } = payload;

  const lessonPlanVariation = yield call(
    api.lessonPlans.createVariation,
    {
      lessonPlan: lessonPlanId,
      fullSession: sessionId,
      name,
      activities,
    },
  );

  if (lessonPlanVariation) {
    yield put(actions.setLessonPlanVariation(lessonPlanVariation));
  } else {
    yield put(actions.setLessonPlanVariation({ error: true }));
  }

  if (typeof onComplete === 'function') {
    yield call(onComplete);
  }
}

/**
 * Watchers.
 */
export default {
  * watchScreenLoaded(): Generator<any, void, any> {
    while (true) {
      const action = yield take([
        actions.VIEW_SESSION_SCREEN_LOADED,
        actions.EDIT_SESSION_SCREEN_LOADED,
      ]);

      yield put(actions.setFlags());

      const [fullSession] = yield call(fetchSessionData, action);

      yield put(actions.setLessonPlan(fullSession));

      if (action.type === actions.EDIT_SESSION_SCREEN_LOADED && fullSession) {
        const equipmentId = fullSession.equipment && fullSession.equipment.equipmentId;
        const activityOptions = yield call(fetchActivityOptions, { equipmentId });

        if (activityOptions) {
          yield put(actions.setScheduleActivityOptions(activityOptions));
        }
      }

      yield put(actions.setFlags(false, true));
    }
  },
  * watchCreateScreenLoaded(): Generator<any, void, any> {
    while (true) {
      const action = yield take([
        actions.CREATE_SESSION_SCREEN_LOADED,
      ]);

      // Set the loading flag on the schedule store.
      yield put(actions.setFlags());

      // Retrieve course plans.
      const [plans, equipment] = yield all([
        call(fetchCoursePlans, action),
        call(fetchEquipment, action),
      ]);

      // Store course plans and unset loading flag.
      yield all([
        put(actions.setCoursePlans(plans)),
        put(dashboardActions.equipmentDetailsUpdated(equipment)),
        put(actions.setFlags(false, true)),
      ]);
    }
  },
  * watchCreateSession(): Generator<any, void, void> {
    yield takeLatest(actions.SCHEDULE_SESSION, scheduleSession);
  },
  * watchCreateLessonPlanVariation(): Generator<any, void, void> {
    yield takeLatest(actions.CREATE_LESSON_PLAN_VARIATION, createLessonPlanVariation);
  },
};
