import React from 'react';
import styled from 'styled-components';

import { CompetencyRadarPlotProps } from './types';
import { competencyShortNameMap, competencyShortNameMapTable } from '../../constants';
import { CompetencyCode } from '../../types';
import SpiderGraph from '../../../ui/SpiderGraph';
import Loading from '../../../ui/Loading';
import { darkGrey, lightestGrey, lightGrey } from '../../../theme/palette';
import CaratUp from '../../../ui/svg/carat-up.svg';
import CaratDown from '../../../ui/svg/carat-down.svg';
import GradeInput from '../../../summary/components/GradeInput';
import { GradeLevelType } from '../../../summary/constants';
import CompetencyDrilldown from './CompetencyDrilldown';

const CompetencyRadarPlot = ({
  onComponentMounted,
  onUpdateCompetencyScore,
  onShowCompetencyDrilldown,
  scores,
  instructorScores,
  activities,
  competencyDrilldown,
  isLoading,
  isLoadingCompetency,
  onShowGradeContext,
  edit,
}: CompetencyRadarPlotProps) => {
  const [editScores, setEditScores] = React.useState<boolean>(false);
  const [drilldown, setDrilldown] = React.useState<string | null>(null);

  // Make sure competency data is available.
  React.useEffect(() => { onComponentMounted(); }, [onComponentMounted]);

  if (!scores) {
    return <Loading />;
  }

  // Convert competency object to legacy format.
  const competencyValues: Array<{ grade: GradeLevelType | null, name: string, key: string }> = [];
  const competencyValuesTable: Array<{ grade: GradeLevelType | null, name: string, key: string }> = [];

  if (scores) {
    competencyShortNameMap.forEach((name: string, key: CompetencyCode) => {
      competencyValues.push({
        name,
        grade: key in scores ? scores[key].score : null,
        key,
      });
    });
    competencyShortNameMapTable.forEach((name: string, key: CompetencyCode) => {
      competencyValuesTable.push({
        name,
        grade: key in scores ? scores[key].score : null,
        key,
      });
    });
  }

  return (
    <>
      <Wrapper>
        {scores
          && <SpiderGraph isLoading={isLoading} competencies={competencyValues} instructorScores={instructorScores} />}
        {edit
          && (
            <ScoresWrapper>
              <EditScores onClick={() => setEditScores(!editScores)} open={editScores}>
                {isLoading ? 'Calculating Competency Scores...' : 'Edit Competency Scores'}
                <Carat open={editScores} hidden={isLoading} />
              </EditScores>

              <ScoresBody>
                {!isLoading && editScores && competencyValuesTable.map(score => {
                  // @ts-ignore
                  const instructorScore = instructorScores[`${score.key}Grade`] || null;
                  const handleUpdateCompetencyScore = (level: GradeLevelType) => onUpdateCompetencyScore(
                    `${score.key}Grade`,
                    instructorScores,
                    level,
                  );

                  const handleShowCompetencyDrilldown = () => {
                    onShowCompetencyDrilldown(score.key);
                    setDrilldown(score.name);
                  };

                  return (
                    <Score key={score.key}>
                      {score.name}
                      <GradeInput
                        key={`competency-${score.key}`}
                        gradeValue={instructorScore !== null ? instructorScore : score.grade}
                        type="competency"
                        onUpdateGradeLevel={handleUpdateCompetencyScore}
                        onShowCompetencyDrilldown={handleShowCompetencyDrilldown}
                        isPredicted={instructorScore === null}
                      />
                    </Score>
                  );
                })}
              </ScoresBody>
            </ScoresWrapper>
          )}
      </Wrapper>

      {!isLoadingCompetency && drilldown && (
        <CompetencyDrilldown
          name={drilldown}
          norms={competencyDrilldown}
          activities={activities}
          onShowGradeContext={onShowGradeContext}
          onCloseDrilldown={() => setDrilldown(null)}
        />
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScoresWrapper = styled.div`
  padding: 20px 0px;
  background-color: ${darkGrey.string()};
  border-radius: 5px;
  width: 70%;
  margin: auto;
`;

const EditScores = styled.div<{ open: boolean }>`
  font-size: 16px;
  color: ${lightestGrey.string()};
  line-height: 19px;
  display: flex;
  justify-content: space-between;
  padding: 0 42px 0 25px;
  font-weight: 400;
`;

export const Carat = styled.div<{ open: boolean, drilldown?: boolean }>`
  background: center center transparent no-repeat url('${props => (props.open ? CaratUp : CaratDown)}');
  background-size: contain;
  height: 19px;
  width: 19px;
  margin-top: ${props => (props.drilldown ? '6px' : '')}
`;

const ScoresBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const Score = styled.div`
  border-top: 1px solid ${lightGrey.string()};
  margin-top: 10px;
  padding: 10px 25px 0 25px;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  line-height: 43px;
`;

export default CompetencyRadarPlot;
