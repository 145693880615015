import localStore from '../utils/localStore';
import { GeneralStoreType } from '../store/constants';

/**
 * Retrieves trainees from the local database. We supress errors since they'll
 * get logged in the localStore util anyways.
 */
export const getTrainees = async (filter?: string): Promise<string[]> => {
  const trainees = await localStore.getRecords<string[]>('trainees');

  // Suppress errors if any.
  if (trainees instanceof Error) {
    return Promise.resolve([]);
  }

  // Filter trainees.
  if (filter) {
    // TODO
  }

  return Promise.resolve(trainees);
};

/**
 * Adds a trainee to the local database.
 */
export const addTrainee = async (name: string): Promise<boolean | Error> => {
  const result = await localStore.addRecords('trainees', [name]);

  if (result instanceof Error) {
    return Promise.reject(result);
  }

  return Promise.resolve(true);
};

export const getScheduleProps = (state: GeneralStoreType) => ({
  isLoading: state.getIn(['schedule', 'isLoading']),
  isReady: state.getIn(['schedule', 'isReady']),
  courseList: state.getIn(['schedule', 'courseList']),
  lessonPlan: state.getIn(['schedule', 'lessonPlan']),
  activityOptions: state.getIn(['schedule', 'activityOptions']),
  currentVariationId: state.getIn(['schedule', 'currentVariationId']),
});
