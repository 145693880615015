import * as React from 'react';

import GradeInput from './GradeInput';
import { getDisplayGrade } from '../utils';
import { GRADEABLE_ACTIVITY,
  GradeLevelType,
  GradeLevelUpdateHandlerType,
  GradePairType,
  GradeableIdType,
  GradingContextType } from '../constants';
import { NoteType } from '../../activities/types';

export type GradeInputContextSelectorType = (id: GradeableIdType) => void;

type Props = {
  gradingContext: GradingContextType,
  onShowGradeContext: GradeInputContextSelectorType,
  onUpdateGradeLevel: GradeLevelUpdateHandlerType,
  onSelectDemo: Function,
  onSaveNote: Function,
};

const GradeInputList = ({
  gradingContext,
  onShowGradeContext,
  onUpdateGradeLevel,
  onSelectDemo,
  onSaveNote,
}: Props) => {
  const inputs: React.ReactNode[] = [];

  gradingContext.grades.forEach((gradePair: GradePairType) => {
    const grade = getDisplayGrade(gradePair);
    const activity = gradingContext.activities.get(gradePair.gradeableId);
    const isDemo = activity && activity.isDemo;
    const handleShowGradeContext = gradePair.type === GRADEABLE_ACTIVITY
      ? () => onShowGradeContext(gradePair.gradeableId)
      : undefined;
    const handleUpdateGradeLevel = (
      level: GradeLevelType,
    ) => onUpdateGradeLevel(grade, level, gradePair.type);
    const handleSaveNote = activity
      ? (text: string, note: NoteType) => {
        if (typeof onSaveNote === 'function') {
          onSaveNote(activity.id, text, note);
        }
      }
      : undefined;

    inputs.push(
      <GradeInput
        key={`grade-${grade.id || grade.tempId || gradePair.gradeableId || ''}`}
        gradeValue={grade.gradeValue}
        isPredicted={grade.isAutomatic}
        activityId={gradePair.gradeableId}
        type={gradePair.type}
        isDemo={isDemo}
        onShowGradeContext={handleShowGradeContext}
        onUpdateGradeLevel={handleUpdateGradeLevel}
        onSelectDemo={onSelectDemo}
        onSaveNote={handleSaveNote}
        note={activity && activity.note}
      />,
    );
  });

  return inputs;
};

export default GradeInputList;
