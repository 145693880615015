import { TrainingSessionEvent, SimulatorStatusResponse } from './types';
import { SessionEventType } from '../session/constants';

export const TRAINING_SCREEN_LOADED = 'Live training screen loaded.';
export const TRAINING_SESSION_STARTED = 'Live training session started (listening for live data).';
export const TRAINING_SESSION_EVENT = 'Received training session event.';
export const TRAINING_SESSION_SIMULATOR_STATUS_EVENT = 'Received training session simulator status event.';
export const TRAINING_SESSION_ENDED = 'Live training session ended.';
export const RECORD_EVENT = 'Recording event.';
export const END_RUN = 'Ending session run.';

export const trainingScreenLoaded = (sessionId: number, runId: number) => ({
  type: TRAINING_SCREEN_LOADED,
  payload: { sessionId, runId },
} as const);

export const trainingSessionActive = (isActive: boolean, runId?: number) => ({
  type: isActive ? TRAINING_SESSION_STARTED : TRAINING_SESSION_ENDED,
  payload: { isActive, runId },
} as const);

export const trainingSessionEvent = (payload: TrainingSessionEvent) => ({
  type: TRAINING_SESSION_EVENT,
  payload,
} as const);

export const trainingSessionSimulatorStatusEvent = (payload: SimulatorStatusResponse) => ({
  type: TRAINING_SESSION_SIMULATOR_STATUS_EVENT,
  payload,
} as const);

export const recordEvent = (event: Partial<SessionEventType>) => ({
  type: RECORD_EVENT,
  payload: { ...event },
} as const);

export const endRun = (id: number, callback?: () => void) => ({
  type: END_RUN,
  payload: { id, callback },
} as const);

export type TrainingScreenLoadedAction = ReturnType<typeof trainingScreenLoaded>;
export type TrainingSessionActiveAction = ReturnType<typeof trainingSessionActive>;
export type TrainingSessionEventAction = ReturnType<typeof trainingSessionEvent>;
export type TrainingSessionSimulatorStatusUpdateAction = ReturnType<typeof trainingSessionSimulatorStatusEvent>;
export type RecordEventAction = ReturnType<typeof recordEvent>;
export type EndRunAction = ReturnType<typeof endRun>;

export type LiveSessionAction =
  | TrainingScreenLoadedAction
  | TrainingSessionActiveAction
  | TrainingSessionEventAction
  | TrainingSessionSimulatorStatusUpdateAction
  | RecordEventAction
  | EndRunAction;
