export const SET_USER_INFO = 'Setting the user information';
export const GET_USER_INFO = 'Recieving the user information';

export const setUserInfo = (username: string, id: number) => ({
  type: SET_USER_INFO,
  payload: {
    username,
    id,
  },
} as const);

export const getUserInfo = () => ({
  type: GET_USER_INFO,
} as const);

export type SetUserInfoAction = ReturnType<typeof setUserInfo>;
export type GetUserInfoAction = ReturnType<typeof getUserInfo>;

export type UserAction =
  | SetUserInfoAction
  | GetUserInfoAction;
