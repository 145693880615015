import React from 'react';
import { Switch as MaterialSwitch, SwitchProps } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Row } from '../Form';
import { textColor } from '../../theme/palette';

const baseColor = textColor.string();

// TODO: style the disabled state.
export const ToggleSwitch = withStyles({
  switchBase: {
    color: baseColor,
  },
  track: {
    backgroundColor: baseColor,
  },
})(MaterialSwitch);

interface ToggleSwitchRowProps extends SwitchProps {
  flexGrow?: number;
  labelLeft: string;
  name: string;
  labelRight?: string;
}

export const ToggleSwitchRow = (props: ToggleSwitchRowProps) => {
  const { flexGrow, labelLeft, labelRight, ...inputProps } = props;

  return (
    <Row data-toggle-switch={props.name} flexGrow={flexGrow}>
      {labelRight}
      <ToggleSwitch {...inputProps} color="primary" />
      {labelLeft}
    </Row>
  );
};

ToggleSwitchRow.defaultProps = {
  flexGrow: null,
  labelRight: null,
};
