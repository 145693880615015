import getAirPlotOptions from './air';
import {
  TakeoffInsightType,
  VisualizationHandlerType,
} from '../../visualization/constants';
import {
  normLateralAcceleration,
} from '../annotations/norms';

// @ts-ignore
const getTakeoffLateralDirectionalPlotOptions: VisualizationHandlerType<TakeoffInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const { insight, frames } = data;
  let plotOptions;
  if (metaData.aircraftDesignator === 'A320') {
    plotOptions = getAirPlotOptions(frames, markers, activity, metaData,
      ['accLatBody', 'roll', ['fcInceptorLPosRoll', 'fcInceptorRPosRoll'], 'rudPedPosPilot', 'altMsl']);
  } else {
    plotOptions = getAirPlotOptions(frames, markers, activity, metaData,
      ['accLatBody', 'roll', 'whlPosPilot', 'rudPedPosPilot', 'altMsl']);
  }

  if (metaData.trainingCenter !== 'NLA') {
    let LateralAcceleration;
    // eslint-disable-next-line no-unused-expressions,@typescript-eslint/no-unused-expressions
    if (insight && insight.summary) {
      insight.summary.payload.forEach((e, i) => {
        if (e && e.normCode === 'SEG2CLIMB_LATACC') {
          LateralAcceleration = insight.summary?.payload[i].envelope;
        }
      });

      if (LateralAcceleration) {
        normLateralAcceleration(frames, plotOptions, LateralAcceleration, 'accLatBody');
      }
    }
  }
  return plotOptions;
};

export default getTakeoffLateralDirectionalPlotOptions;
