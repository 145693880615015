/**
 * Wrapper for the contents of a screen.
 */
import styled from 'styled-components';

const ContentContainer = styled.div`
    @media only screen and (min-width: 1200px) and (orientation: landscape) {
        margin-left: auto;
        margin-right: auto;
        max-width: 1200px;
        width: 100%;
    }
 `;

export default ContentContainer;
