import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { mediumLightGrey } from '../theme/palette';

const PaladinTheme = createGlobalStyle`
  .tippy-tooltip.paladin-theme {
    background-color: ${mediumLightGrey.string()};
    padding: 20px;
    box-shadow: 0px 5px 15px 0px black;
  }

  .tippy-tooltip.paladin-theme[data-animatefill] {
    background-color: transparent;
  }

  .tippy-tooltip.paladin-theme .tippy-backdrop {
    background-color: ${mediumLightGrey.string()};
  }

  .tippy-popper[x-placement^='top'] .tippy-tooltip.paladin-theme .tippy-arrow {
    border-top-color: ${mediumLightGrey.string()};
  }
  .tippy-popper[x-placement^='bottom'] .tippy-tooltip.paladin-theme .tippy-arrow {
    border-bottom-color: ${mediumLightGrey.string()};
  }
  .tippy-popper[x-placement^='left'] .tippy-tooltip.paladin-theme .tippy-arrow {
    border-left-color: ${mediumLightGrey.string()};
  }
  .tippy-popper[x-placement^='right'] .tippy-tooltip.paladin-theme .tippy-arrow {
    border-right-color: ${mediumLightGrey.string()};
  }
  .tippy-tooltip.paladin-theme .tippy-roundarrow {
    fill: ${mediumLightGrey.string()};
  }
`;

export const TippyThemes = () => (
  <>
    <PaladinTheme />
  </>
);
