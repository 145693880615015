import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import GradingScreen from './GradingScreen';
import { DispatchMapProps } from './types';
import * as actions from '../../actions';
import * as activityActions from '../../../activities/actions';
import * as sessionActions from '../../../session/actions';
import * as recommendationActions from '../../../recommendations/actions';
import { GeneralStoreType } from '../../../store/constants';
import { NoteType } from '../../../activities/types';

const mapStateToProps = (state: GeneralStoreType) => {
  const summary = state.get('summary');
  const session = state.get('session');
  const activities = state.get('activities');
  const sessionList = state.get('sessionList');

  const {
    gradingSheets,
    activityGrades,
    ksaComponentGrades,
    markers,
  } = summary;
  const { currentSession, runs } = session;
  const { map } = activities;
  const { sessions, isLoading } = sessionList;

  return {
    runs,
    activities: map,
    gradingSheets,
    activityGrades,
    ksaComponentGrades,
    markers,
    fullSession: currentSession,
    sessions,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch: Function): DispatchMapProps => ({
  onComponentMounted: (sessionId: number) => dispatch(actions.gradingScreenLoaded(sessionId)),
  onSelectSessionRun: (id: number) => dispatch(actions.loadingCompetencyGraph(id)),
  onUpdateGradeLevel: (
    grade,
    level,
    runId,
    type,
  ) => dispatch(actions.updateInstructorGrade(grade, level, runId, type)),
  onSelectDemo: (activityId: number) => dispatch(activityActions.setActivityDemo(activityId)),
  onDone: () => {
    dispatch(sessionActions.updateRuns([]));
    dispatch(activityActions.clearActivities());
  },
  onUpdateRunId: (runId: number) => dispatch(activityActions.updateRunId(runId)),
  onClearTraineeProfile: () => dispatch(actions.clearTraineeProfile()),
  onSaveNote: (
    activityId: number,
    text: string,
    note: NoteType | null,
  ) => dispatch(activityActions.saveInstructorNote(activityId, text, note)),
  onSubmitInstructorFeedback: (type: string, id: number, instructorFeedback: boolean) => {
    dispatch(recommendationActions.submitInstructorFeedback(type, id, instructorFeedback));
  },
});

const GradingScreenContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(withImmutablePropsToJS(GradingScreen));

export default GradingScreenContainer;
