import { END } from 'redux-saga';

import { LiveSessionSimulatorStatusSubscriber, LiveSessionSubscriber } from '../types';
import api from '../../api';
import config from '../../config';

export const getPollingSubscriber: LiveSessionSubscriber = runId => sendUpdate => {
  const intervalId = setInterval(async () => {
    try {
      const { data } = await api.sessions.poll(runId);

      sendUpdate(data);
    } catch (error) {
      // Close the polling channel if we get a "session ended" message.
      if (error.response && error.response.status === 409) {
        sendUpdate(END);
      } else {
        api.logError(error);
      }
    }
  }, config.pingRate);

  return () => clearInterval(intervalId);
};

export const getPollingSimulatorStatusSubscriber: LiveSessionSimulatorStatusSubscriber = runId => updateSimulatorStatus => {
  const intervalId = setInterval(async () => {
    try {
      const { data } = await api.sessions.pollSimulatorStatus(runId);

      updateSimulatorStatus(data);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        updateSimulatorStatus(END);
      } else {
        api.logError(error);
      }
    }
  }, config.simulatorStatusPingRate);

  return () => clearInterval(intervalId);
};
