import React from 'react';
import styled from 'styled-components';

import { textColor } from '../../../theme/palette';

const MalfunctionTag = ({ name }: { name: string }) => (
  <MalfunctionTagWrapper>
    <MalfunctionSpan>{name}</MalfunctionSpan>

    <MalfunctionSpan active>Active</MalfunctionSpan>
  </MalfunctionTagWrapper>
);

export default MalfunctionTag;

const MalfunctionTagWrapper = styled.div`
  color: ${textColor.string()};
  line-height: 20px;
  margin: 19px 20px 20px 20px;
  display: flex;
  justify-content: space-between;
`;

const MalfunctionSpan = styled.div<{ active?: boolean }>`
  display: inline-block;
  color: ${props => (props.active ? props.theme.accent.string() : '')}
`;
