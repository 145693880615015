import moment from 'moment';
import { END, eventChannel } from 'redux-saga';
import { all, call, fork, put, select, take, takeEvery } from 'redux-saga/effects';

import * as actions from './actions';
import { selectEndedAt } from './selectors';
import api from '../api';
import config from '../config';
import { GRADE_LEVEL_UPDATED, GradeLevelUpdatedAction } from '../summary/actions';
import { GradeLevelType, GradeType } from '../summary/constants';
import reporter from '../utils/reporter';

/**
 * Retrieves competency scores from the API.
 */
function* getCompetencyScores(runId: number) {
  try {
    const { data: { scores, instructorScores }, status } = yield call(api.sessions.getCompetencyScores, runId);

    return { scores, instructorScores, status };
  } catch (error) {
    let status = null;

    if (error.response && error.response.status) {
      status = error.response.status;

      if (error.response.status !== 501) {
        // Log API error.
        yield call(api.logError, error);
      }
    } else {
      // Log JS error.
      yield call(api.logError, error);
    }

    return { scores: null, status };
  }
}

function* updateCompetencyScore(runId: number, competency: string, score: GradeType, level: GradeLevelType) {
  if (!score || !level) {
    const error = new Error('Invalid grade object or level in updateCompetencyScore() saga.');

    yield call(api.logError, error);

    return error;
  }

  const apiRequest = score.id
    ? call(api.grades.updateCompetency, score.id, competency, level)
    : call(api.grades.createCompetency, 'run', runId, competency, level);

  try {
    const { data } = yield apiRequest;

    return data;
  } catch (error) {
    yield call(api.logError, error);

    return error;
  }
}

/**
 * Creates an event channel for competency inference polling
 */
const createCompetencyInferenceChannel = (
  runId: number,
  expiry: moment.Moment,
) => eventChannel(sendUpdate => {
  // Create interval for polling the competency scores endpoint.
  const intervalId = setInterval(async () => {
    if (moment().isAfter(expiry)) {
      reporter.warn('Competency inference polling timed-out.');
      sendUpdate(END);
    }

    try {
      const { data: { scores }, status } = await api.sessions.getCompetencyScores(runId);

      // Send results into the channel.
      sendUpdate({ scores, status });
    } catch (error) {
      api.logError(error);
      sendUpdate(END);
    }
  }, config.pingRate);

  return () => clearInterval(intervalId);
});

/**
 * Polls the API for competency inference results.
 */
function* competencyInferenceListener(runId: number, expiry: moment.Moment) {
  const channel = yield call(createCompetencyInferenceChannel, runId, expiry);

  try {
    while (true) {
      const { scores, status } = yield take(channel);

      if (status === 200) {
        yield put(actions.scoresUpdated(runId, scores.scores));
        channel.close();
      }
    }
  } finally {
    channel.close();
    yield put(actions.loadingCompetencyScores(false));
  }
}

function* getCompetencyDrilldownData(runId: number, competency: string) {
  if (!runId || !competency) {
    const error = new Error('Invalid run ID or competency code in getCompetencyDrilldownData() saga.');

    yield call(api.logError, error);

    return error;
  }

  try {
    const { data } = yield call(api.analytics.getCompetencyDrilldown, runId, competency);

    return data;
  } catch (error) {
    yield call(api.logError, error);

    return error;
  }
}

/**
 * Handles the RADAR_PLOT_MOUNTED action, updating the radar plot as needed
 * and polling for new data if competency inference is in progress.
 */
function* handleRadarPlotMounted({ payload: { runId } }: actions.RadarPlotMountedAction) {
  const { scores, instructorScores, status } = yield call(getCompetencyScores, runId);

  if (scores) {
    yield all([
      put(actions.scoresUpdated(runId, scores)),
      put(actions.competencyScoresLoaded(runId, instructorScores)),
    ]);
  }

  if (status === 202) {
    const endedAt = yield select(selectEndedAt, runId);
    const expiry = moment.utc(endedAt).add(config.competencyInferenceTimeout, 'milliseconds');

    yield all([
      put(actions.loadingCompetencyScores()),
      fork(competencyInferenceListener, runId, expiry),
    ]);
  }
}

function* handleGradeUpdated({ payload: { runId, spiderGraphCompetency } }: GradeLevelUpdatedAction) {
  yield put(actions.scoresUpdated(runId, spiderGraphCompetency));
}

function* handleCompetencyScoreUpdated({
  payload: { runId, competency, score, level },
}: actions.UpdateCompetencyScoreAction) {
  const { grade } = yield call(updateCompetencyScore, runId, competency, score, level);

  if (grade) {
    yield put(actions.competencyScoresUpdated(runId, grade));
  }
}

function* handleGetCompetencyDrilldown({ payload: { runId, competency } }: actions.LoadingCompetencyDrilldownAction) {
  const data = yield call(getCompetencyDrilldownData, runId, competency);

  yield put(actions.competencyDrilldownLoaded(data !== null ? data.payload : null));
}

export default [
  function* () {
    yield takeEvery(actions.RADAR_PLOT_MOUNTED, handleRadarPlotMounted);
  },
  function* () {
    yield takeEvery(GRADE_LEVEL_UPDATED, handleGradeUpdated);
  },
  function* () {
    yield takeEvery(actions.UPDATE_COMPETENCY_SCORE, handleCompetencyScoreUpdated);
  },
  function* () {
    yield takeEvery(actions.LOADING_COMPETENCY_DRILLDOWN, handleGetCompetencyDrilldown);
  },
];
