import * as Plotly from 'plotly.js';
import getConditionTraces from './plotOptions/activeMalfunctions';
import getFlapsPlot from './plotOptions/flaps';
import {
  isApproachActivity,
  isGoAroundActivity,
  isTakeoffActivity,
} from '../visualization/activityCategory';

import { FrameType } from '../../frames/constants';
import { ConditionType } from '../simData';
import { getScale } from './scalePlots';

export type DataPointsType = {
  traces: Plotly.Data[];
  conditions: ConditionType[];
  flaps: { [key: string]: any };
};

export const pickColor = (index: number): string => {
  const colors = ['#1a93d5', '#f1c562', '#8ab674', '#7a8c84'];

  // Use the provided index to pick a colour from our colour array. We use the
  // modulo to make sure the index is never greater than the size of our
  // colour array.
  const colorIndex = index % colors.length;

  return colors[colorIndex];
};

/**
 * Converts a data stream into a format plotly can understand.
 */
const getDataPoints = (
  params: Array<string | Array<string>>,
  frames: FrameType[],
  activityCode: string,
  trainingCenter: string,
): DataPointsType => {
  const dataPoints: DataPointsType = {
    traces: [],
    conditions: [],
    flaps: {},
  };
  const simParamPlotIndexMap = new Map();
  let numPlots = 0;

  const conditions = getConditionTraces(frames);

  dataPoints.traces.push(...conditions.conditionTraces);
  dataPoints.conditions.push(...conditions.activeConditions);

  numPlots += dataPoints.conditions.length;

  let num = dataPoints.conditions.length;
  dataPoints.conditions.forEach(condition => {
    simParamPlotIndexMap.set(condition.code, dataPoints.traces.length - num);
    num--;
  });

  // Fill the dataPoints object with traces for each parameter.
  params.forEach((plotName, index) => {
    getScale(plotName, dataPoints, frames, numPlots);
    if (Array.isArray(plotName)) {
      plotName.forEach((plot, i) => {
        dataPoints.traces.push({
          line: {
            color: pickColor(index),
            dash: i === 1 ? 'dot' : undefined,
            width: 4,
          },
          mode: 'lines',
          name: plot,
          type: 'scatter',
          xaxis: `x${numPlots + 2}`,
          yaxis: `y${numPlots + 2}`,
          y: [],
          showlegend: false,
        });
        simParamPlotIndexMap.set(plot, dataPoints.traces.length - 1);
      });
    } else {
      dataPoints.traces.push({
        line: {
          color: pickColor(index),
          width: 4,
        },
        mode: 'lines',
        name: plotName,
        type: 'scatter',
        xaxis: `x${numPlots + 2}`,
        yaxis: `y${numPlots + 2}`,
        y: [],
        showlegend: false,
      });

      simParamPlotIndexMap.set(plotName, dataPoints.traces.length - 1);
    }

    numPlots++;
  });

  if (
    trainingCenter !== 'NLA'
    && activityCode !== 'TAKEOFF_SLOPE'
    && params[0] !== 'hdgTrue'
    && params[0] !== 'accLatBody'
  ) {
    if (
      isTakeoffActivity(activityCode)
      || isApproachActivity(activityCode)
      || isGoAroundActivity(activityCode)
    ) {
      const flap = getFlapsPlot(frames, numPlots);
      dataPoints.traces.push(flap);
      dataPoints.flaps = flap;

      simParamPlotIndexMap.set(flap.name, dataPoints.traces.length - 1);

      numPlots++;
    }
  }

  if (!frames || frames.length < 2) {
    return dataPoints;
  }

  // Determine frequency of data.
  const timeDelta = (Number(new Date(frames[1].timestamp)) - Number(new Date(frames[0].timestamp))) / 1000;

  // The x-axis will be the same for all sub-plots, so we only need to generate it once.
  const xAxis: number[] = [];
  let xAxisCounter = 0;

  frames.forEach((frame, frameIndex) => {
    // Build the array for the x-axis.
    xAxis.push((xAxisCounter += timeDelta));

    // Build the arrays for the y-axis of each malfunction sub-plot.
    dataPoints.conditions.forEach(({ startedAtIndex, endedAtIndex }, index) => {
      if (frameIndex >= startedAtIndex && frameIndex < endedAtIndex) {
        // Draw along the '0' line.
        // @ts-ignore
        dataPoints.traces[index].y.push(0);
      } else {
        // Don't draw anything at all.
        // @ts-ignore
        dataPoints.traces[index].y.push(null);
      }
    });

    // Build the arrays for the y-axis of each sim param sub-plot.
    params.forEach(param => {
      if (Array.isArray(param)) {
        param.forEach(instance => {
          // @ts-ignore

          dataPoints.traces[simParamPlotIndexMap.get(instance)].y.push(
            frame.data[instance],
          );
        });
      } else {
        // @ts-ignore
        dataPoints.traces[simParamPlotIndexMap.get(param)].y.push(
          frame.data[param],
        );
      }
    });

    if ('name' in dataPoints.flaps) {
      const { name } = dataPoints.flaps;
      // @ts-ignore
      dataPoints.traces[simParamPlotIndexMap.get(name)].y.push(
        frame.data[name],
      );
    }
  });

  // Add x-axis values to each trace.
  dataPoints.traces.forEach((trace, index) => {
    dataPoints.traces[index].x = xAxis;
  });
  // for HDG plot, normalize the data to 0-360
  if (dataPoints.traces[simParamPlotIndexMap.get('hdgTrue')]) {
    dataPoints.traces[simParamPlotIndexMap.get('hdgTrue')].y = (
      dataPoints.traces[simParamPlotIndexMap.get('hdgTrue')].y as any[]).map(
      (value: any) => (value < 0 ? value + 360 : value),
    );
  }
  return dataPoints;
};

export default getDataPoints;
