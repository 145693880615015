import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import ViewScreen from './ViewSessionScreen';
import * as actions from '../../actions';
import { getScheduleProps } from '../../utils';
import { ScheduleStore } from '../../store';
import { GeneralStoreType } from '../../../store/constants';

// Props provided by this container
export type ViewSessionScreenProps = RouteComponentProps<{sessionId: string}> & ScheduleStore & {
  onComponentMounted: (id: number) => void,
  onDone: () => void,
};

const mapStateToProps = (state: GeneralStoreType): ScheduleStore => getScheduleProps(state);

const mapDispatchToProps = (dispatch: Function) => ({
  onComponentMounted: (sessionId: number) => dispatch(actions.viewSessionScreenLoaded(sessionId)),
  // @ts-ignore
  onDone: () => dispatch(actions.setLessonPlan({})),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(withImmutablePropsToJS(ViewScreen));
