import { Record, RecordOf } from 'immutable';

export interface UserInfoStore {
  username: string | null;
  id: number | null;

}

export type ImmutableUserInfoStore = RecordOf<UserInfoStore>;

const createUserInfoStore = Record<UserInfoStore>({
  username: null,
  id: null,
});

const defaultState: ImmutableUserInfoStore = createUserInfoStore();

export default defaultState;
