import * as React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import PreviewRibbon from '../PreviewRibbon';
import LogoSVG from '../svg/InstructIQLogo.svg';
import BackArrow from '../svg/BackArrow.svg';
import config from '../../config';

import menuIcon from '../../assets/icons/menu.png';
import NavBar from '../NavBar';
import { lightGrey } from '../../theme/palette';
import { SimulatorStatusUtil } from '../../utils/common/simulatorStatusHandler';
import { SimulatorStatus } from '../../training/types';

export const reloadHandler = () => window.location.reload(true);

type Props = {
  handleLogout?: Function,
  showEndSession?: boolean,
  onEndSessionRun?: () => void,
  back?: boolean | string | Function,
  isPreview?: boolean,
  simulatorStatus?: SimulatorStatus | null
};

const Header = ({ handleLogout, showEndSession, onEndSessionRun, back, isPreview, simulatorStatus }: Props) => {
  const [showNavBar, setShowNavBar] = React.useState(false);
  const history = useHistory();
  const handleGoBack = () => {
    if (back === 'schedule') {
      history.push('/schedule');
    } else if (typeof back === 'function') {
      back();
    } else {
      history.goBack();
    }
  };

  return (
    <>
      {isPreview && !showEndSession && (
        <PreviewRibbon />
      )}

      {isPreview && showEndSession && (
        <VerticalBar color={lightGrey.string()} top={0}>BETA</VerticalBar>
      )}

      {simulatorStatus && (
        <VerticalBar color={SimulatorStatusUtil.getColor(simulatorStatus)} top={isPreview && showEndSession ? 20 : 0}>
          {SimulatorStatusUtil.getMessage(simulatorStatus)}
        </VerticalBar>
      )}

      <NavBar showNavBar={showNavBar} handleLogout={handleLogout} />
      <Background hidden={!showNavBar} onClick={() => setShowNavBar(!showNavBar)} />

      <Wrapper isPreview={isPreview} live={showEndSession} isSimulatorStatus={!!simulatorStatus}>

        {handleLogout && (
          <MenuIcon width="30px" onClick={() => setShowNavBar(!showNavBar)} />
        )}

        {back && (
          <BackIcon
            onClick={handleGoBack}
            hidden={!back}
          />
        )}

        <Title>
          <LogoWrapper onClick={reloadHandler}>
            <Logo />
          </LogoWrapper>
        </Title>
        <EndSession
          onClick={onEndSessionRun}
          hidden={!showEndSession}
        >
          End Session
        </EndSession>

      </Wrapper>
    </>
  );
};

export default Header;

Header.defaultProps = {
  handleLogout: null,
  showEndSession: false,
  onEndSessionRun: null,
  back: null,
  isPreview: config.deployEnv === 'preview',
  simulatorStatus: null,
};

const VerticalBar = styled.div<{ color: string, top: number }>`
  background-color: ${props => props.color};
  position: fixed;
  width: 100%;
  top: ${props => props.top}px;
  height: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  z-index: 12;
`;

function calcWrapperHeight(isPreview?: boolean, live?: boolean, isSimulatorStatus?: boolean): string {
  let result = 0;
  if (isPreview && live) {
    result += 20;
  }
  if (isSimulatorStatus) {
    result += 20;
  }
  return `${result}px`;
}

const Wrapper = styled.header<{ isPreview?: boolean, live?: boolean, isSimulatorStatus?: boolean }>`
  background-color: ${props => props.theme.black.string()};
  color: ${props => props.theme.white.string()};
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: ${props => (calcWrapperHeight(props.isPreview, props.live, props.isSimulatorStatus))};
  box-sizing: border-box;
  width: 100%;
  min-height: ${props => (props.isPreview && props.live ? '65px' : props.theme.headerHeight)};
  padding: 20px 25px;
  z-index: 11;
`;

const LogoWrapper = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;
`;

export const Logo = styled.div`
  background: center center transparent no-repeat url('${LogoSVG}');
  background-size: contain;
  display: inline-block;
  position: fixed;
  left: 50%;
  margin-left: -130px;
  height: 61px;
  width: 259px;
`;

const EndSession = styled.div`
  font-size: 16px;
  line-height: 19px;
  color: #30AEFF;
  cursor: pointer;
`;

const BackIcon = styled.div`
  background: center center transparent no-repeat url('${BackArrow}');
  background-size: contain;
  height: 20px;
  width: 26px;
`;

const MenuIcon = styled.div<{ height?: string, width: string }>`
  background: center center transparent no-repeat url('${menuIcon}');
  background-size: contain;
  display: inline-block;
  height: ${props => props.height || props.width || '100%'};
  width: ${props => props.width || props.height || 'auto'};
`;

const Background = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.4;
  z-index: 12;
`;

// const UserIcon = styled.div`
//   background: center center transparent no-repeat url('${userIcon}');
//   background-size: contain;
//   display: inline-block;
//   height: ${props => props.height || props.width || '100%'};
//   width: ${props => props.width || props.height || 'auto'};
// `;

const Title = styled.div`
  display: flex;
  margin: auto;
`;
