import * as React from 'react';
import styled from 'styled-components';

import { primary, secondary, tertiary } from '../theme/palette';

export const BUTTON_BACKGROUND_GRADIENT = 'background-gradient';
export const BUTTON_BORDER_GRADIENT = 'border-gradient';
export const BUTTON_BORDER_DISABLED = 'border-disabled';
export const BUTTON_BLACK = 'black';
export const BUTTON_LIGHT = 'light';
export const BUTTON_TAB = 'tab';
export const BUTTON_TAB_GRADIENT = 'tab-gradient';

export type ButtonVariationType =
  typeof BUTTON_BACKGROUND_GRADIENT |
  typeof BUTTON_BORDER_GRADIENT |
  typeof BUTTON_BORDER_DISABLED |
  typeof BUTTON_BLACK |
  typeof BUTTON_LIGHT |
  typeof BUTTON_TAB |
  typeof BUTTON_TAB_GRADIENT;

type Props = {
  backgroundColor?: string,
  children?: React.ReactNode,
  disabled?: boolean,
  margin?: string,
  marginBottom?: string,
  marginTop?: string,
  onClick?: (event: React.MouseEvent) => void,
  variation?: ButtonVariationType,
  width?: string,
  height?: string,
};

const ButtonBase = styled.button<Props>`
  background-color: transparent;
  height: ${props => props.height || '50px'};
  width: ${props => props.width || '105px'};
  border: none;
  border-radius: 3px;
  color: ${props => props.theme.button.textColor};
  cursor: pointer;
  font-size: 14px;
  margin: ${props => props.margin || '0.4rem'};
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  padding: 0;
  outline: none;
  transition: background-color ${props => props.theme.transitionTime};
`;

const ButtonBackgroundGradient = styled(ButtonBase)`
  background-image: linear-gradient(
    to right,
    ${primary.string()},
    ${tertiary.string()}
  );
`;

const ButtonBorderGradient = styled(ButtonBase)<Props>`
  background:
    linear-gradient(
      ${props => props.backgroundColor || ''},
      ${props => props.backgroundColor || ''}
    ) padding-box,
    linear-gradient(
      to right,
      ${primary.string()},
      ${tertiary.string()}
    ) border-box;
  border: 2px solid transparent;
`;

const ButtonBorderDisabled = styled(ButtonBase)`
  border: 2px solid ${props => props.theme.textColor.darken(0.6).string()};
`;

const ButtonBlack = styled(ButtonBase)`
  background-color: #000000;
`;

const ButtonLight = styled(ButtonBase)`
  background-color: rgba(255, 255, 255, 0.2);
`;

const ButtonTab = styled(ButtonBase)`
  background-color: #000000;
  border-bottom: 2px solid ${props => props.theme.textColor.darken(0.6).string()};
  border-radius: 0px;
  height: 50px;
  line-height: 20px;
  margin: 0;
`;

const ButtonTabGradient = styled(ButtonTab)`
  background:
    linear-gradient(#000, #000) padding-box,
    linear-gradient(
      to right,
      ${primary.string()},
      ${secondary.string()}
    ) border-box;
  border-bottom: 2px solid transparent;
`;

export const Cancel = styled(ButtonBase)`
  background-color: ${props => props.theme.textColor.darken(0.9).string()};
  border-color: ${props => props.theme.textColor.string()};
  color: ${props => props.theme.textColor.string()};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Button = (props: Props) => {
  const btnProps = {
    ...props,
  };

  delete btnProps.variation;

  let Component = ButtonBackgroundGradient;

  switch (props.variation) {
    case BUTTON_BORDER_GRADIENT:
      Component = ButtonBorderGradient;
      break;

    case BUTTON_BORDER_DISABLED:
      Component = ButtonBorderDisabled;
      break;

    case BUTTON_BLACK:
      Component = ButtonBlack;
      break;

    case BUTTON_LIGHT:
      Component = ButtonLight;
      break;

    case BUTTON_TAB:
      Component = ButtonTab;
      break;

    case BUTTON_TAB_GRADIENT:
      Component = ButtonTabGradient;
      break;

    default:
  }

  return <Component {...btnProps} />;
};

Button.defaultProps = {
  width: '',
  height: '',
  marginTop: '',
  marginBottom: '',
  backgroundColor: '',
  variation: BUTTON_BACKGROUND_GRADIENT,
  margin: '',
  children: null,
  disabled: false,
  onClick: null,
};

export default Button;
