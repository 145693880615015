import api from '../api';
import { routes } from '../utils';
import localStore from '../utils/localStore';

export const isLoginRoute = (): boolean => window.location.pathname === routes.login();

export const isLogoutRoute = (): boolean => window.location.pathname === routes.logout();

export const isInstructorAdminRoute = (): boolean => (
  window.location.pathname === routes.instructorManager()
);

export const storeToken = async (token?: string | null): Promise<boolean | Error> => {
  const result = await localStore.setParam('token', token);

  if (result instanceof Error) {
    return Promise.reject(result);
  }

  return Promise.resolve(true);
};

export const getToken = async (): Promise<string> => {
  const token = await localStore.getParam<string>('token');

  // Suppress errors if any.
  if (token instanceof Error) {
    return Promise.resolve('');
  }

  return Promise.resolve(token);
};

/**
 * Temporary "instructor admin" flag.
 */
const INSTRUCTOR_ADMIN_KEY = 'isia';
export const setIsInstructorAdmin = async (isInstructorAdmin: boolean) => localStore.setParam(
  INSTRUCTOR_ADMIN_KEY,
  isInstructorAdmin,
);

export const getIsInstructorAdmin = async (): Promise<boolean> => {
  const isAdmin = await localStore.getParam<boolean>(INSTRUCTOR_ADMIN_KEY);

  // Suppress errors if any.
  if (isAdmin instanceof Error) {
    return Promise.resolve(false);
  }

  return Promise.resolve(isAdmin);
};

export const createInstructorAccount = async (
  username: string,
  password: string,
  onComplete: Function,
): Promise<void> => {
  try {
    await api.instructors.create(username, password);
    onComplete();
  } catch (error) {
    const errorMessage = 'response' in error
      ? error.response.data
      : error;

    onComplete(errorMessage);
  }
};

/**
 * Force sanitize user name input to lowercase hyphen format
 */
export const sanitizeUsername = (
  input: string,
): string => input.replace(/\s+/g, '-').replace(/-+/g, '-').toLowerCase();
