import React from 'react';
import moment from 'moment';

import * as ui from '../../ui/Form';
// noinspection TypeScriptPreferShortImport
import { ToggleSwitchRow } from '../../ui/form/ToggleSwitch';
import TraineeSelect from './TraineeSelect';
import {
  Button,
  Wrapper as ButtonWrapper,
  Cancel,
} from '../../ui/Button';
import { CourseType, LessonPlanType, LessonPlanVariationType } from '../constants';
import { EquipmentType } from '../../dashboard/constants';
import getEquipmentName from '../../dashboard/utils';

type Props = {
  courses: CourseType[],
  equipment: EquipmentType[],
  isLoading: boolean,
  onCancel: (event: React.FormEvent) => void,
  onSubmit: (event: React.FormEvent) => void,
};

/**
 * Helper function to retrieve the name of a resource.
 */
const getName = (obj: {name: string}): string => obj.name;

const getOptionValue = (lessonPlan: LessonPlanType) => {
  if (lessonPlan.variations) {
    return `lesson-${lessonPlan.id}`;
  }
  return `variation-${lessonPlan.id}`;
};

const SessionForm = ({ courses, equipment, isLoading, onSubmit, onCancel }: Props) => {
  const [pilotFlying, setPilotFlying] = React.useState(null);
  const [pilotMonitoring, setPilotMonitoring] = React.useState(null);
  const [crossTrain, setCrossTrain] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);

  // Initialize form data.
  const scheduledDate = moment().format('Y-MM-DD');
  const scheduledTime = moment()
    .add(1, 'hour')
    .minute(0)
    .second(0)
    .format('HH:mm:ss');

  // Handler for selecting a course.
  const [selectedCourseId, setSelectedCourseId] = React.useState(0);
  const selectedCourse = courses.find(c => c.id === selectedCourseId);
  const handleSelectCourse = (event: React.ChangeEvent) => {
    setSelectedCourseId(parseInt((event.target as HTMLSelectElement).value, 10));
  };

  // Handler for selecting a lesson plan.
  const lessonPlans: Array<LessonPlanType | LessonPlanVariationType> = [];
  if (selectedCourse) {
    selectedCourse.lessonPlans.forEach(lessonPlan => {
      lessonPlans.push(lessonPlan);
      lessonPlan.variations.forEach(variation => {
        lessonPlans.push(variation);
      });
    });
  }

  const [selectedLessonPlanId, setSelectedLessonPlanId] = React.useState<number | string>(0);
  const handleSelectLessonPlan = (event: React.ChangeEvent) => {
    const target = event.target as HTMLSelectElement;
    setSelectedLessonPlanId(target.value);
  };

  // Pick the first course and lesson plan by default once that info
  // is available.
  React.useEffect(() => {
    if (!selectedCourseId && courses.length > 0) {
      setSelectedCourseId(courses[0].id);
      setSelectedLessonPlanId(`lesson-${courses[0].lessonPlans[0].id}`);
    }
  }, [courses, selectedCourseId]);

  const handleChange = (a: any) => {
    setPilotMonitoring(a);
    if (a !== null) {
      setDisabled(false);
      setCrossTrain(true);
    } else {
      setDisabled(true);
      setCrossTrain(false);
    }
  };

  const handleClick = (event: React.ChangeEvent) => {
    setCrossTrain((event.target as HTMLInputElement).checked);
  };

  // Handler for selecting an equipment.
  const [selectedEquipmentId, setSelectedEquipmentId] = React.useState(0);
  const handleSelectEquipment = (event: React.ChangeEvent) => {
    setSelectedEquipmentId(parseInt((event.target as HTMLSelectElement).value, 10));
  };

  // Pick the first equipment by default once that info is available.
  React.useEffect(() => {
    if (!selectedEquipmentId && equipment.length > 0) {
      setSelectedEquipmentId(equipment[0].id);
    }
  }, [equipment, selectedEquipmentId]);

  const crossTrainLabel = crossTrain
    ? 'Create Cross-Training Session'
    : 'Create Single Training Session';

  return (
    <form onSubmit={onSubmit}>
      <ui.ColumnSelect
        label="Course"
        name="course"
        options={courses}
        getOptionLabel={getName}
        value={selectedCourseId}
        onChange={handleSelectCourse}
        disabled={isLoading}
        required
      />

      <ui.ColumnSelect
        label="Lesson Plan"
        name="lessonPlan"
        options={lessonPlans}
        getOptionLabel={getName}
        getOptionValue={getOptionValue}
        value={selectedLessonPlanId}
        onChange={handleSelectLessonPlan}
        disabled={isLoading}
        required
      />

      <ui.InputGroup>
        <TraineeSelect
          label="Pilot Flying"
          name="pilotFlying"
          placeholder="Input name"
          value={pilotFlying}
          onChange={setPilotFlying}
          disabled={isLoading}
          required
        />

        <TraineeSelect
          label="Pilot Monitoring"
          name="pilotMonitoring"
          placeholder="Input name"
          value={pilotMonitoring}
          onChange={handleChange}
          disabled={isLoading}
          required
          isClearable
        />
      </ui.InputGroup>

      <ui.ColumnSelect
        label="Simulator"
        name="equipment"
        options={equipment}
        getOptionLabel={getEquipmentName}
        value={selectedEquipmentId}
        onChange={handleSelectEquipment}
        disabled={isLoading}
        required
      />

      <ui.InputGroup>
        <ui.ColumnInput
          label="Scheduled Date"
          name="scheduledDate"
          type="date"
          value={scheduledDate}
          disabled={isLoading}
          required
        />

        <ui.ColumnInput
          label="Scheduled Time"
          name="scheduledTime"
          type="time"
          value={scheduledTime}
          disabled={isLoading}
          required
        />
      </ui.InputGroup>

      <ToggleSwitchRow
        name="isCrossTrainingSession"
        labelLeft={crossTrainLabel}
        disabled={disabled}
        checked={crossTrain}
        onChange={handleClick}
      />

      <ButtonWrapper>
        <Cancel onClick={onCancel}>CANCEL</Cancel>

        <Button disabled={isLoading}>
          {isLoading ? 'SAVING...' : 'SAVE'}
        </Button>
      </ButtonWrapper>
    </form>
  );
};

export default SessionForm;
