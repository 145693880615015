import { TakeoffInsightType, TYPE_PLOTLY, TYPE_SUMMARY, VisualizationHandlerType } from '../constants';
import getHeightVelocityPlotOptions from '../../plotly/heightVelocity';
import getTakeoffOEIPlotOptions from '../../plotly/plotOptions/takeoffOEI';
import getTakeoffOEIGroundRollPlotOptions from '../../plotly/plotOptions/takeoffOEIGroundRoll';
import getTakeoffOEILateralDirectionPlotOptions from '../../plotly/plotOptions/takeoffOEILateralDirect';

// @ts-ignore
const getTakeoffOEIVisualizations: VisualizationHandlerType<TakeoffInsightType> = (
  activity,
  data,
  markers,
  metaData,
) => {
  const visualizations = [];

  if (data.insight && data.insight.summary && data.insight.summary.payload !== null) {
    const exists = data.insight.summary.payload.some(a => a.score !== null);
    if (exists) {
      visualizations.push({
        type: TYPE_SUMMARY,
        data: data.insight.summary,
      });
    }
  }

  visualizations.push({
    type: TYPE_PLOTLY,
    data: getTakeoffOEIPlotOptions(activity, data, markers, metaData),
  },
  {
    type: TYPE_PLOTLY,
    data: getHeightVelocityPlotOptions(data.frames),
  },
  {
    type: TYPE_PLOTLY,
    data: getTakeoffOEIGroundRollPlotOptions(activity, data, markers, metaData),
  },
  {
    type: TYPE_PLOTLY,
    data: getTakeoffOEILateralDirectionPlotOptions(activity, data, markers, metaData),
  });

  return visualizations;
};

export default getTakeoffOEIVisualizations;
