import { call, Effect, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import * as insightActions from '../insight/actions';
import api from '../api';

function* fetchActivityRecommendations({ payload }: insightActions.GradeContextLoadedAction) {
  const { activityId } = payload;

  try {
    const { activities } = yield call(api.recommendations.activity, activityId);

    if (activities) {
      yield put(actions.updateActivityRecommendations(activities[0]));
    }
  } catch (error) {
    api.logError(error);
  }
}

function* submitInstructorFeedback({ payload }: actions.SubmitInstructorFeedbackAction) {
  const { id, instructorFeedback } = payload;
  const apiCall = api.recommendations.activityFeedback;
  yield call(apiCall, id, instructorFeedback);
}

export default {
  * expectActivityRecommendations(): Generator<Effect, void, void> {
    yield takeLatest(insightActions.GRADE_CONTEXT_LOADED, fetchActivityRecommendations);
  },
  * watchedInstructorFeedback(): Generator<Effect, void, void> {
    yield takeLatest(actions.SUBMIT_INSTRUCTOR_FEEDBACK, submitInstructorFeedback);
  },
};
