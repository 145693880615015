import { GeneralStoreType } from '../store/constants';

export const getRunId = (state: GeneralStoreType) => state.getIn(['training', 'runId']);

export const getActiveMalfunctions = (state: GeneralStoreType) => state.getIn(['training', 'activeMalfunctions']);

export const getPredictedActivityId = (state: GeneralStoreType) => state.getIn(['training', 'predictedActivityId']);

export const getWeatherConditions = (state: GeneralStoreType) => state.getIn(['training', 'weatherConditions']);

export const getSimulatorStatus = (state: GeneralStoreType) => state.getIn(['training', 'simulatorStatus']);
