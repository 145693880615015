import { fromJS } from 'immutable';
import defaultState, { ImmutableScheduleStore } from './store';
import * as actions from './actions';

export default (
  state: ImmutableScheduleStore = defaultState,
  action: actions.ScheduleActions,
): ImmutableScheduleStore => {
  switch (action.type) {
    case actions.FLAGS:
      return state.merge({
        isLoading: action.payload.isLoading,
        isReady: action.payload.isReady,
      });

    case actions.COURSE_PLANS_UPDATED:
    {
      // Map through all courses to order the lesson plans.
      const { plans } = action.payload;
      let courseList = plans;

      if (plans.length > 0) {
        courseList = action.payload.plans.map(coursePlan => {
          coursePlan.lessonPlans.sort((a, b) => a.ordering - b.ordering);

          return coursePlan;
        });
      }

      return state.set('courseList', fromJS(courseList));
    }

    case actions.CREATE_LESSON_PLAN_VARIATION:
    case actions.SCHEDULE_SESSION:
      return state.set('isLoading', true);

    case actions.LESSON_PLAN_VARIATION_CREATED:
    case actions.SCHEDULE_SESSION_COMPLETED:
    {
      if ('error' in action.payload) {
        return state.merge({
          isLoading: false,
          isReady: false,
        });
      }

      return state.merge({
        isLoading: false,
        isReady: true,
      });
    }

    case actions.LESSON_PLAN_UPDATED:
      return state.merge({
        lessonPlan: fromJS(action.payload.fullSession.lessonPlan),
        currentVariationId: fromJS(action.payload.fullSession.lessonPlanVariation),
      });

    case actions.SCHEDULE_ACTIVITY_OPTIONS_UPDATED:
      return state.set('activityOptions', fromJS(action.payload.activityOptions));

    default:
      return state;
  }
};
