import { PlotlyOptions } from '../types';

const attitudesLegend = (options: PlotlyOptions) => {
  const plotOptions = options;
  // find index of roll plotOptions
  const rollPlotIndex = plotOptions.data.findIndex(({ name }) => name === 'roll');
  // find index of pitch plotOptions
  const pitchPlotIndex = plotOptions.data.findIndex(({ name }) => name === 'pitch');

  let yaxis = 0;

  // find yaxis value of attitude plotOptions
  Object.entries(plotOptions.layout).forEach(entry => {
    if (
      entry[0].substr(0, 5) === 'yaxis'
      && entry[1].title !== null
      && entry[1].title.text === 'ATTITUDE'
    ) {
      [yaxis] = entry[1].domain;
    }
  });

  // subplot name to upper case for legend ui purposes
  // @ts-ignore
  plotOptions.data[rollPlotIndex].name = plotOptions.data[rollPlotIndex].name.toUpperCase();
  // @ts-ignore
  plotOptions.data[pitchPlotIndex].name = plotOptions.data[pitchPlotIndex].name.toUpperCase();

  // make legend visible for attitude plotOptions
  plotOptions.data[rollPlotIndex].showlegend = true;
  plotOptions.data[pitchPlotIndex].showlegend = true;

  // align legend with attitude plotOptions
  plotOptions.layout.legend = {
    y: yaxis,
    font: {
      color: 'white',
    },
  };
};

export default attitudesLegend;
