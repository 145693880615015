import { css, keyframes } from 'styled-components';

const borderAnimation = keyframes`
  0% {
    border: 1px solid #f7d747;
    border-radius: 5px;
    box-shadow: 0 0 20px #f7d747;
  }
  100% {
    border: 1px solid black;
  }
`;

const borderAnimationRule = css`
  ${borderAnimation} 2s ease-out
`;

export default borderAnimationRule;
