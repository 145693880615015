import getAirPlotOptions from './air';
import { annotationStickShaker, annotationVRef } from '../annotations';
import { getEngineParam } from '../utils';
import { PlotlyHandlerType } from '../types';

const getLandingFlarePhasePlotOptions: PlotlyHandlerType = (
  frames,
  markers,
  activityCode,
  metaData,
) => {
  const wowNIndex = frames.findIndex(({ data: framesData }) => framesData.wowN === true || framesData.wowN === 1.0);
  const framesFrombeginingToLiftoff = wowNIndex !== 0 ? frames.slice(0, wowNIndex + 1) : frames;
  const plotOptions = getAirPlotOptions(framesFrombeginingToLiftoff, markers, activityCode, metaData,
    ['radalt', 'roc', 'cas', 'pitch', getEngineParam(metaData.aircraftDesignator, metaData.aircraftType)]);

  if (metaData.trainingCenter !== 'NLA') {
    annotationVRef(framesFrombeginingToLiftoff, plotOptions, 'cas');
    annotationStickShaker(framesFrombeginingToLiftoff, plotOptions, 'cas');
  }

  return plotOptions;
};

export default getLandingFlarePhasePlotOptions;
