import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import Activity from './Activity';
import * as actions from '../../actions';
import * as sessionActions from '../../../session/actions';
import * as gradingActions from '../../../summary/actions';
import { ComponentProps, Props } from './types';
import { ActivityType, ActivityOptionType, NoteType } from '../../types';
import { GRADEABLE_ACTIVITY, GradeLevelType, GradeType } from '../../../summary/constants';
import { getOrCreateInstructorGrade } from '../../../summary/utils';
import {
  getActiveMalfunctions,
  getPredictedActivityId,
  getWeatherConditions,
} from '../../../training/selectors';
import { GeneralStoreType } from '../../../store/constants';

const mapStateToProps = (state: GeneralStoreType, { id }: ComponentProps) => {
  const activities = state.get('activities');
  const summary = state.get('summary');
  const malfunctions = getActiveMalfunctions(state);
  const predictedActivityId = getPredictedActivityId(state);
  const weatherConditions = getWeatherConditions(state);

  const { currentRunId } = activities;
  const { activityGrades } = summary;
  const grade = getOrCreateInstructorGrade(activityGrades.get(id) || [], { activityId: id });

  return { currentRunId, grade, malfunctions, predictedActivityId, weatherConditions };
};

const mapDispatchToProps = (dispatch: Function, { currentRunId }: Props) => ({
  onLaunchActivity: (activityId: number) => dispatch(actions.launchActivity(activityId)),
  onLaunchNextActivity: () => dispatch(actions.launchNextActivity()),
  onRepeatActivity: (activityId: number, timestamp: string) => dispatch(
    actions.repeatActivity(activityId, timestamp),
  ),
  onCreateActivity: (
    runId?: number,
    placeholderId?: string,
    activity?: Partial<ActivityType>,
  ) => dispatch(sessionActions.createActivity(runId, placeholderId, activity)),
  onSelectGrade: (grade: GradeType, level: GradeLevelType) => {
    dispatch(gradingActions.updateInstructorGrade(grade, level, currentRunId, GRADEABLE_ACTIVITY));
  },
  onSelectDemo: (activityId: number) => dispatch(actions.setActivityDemo(activityId)),
  onChangeActivity: (activityDetails: ActivityOptionType, activityId: number, runId: number) => {
    dispatch(actions.editActivity(activityDetails, activityId, runId));
  },
  onSaveNote: (
    activityId: number,
    text: string,
    note: NoteType | null,
  ) => dispatch(actions.saveInstructorNote(activityId, text, note)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(withImmutablePropsToJS<Props>(Activity));
