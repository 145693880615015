export * from './event';
export * from './ksa';
export * from './run';

// @deprecated 2019-04-29
export type PilotType = {
  id: number,
  firstName: string,
  lastName: string,
  fullName: string,
};
