import { SimulatorStatus } from '../../training/types';

export class SimulatorStatusUtil {
  public static getMessage(simulatorStatus: SimulatorStatus): string {
    switch (simulatorStatus) {
      case SimulatorStatus.CONNECTED:
        return 'Collecting data from simulator';
      case SimulatorStatus.NOT_CONNECTED:
        return 'Simulator data not received';
      default:
        return '';
    }
  }

  public static getColor(simulatorStatus: SimulatorStatus): string {
    switch (simulatorStatus) {
      case SimulatorStatus.CONNECTED:
        return '#484848';
      case SimulatorStatus.NOT_CONNECTED:
        return '#781212';
      default:
        return '';
    }
  }
}
