import { END } from 'redux-saga';

export interface TrainingSessionEvent {
  activeMalfunctions: string[];
  predictedActivityId: number | null;
  weatherConditions: string[];
}

interface TrainingSessionEventEmitter {
  (input: TrainingSessionEvent | END): void;
}

export interface TrainingSessionListener {
  (emitter: TrainingSessionEventEmitter): () => void;
}

export interface LiveSessionSubscriber {
  (runId: number): TrainingSessionListener;
}

export interface SimulatorStatusResponse {
  status: string | null;
}

interface TrainingSessionSimulatorStatusEventEmitter {
  (input: SimulatorStatusResponse | END): void;
}

export interface TrainingSessionSimulatorStatusListener {
  (emitter: TrainingSessionSimulatorStatusEventEmitter): () => void;
}

export interface LiveSessionSimulatorStatusSubscriber {
  (runId: number): TrainingSessionSimulatorStatusListener;
}

export enum SimulatorStatus {
  CONNECTED = 'connected',
  NOT_CONNECTED = 'not connected'
}
