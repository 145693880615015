import { fromJS, List } from 'immutable';
import * as actions from './actions';
import defaultState, { ImmutableCompetencyStore } from './store';

export default (
  state: ImmutableCompetencyStore = defaultState,
  action: actions.CompetencyAction,
): ImmutableCompetencyStore => {
  switch (action.type) {
    case actions.LOADING_COMPETENCY_SCORES:
      return state.set('isLoading', action.payload.isLoading);

    case actions.SCORES_UPDATED:
      return state.setIn(
        ['scoresByRun', action.payload.runId], fromJS(action.payload.scores),
      ).set('isLoading', false);

    case actions.COMPETENCY_SCORES_LOADED: {
      const { runId, runGrade } = action.payload;

      return state.setIn(['instructorScores', runId], fromJS(runGrade));
    }

    case actions.COMPETENCY_SCORES_UPDATED:
      return state.setIn(['instructorScores', action.payload.runId], fromJS(action.payload.scores));

    case actions.LOADING_COMPETENCY_DRILLDOWN:
      return state.set('isLoadingCompetency', true);

    case actions.COMPETENCY_DRILLDOWN_LOADED: {
      const { norms } = action.payload;

      return norms !== null
        ? state.merge({
          drilldown: fromJS(norms),
          isLoadingCompetency: false,
        })
        : state.merge({
          drilldown: List(),
          isLoadingCompetency: false,
        });
    }

    default:
      return state;
  }
};
