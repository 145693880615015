export const NormCodeToNameMap = new Map([
  // Takeoff Norms
  ['GNDROLL_FWD_COL', 'Ground Roll Forward Column Pressure'],
  ['GNDROLL_HDG', 'Ground Roll HDG Control'],
  ['ROTATION_HDG', 'Rotation HDG Control'],
  ['GEARUP_TIME', 'Gear Up Selection Time'],
  ['SEG2CLIMB_CAS', 'Second Segment Climb CAS'],
  ['SEG2CLIMB_LATACC', 'Balanced Flight After Liftoff'],
  ['PITCH_AFTER_LIFTOFF', 'Pitch Control After Liftoff'],

  // Rejected Takeoff Norms
  ['RTO_REACTION_TIME', 'Reaction Time from Malfunction Activation'],
  ['RTO_PROCEDURE_TIMING', 'RTO Procedure Timing'],
  ['RTO_PROCEDURE_SEQ', 'RTO Procedure Sequence'],
  ['RTO_HDG', 'Heading Deviation'],
  ['RTO_BRAKES_PROFILE', 'Brakes Application'],

  // Landing Norms
  ['IDLE_ALT_LDG', 'Height at Idle Thrust Selection'],
  ['FLARE_CAS', 'Airspeed Through Flare to Touchdown'],
  ['TOUCHDOWN_ROD', 'Touchdown Rate of Descent'],
  ['TOUCHDOWN_PITCH', 'Touchdown Pitch Attitude'],
  ['TOUCHDOWN_CAS', 'Touchdown Airspeed'],
  ['TOUCHDOWN_DIST', 'Touchdown Distance Along Runway'],
  ['SPD_BRK_DEPLOYMENT_TIME', 'Speed Brake Deployment Timing'],
  ['REV_DEPLOYMENT_TIME', 'Airspeed at Thrust Reverser Deployment'],
  ['BRAKES_APPLIED_CAS', 'Airspeed at Brakes Application After Landing'],
  ['ROLLOUT_FWD_COL', 'Rollout Forward Pitch Control'],
  ['ROLLOUT_HDG', 'Rollout Heading Control'],
  ['REV_IDLE_CAS', 'Airspeed at Thrust Reverser Idle'],
  ['BRAKES_RELEASED_GSPD', 'Ground Speed at Brakes Release'],
  ['SPD_BRK_RETRACTED_CAS', 'Speed Brake Retraction After Landing Complete'],

  // Final Approach Norms
  ['APP_FINAL_FLAP_EXT_CAS', 'Airspeed at Landing Flap Selection'],
  ['APP_FINAL_FLAP_EXT_LOCATION', 'Glideslope Deviation at Flap Selection'],
  ['APP_FINAL_GEAR_DN_CAS', 'Airspeed at Gear Down Selection'],
  ['APP_FINAL_GEAR_DN_LOCATION', 'Location at Gear Down Selection'],
  ['APP_FINAL_CAS', 'VRef during Approach'],
  ['APP_FINAL_THRUST', 'Thrust Setting during Approach'],
  ['APP_FINAL_LAT_PATH', 'LOC Deviation'],
  ['APP_FINAL_VERT_PATH', 'Glideslope Deviation'],
  ['APP_FINAL_AP_DISC_ALTITUDE', 'Altitude at AP Disconnect'],
]);

export const NormOrderingFromCodeMap = new Map([
  // Takeoff Norms
  ['GNDROLL_FWD_COL', 1],
  ['GNDROLL_HDG', 2],
  ['ROTATION_HDG', 3],
  ['GEARUP_TIME', 4],
  ['SEG2CLIMB_CAS', 5],
  ['SEG2CLIMB_LATACC', 6],
  ['PITCH_AFTER_LIFTOFF', 7],

  // Rejected Takeoff Norms
  ['RTO_REACTION_TIME', 1],
  ['RTO_PROCEDURE_TIMING', 2],
  ['RTO_PROCEDURE_SEQ', 3],
  ['RTO_HDG', 4],
  ['RTO_BRAKES_PROFILE', 5],

  // Landing Norms
  ['IDLE_ALT_LDG', 1],
  ['FLARE_CAS', 2],
  ['TOUCHDOWN_ROD', 3],
  ['TOUCHDOWN_PITCH', 4],
  ['TOUCHDOWN_CAS', 5],
  ['TOUCHDOWN_DIST', 6],
  ['SPD_BRK_DEPLOYMENT_TIME', 7],
  ['REV_DEPLOYMENT_TIME', 8],
  ['BRAKES_APPLIED_CAS', 9],
  ['ROLLOUT_FWD_COL', 10],
  ['ROLLOUT_HDG', 11],
  ['REV_IDLE_CAS', 12],
  ['BRAKES_RELEASED_GSPD', 13],
  ['SPD_BRK_RETRACTED_CAS', 14],

  // Final Approach Norms
  ['APP_FINAL_FLAP_EXT_CAS', 1],
  ['APP_FINAL_FLAP_EXT_LOCATION', 2],
  ['APP_FINAL_GEAR_DN_CAS', 3],
  ['APP_FINAL_GEAR_DN_LOCATION', 4],
  ['APP_FINAL_CAS', 5],
  ['APP_FINAL_THRUST', 6],
  ['APP_FINAL_LAT_PATH', 7],
  ['APP_FINAL_VERT_PATH', 8],
  ['APP_FINAL_AP_DISC_ALTITUDE', 9],
]);
