import React from 'react';
import styled from 'styled-components';

import { Button, BUTTON_BLACK } from '../../../ui/Button';
import IconRepeat from '../../../ui/svg/Repeat.svg';
import borderAnimationRule from './animations';

type Props = {
  onClick: () => void,
  repeatCount: number,
  isRepeat: boolean,
};

const Repeat = ({ onClick, repeatCount, isRepeat }: Props) => (
  <ButtonWrapper repeatCount={repeatCount} isRepeat={isRepeat}>
    <Button onClick={onClick} variation={BUTTON_BLACK} width="138px" margin="none">
      <IconWrapper>
        <RepeatIcon />
        REPEAT
      </IconWrapper>
    </Button>
  </ButtonWrapper>
);

export default Repeat;

type RepeatProps = {
  repeatCount: number,
  isRepeat: boolean,
};

const ButtonWrapper = styled.div<RepeatProps>`
  animation: ${props => (props.repeatCount ? borderAnimationRule : 'none')};
  border: 1px solid ${props => (props.isRepeat ? '#f7d747' : 'black')};
  border-radius: 3px;
  box-shadow: ${props => (props.isRepeat ? '0 0 20px #f7d747' : '')};
`;

const RepeatIcon = styled.div`
  background: center center transparent no-repeat url('${IconRepeat}');
  height: 20px;
  width: 16px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px 25px;
  line-height: 20px;
`;
