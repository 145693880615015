import { List, Record, RecordOf } from 'immutable';

import {
  CourseType,
  LessonPlanType,
  LessonPlanActivityType,
} from './constants';

export type ScheduleStore = {
  isLoading: boolean,
  isReady: boolean,
  courseList: List<CourseType>,
  lessonPlan?: LessonPlanType,
  activityOptions: List<LessonPlanActivityType>,
  currentVariationId?: number,
};

export type ImmutableScheduleStore = RecordOf<ScheduleStore>;

const createScheduleStore = Record<ScheduleStore>({
  isLoading: false,
  isReady: false,
  courseList: List(),
  activityOptions: List(),
  lessonPlan: undefined,
  currentVariationId: undefined,
});

const defaultState: ImmutableScheduleStore = createScheduleStore({
  isLoading: false,
  isReady: false,
  courseList: List(),
  activityOptions: List(),
  lessonPlan: undefined,
  currentVariationId: undefined,
});

export default defaultState;
