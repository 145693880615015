import { List } from 'immutable';
import defaultState, { ImmutableSessionListStore } from './store';
import * as actions from './actions';

export default (
  state: ImmutableSessionListStore = defaultState,
  action: actions.SessionListAction,
): ImmutableSessionListStore => {
  switch (action.type) {
    case actions.SESSION_LIST_RECEIVED:
    {
      const { sessions, count } = action.payload;

      return state.merge({
        sessions: List(sessions),
        isLoading: false,
        count,
      });
    }

    case actions.EXTENDED_SESSION_LIST_RECEIVED: {
      const { sessions, count } = action.payload;

      return state.merge({
        sessions: List([...state.sessions, ...sessions]),
        isExtendedLoading: false,
        count,
      });
    }

    case actions.LOADING_SESSION_LIST:
    {
      const { isLoading } = action.payload;

      return state.set('isLoading', isLoading);
    }

    case actions.EXTENDED_LOADING_SESSION_LIST: {
      const { isLoading } = action.payload;

      return state.set('isExtendedLoading', isLoading);
    }

    default:
      return state;
  }
};
