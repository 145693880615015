import {
  call,
  put,
  select,
  all,
  take,
  takeLatest,
  Effect,
} from 'redux-saga/effects';
import moment from 'moment';

import * as actions from './actions';
import * as gradingActions from '../summary/actions';
import * as frameActions from '../frames/actions';
import * as trainingActions from '../training/actions';
import * as insightActions from '../insight/actions';
import api from '../api';
import { GeneralStoreType } from '../store/constants';

type ExpectsActivitiesAction =
  | trainingActions.TrainingScreenLoadedAction
  | gradingActions.GradingScreenAction;

function* fetchActivityData(action: ExpectsActivitiesAction) {
  const { sessionId } = action.payload;

  try {
    const runsExpand = ['activities'];

    if (action.type === gradingActions.GRADING_SCREEN_LOADED && action.payload.fetchGradingSheet) {
      runsExpand.splice(0, 1, 'activities.grades');
    }

    const [{ results: sessionRuns }] = yield all([
      call(api.sessions.getRuns, sessionId, runsExpand),
    ]);

    return sessionRuns;
  } catch (error) {
    api.logError(error);

    return [];
  }
}

function* launchActivity({ payload }: actions.LaunchActivityAction) {
  const { id } = payload;

  try {
    yield call(api.activities.startActivity, id);
  } catch (error) {
    api.logError(error);
  }
}

function* launchNextActivity() {
  try {
    // Retrieve current activity.
    const { nextActivityId, map, currentRunId } = yield select((state: GeneralStoreType) => state.get('activities'));

    if (!nextActivityId) {
      return;
    }

    const activities = map.getIn([currentRunId.toString()]);
    const nextActivity = activities.find((activity: any) => activity.get('id') === nextActivityId);

    if (nextActivity.get('startedAt') !== null && nextActivity.get('endedAt') !== null) {
      const timestamp = moment().toISOString();
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      yield call(repeatActivity, actions.repeatActivity(nextActivityId, timestamp));
    } else {
      // Mark next activity as started
      yield call(api.activities.startActivity, nextActivityId);
    }
  } catch (error) {
    api.logError(error);
  }
}

function* repeatActivity({ payload }: actions.RepeatActivityAction) {
  const { id, timestamp } = payload;

  try {
    const activity = yield call(api.activities.repeatActivity, id, {
      timestamp,
    });

    if (activity) {
      yield put(actions.updateRepeatedActivity(id, activity));
    }
  } catch (error) {
    api.logError(error);
  }
}

function* editActivity({ payload }: actions.EditActivityAction) {
  const { activityDetails, currentActivityId, currentRunId } = payload;

  try {
    const editedActivity = yield call(
      api.activities.editActivity,
      activityDetails,
      currentActivityId,
      currentRunId,
    );

    if (editedActivity) {
      yield put(actions.updateEditedActivity(currentActivityId, editedActivity));
    }
  } catch (error) {
    api.logError(error);
  }
}

function* demoActivity({ payload }: actions.SetActivityDemoAction) {
  const { currentActivityId } = payload;

  try {
    const { data } = yield call(api.activities.demo, currentActivityId);

    if (data) {
      yield put(actions.updateActivityDemo(currentActivityId, data));
    }
  } catch (error) {
    api.logError(error);
  }
}

function* saveNote({ payload }: actions.SaveInstructorNoteAction) {
  const { activityId, text, note } = payload;

  const apiRequest = note === null
    ? call(api.activities.createNote, activityId, text)
    : call(api.activities.updateNote, note.id, activityId, text);

  try {
    const { data } = yield apiRequest;

    if (data) {
      yield put(actions.updateInstructorNote(activityId, data.note));
    }
  } catch (error) {
    api.logError(error);
  }
}

export default {
  * expectActivities(): Generator<Effect, void, any> {
    while (true) {
      const action = yield take([
        trainingActions.TRAINING_SCREEN_LOADED,
        gradingActions.GRADING_SCREEN_LOADED,
      ]);

      const activities = yield call(fetchActivityData, action);

      yield all([
        put(actions.updateActivities(activities)),
        put(actions.updateCurrentRun(action.payload.runId)),
      ]);
    }
  },
  * expectGradeContext(): Generator<Effect, void, any> {
    while (true) {
      const action = yield take(insightActions.GRADE_CONTEXT_LOADED);

      const { runId, activityId } = action.payload;

      const activities = yield call(fetchActivityData, action);

      yield all([
        put(actions.updateActivities(activities)),
        put(actions.updateCurrentRun(runId)),
        put(actions.updateCurrentActivity(activityId)),
        put(frameActions.fetchFrames(runId, activityId)),
      ]);
    }
  },
  * watchLaunchActivity(): Generator<Effect, void, void> {
    yield takeLatest(actions.LAUNCH_ACTIVITY, launchActivity);
  },
  * watchLaunchNextActivity(): Generator<Effect, void, void> {
    yield takeLatest(actions.LAUNCH_NEXT_ACTIVITY, launchNextActivity);
  },
  * watchRepeatActivity(): Generator<Effect, void, void> {
    yield takeLatest(actions.REPEAT_ACTIVITY, repeatActivity);
  },
  * watchEditActivity(): Generator<Effect, void, void> {
    yield takeLatest(actions.EDIT_ACTIVITY, editActivity);
  },
  * watchDemoActivity(): Generator<Effect, void, void> {
    yield takeLatest(actions.UPDATE_ACTIVITY_DEMO, demoActivity);
  },
  * watchUpdateNote(): Generator<Effect, void, void> {
    yield takeLatest(actions.SAVE_INSTRUCTOR_NOTE, saveNote);
  },
};
