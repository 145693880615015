import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';

import ResourceNotFound from './ResourceNotFound';
import { dashboardLoaded } from '../actions';
import config from '../../config';
import CreateSession from '../../schedule/components/CreateSessionScreen';
import EditSession from '../../schedule/components/EditSessionScreen';
import GradingScreen from '../../summary/components/GradingScreen';
import GradeContext from '../../summary/components/GradeContext';
import LandingPage from '../../landing/components/LandingPage';
import SessionList from '../../sessionList/components/ListScreen';
import SessionOverview from '../../sessionList/components/SessionOverview';
import SessionRunScreen from '../../session/components/SessionRunScreen';
import TraineeProfile from '../../traineeProfile/components/traineeProfile';
import UserGuide from '../../userGuide/components/UserGuide';
import ViewSession from '../../schedule/components/ViewSessionScreen';

import {
  home,
  schedule,
  createSession,
  viewSession,
  editSession,
  sessionOverview,
  liveSessionRun,
  gradingSheet,
  gradeContext,
  userGuide,
  traineeProfile,
} from '../../utils/routes';

const App = () => {
  const dispatch = useDispatch();
  useEffect(() => { dispatch(dashboardLoaded()); }, [dispatch]);

  return (
    <Router>
      <Wrapper>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{config.appName}</title>
        </Helmet>

        <Switch>
          <Route exact path={home()} component={LandingPage} />
          <Route exact path={schedule()} component={SessionList} />
          <Route exact path={createSession()} component={CreateSession} />
          <Route exact path={viewSession()} component={ViewSession} />
          <Route exact path={editSession()} component={EditSession} />
          <Route exact path={sessionOverview()} component={SessionOverview} />
          <Route exact path={traineeProfile()} component={TraineeProfile} />
          <Route exact path={liveSessionRun()} component={SessionRunScreen} />
          <Route exact path={gradingSheet()} component={GradingScreen} />
          <Route exact path={gradeContext()} component={GradeContext} />
          <Route exact path={userGuide()} component={UserGuide} />

          <Route component={ResourceNotFound} />
        </Switch>
      </Wrapper>
    </Router>
  );
};

const Wrapper = styled.div`
  background-color: ${props => props.theme.background.string()};
  color: ${props => props.theme.textColor.string()};
  display: flex;
  flex-direction: column;
  height: 100vh;

  ::-webkit-scrollbar {
    width: 12px; /* for vertical scrollbars */
    height: 12px; /* for horizontal scrollbars */
  }

  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.theme.accent.string()};
  }
`;

export default App;
