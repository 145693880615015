/* eslint no-underscore-dangle: OFF */
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, createStore } from 'redux';
import config from '../config';
import reducers from '../reducers';
import registerSagasWith from '../registerSagas';

// Redux enhancers
const sagaMiddleware = createSagaMiddleware();
const enhancers = [applyMiddleware(sagaMiddleware)];

// Integrate with Redux Dev Tools for development builds
if ((config.isDev || config.debugEnvs.includes(config.deployEnv)) && window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

// Create the store, with all Redux enhancers
const store = createStore(reducers, compose(...enhancers));

// Run all sagas
registerSagasWith(sagaMiddleware);

export default store;
