type IdType = string | number;

export const home = () => '/';

export const instructorManager = () => '/manage';

export const login = () => ('/login');

export const logout = () => '/logout';

export const schedule = () => '/schedule';
export const createSession = () => '/schedule/new';

export const viewSession = (id: IdType = ':sessionId([0-9]+)') => `/session/view/${id}`;
export const editSession = (id: IdType = ':sessionId([0-9]+)') => `/session/edit/${id}`;
export const sessionOverview = (id: IdType = ':sessionId([0-9]+)') => `/session/${id}`;

export const traineeProfile = (trainee: IdType = ':trainee') => `/trainee/${trainee}`;

export const liveSessionRun = (
  sessionId: IdType = ':sessionId([0-9]+)',
  runId: IdType = ':runId([0-9]+)',
) => `/training/${sessionId}/${runId}`;

export const gradingSheet = (id: IdType = ':id') => `/summary/${id}`;
export const gradeContext = (
  sessionId: IdType = ':sessionId([0-9]+)',
  runId: IdType = ':runId([0-9]+)',
  activityId: IdType = ':activityId([0-9]+)',
) => `/context/${sessionId}/${runId}/${activityId}`;

export const userGuide = () => '/user-guide';

// For backwards compatibility.
export default {
  home,
  instructorManager,
  login,
  logout,
  schedule,
  createSession,
  viewSession,
  editSession,
  sessionOverview,
  liveSessionRun,
  gradingSheet,
};
