import { fromJS, Map } from 'immutable';
import * as actions from './actions';
import { SessionRunType } from './constants';
import defaultState, { ImmutableSessionStore } from './store';

export default (
  state: ImmutableSessionStore = defaultState,
  action: actions.SessionAction,
): ImmutableSessionStore => {
  switch (action.type) {
    case actions.UPDATE_SESSION_DETAILS:
    {
      const {
        id,
        courseName,
        lessonPlan,
        equipment,
        scheduledFor,
        startedAt,
        endedAt,
        lessonPlanName,
      } = action.payload.data;

      const { equipmentId } = equipment || {};
      const { name, shortName } = lessonPlan || {};

      // @ts-ignore
      return state.set('currentSession', Map({
        id,
        courseName,
        name,
        shortName,
        equipmentId,
        scheduledFor,
        startedAt,
        endedAt,
        lessonPlanName,
      }));
    }

    case actions.UPDATE_RUNS:
    {
      const runs: SessionRunType[] = [];

      action.payload.runs.forEach(run => {
        runs.push({
          id: run.id,
          pilotFlying: run.pilotFlying,
          pilotMonitoring: run.pilotMonitoring,
          startedAt: run.startedAt,
          endedAt: run.endedAt,
          completedAt: run.completedAt,
          comment: run.comment,
          markers: run.markers,
        });
      });

      return state.set('runs', fromJS(runs));
    }

    default:
      return state;
  }
};
