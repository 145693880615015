/* eslint max-len: OFF */
/**
 * Service worker, built on Workbox.
 *
 * https://developers.google.com/web/tools/workbox
 * https://create-react-app.dev/docs/making-a-progressive-web-app
 * https://github.com/facebook/create-react-app/blob/master/packages/cra-template-typescript/template/src/serviceWorkerRegistration.ts
 */
import config from './config';

interface ServiceWorkerConfig {
  error: (message: Error | string, details?: any) => void;
  log: (message: string) => void;
  onSuccess?: (registration: ServiceWorkerRegistration) => void;
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
}

const isServiceWorkerSupported = 'serviceWorker' in navigator;

const isLocalhost = Boolean(
  window.location.hostname === 'localhost'
  // [::1] is the IPv6 localhost address.
  || window.location.hostname === '[::1]'
  // 127.0.0.0/8 are considered localhost for IPv4.
  || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

/**
 * Reloads the app when an update is available.
 *
 * We can only know when an update is available by sending a request to
 * Cloudfront, which happens naturally when refreshing the page. In our
 * case, logging out will always cause the browser to fetch the latest version
 * of the app (using `window.location.assign`).
 *
 * Note: we're assuming here that "logging out" is the only action that will
 * cause the browser to fetch the latest version of the app.
 */
const handleUpdateApp = async (sw: ServiceWorkerConfig, registration: ServiceWorkerRegistration) => {
  sw.log('Updating app & Service Worker...');

  // Clear service worker caches.
  const cacheKeys = await window.caches.keys();
  const clearCachePromises: Array<Promise<boolean>> = [];
  cacheKeys.forEach(cacheName => clearCachePromises.push(window.caches.delete(cacheName)));

  await Promise.all(clearCachePromises);

  // Unregister the service worker and reload the app.
  await registration.unregister();
  window.location.reload();
};

const registerServiceWorker = async (sw: ServiceWorkerConfig, url: string) => {
  sw.log(`Registering service worker (v${config.version})...`);

  try {
    const registration = await navigator.serviceWorker.register(url);

    registration.onupdatefound = () => {
      const installingWorker = registration.installing;

      if (installingWorker == null) {
        return;
      }

      installingWorker.onstatechange = () => {
        sw.log(`Installing service worker: ${installingWorker.state}`);

        if (installingWorker.state !== 'installed') {
          return;
        }

        if (navigator.serviceWorker.controller) {
          // At this point, the updated precached content has been fetched,
          // but the previous service worker will still serve the older
          // content until all client tabs are closed.
          sw.log('Dashboard update available.');
          handleUpdateApp(sw, registration);

          if (typeof sw.onUpdate === 'function') {
            sw.onUpdate(registration);
          }
        } else {
          // At this point, everything has been precached.
          // It's the perfect time to display a
          // "Content is cached for offline use." message.
          sw.log('Dashboard is cached for offline use.');

          if (typeof sw.onSuccess === 'function') {
            sw.onSuccess(registration);
          }
        }
      };
    };
  } catch (error) {
    sw.error(`Error during service worker registration: ${String(error)}`, error);
  }
};

const checkValidServiceWorker = async (sw: ServiceWorkerConfig, url: string) => {
  try {
    // Check if the service worker can be found. If it can't reload the page.
    const { headers, status } = await fetch(url, { headers: { 'Service-Worker': 'script' } });
    const contentType = headers.get('content-type');
    const invalidContentType = contentType != null && contentType.indexOf('javascript') === -1;

    if (status === 404 || invalidContentType) {
      const registration = await navigator.serviceWorker.ready;

      await registration.unregister();

      window.location.reload();
    } else {
      await registerServiceWorker(sw, url);
    }
  } catch (error) {
    sw.log('No internet connection found. Dashboard is running in offline mode.');
  }
};

export const register = (sw: ServiceWorkerConfig) => {
  if (config.isDev || !isServiceWorkerSupported) {
    return;
  }

  window.addEventListener('load', async () => {
    const serviceWorkerUrl = `${window.location.origin}/service-worker.js`;

    if (isLocalhost) {
      // This is running on localhost. Let's check if a service worker still exists or not.
      await checkValidServiceWorker(sw, serviceWorkerUrl);

      // Add some additional logging to localhost, pointing developers to the
      // service worker/PWA documentation.
      await navigator.serviceWorker.ready;

      sw.log('This web app is being served cache-first by a service worker. To learn more, visit https://bit.ly/CRA-PWA');
    } else {
      // Is not localhost. Just register service worker.
      await registerServiceWorker(sw, serviceWorkerUrl);
    }
  });
};

export const unregister = async (sw: ServiceWorkerConfig) => {
  if (!isServiceWorkerSupported) {
    return;
  }

  try {
    const registration = await navigator.serviceWorker.ready;
    await registration.unregister();
  } catch (error) {
    sw.error(error);
  }
};
