import * as React from 'react';
import styled from 'styled-components';
import { ActivityListProps } from './types';
import Activity from '../Activity';
import Loading from '../../../ui/Loading';
import { ActivityType } from '../../types';

const ActivityList = ({
  currentRunId,
  activities,
  currentActivityId,
  frames,
  activityOptions,
}: ActivityListProps) => {
  if (!currentRunId || !(currentRunId in activities)) {
    return <Loading />;
  }

  // Placeholder for "Add Activity" trigger.
  // TODO: disabled until we fully support this feature.
  // const activityPlaceholder = {
  //   id: null,
  //   placeholderId: uniqueId('placeholder'),
  //   name: 'Unplanned Activity',
  //   isPlaceholder: true,
  //   isRepeated: false,
  // };

  const activeMalfunctions = frames.length
    ? frames[frames.length - 1].activeMalfunctions.map(name => name.replace(/_/g, ' '))
    : [];

  return (
    <Wrapper>
      <Shade key="shade" />
      <Padding key="padding" />
      {/* {[...activities, activityPlaceholder].map((activity) => { */}
      {/*
      // @ts-ignore */}
      {activities[currentRunId].map((activity: ActivityType) => {
        if (activity.isRepeated) {
          return null;
        }

        const isActive = !!currentActivityId && currentActivityId === activity.id;

        return (
          // @ts-ignore
          <Activity
            {...activity}
            key={activity.placeholderId || activity.id}
            isActive={isActive}
            malfunctions={isActive ? activeMalfunctions : []}
            repeatCount={activity.repeatCount}
            activityOptions={activityOptions}
          />
        );
      })}
    </Wrapper>
  );
};

export default ActivityList;

const Wrapper = styled.section`
  position: relative;
  top: 200px;
`;

const Shade = styled.div`
  background: linear-gradient(black, transparent);
  display: block;

  position: fixed;
  width: 100vw;
  height: 60px;
`;

const Padding = styled.div`
  display: block;
  height: 80px;
`;
