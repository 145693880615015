import { SessionEventType } from './constants';
import { FrameType } from '../frames/constants';
import { TimeBoundResourceType } from '../utils/constants';

/**
 * Type definition for a session-like object that has a start and end time.
 */

export const getRelatedMarkers = (
  { startedAt, endedAt }: TimeBoundResourceType,
  markers: SessionEventType[],
): SessionEventType[] => markers.filter(({ timestamp }) => {
  if (!startedAt || !timestamp) {
    return false;
  }

  const ts = timestamp.toISOString();

  if (startedAt > ts) {
    return false;
  }

  return !(endedAt && endedAt < ts);
});

export const hasStickShaker = ({ data }: FrameType): boolean => (
  !!data.stickshakerActiveR
  || !!data.stickshakerActiveL
  || !!data.stickshakerActive
);
