import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import withImmutablePropsToJS from 'with-immutable-props-to-js';

import TraineeProfile from './traineeProfile';
import * as sessionListActions from '../../../sessionList/actions';
import { GeneralStoreType } from '../../../store/constants';
import { FullSessionType } from '../../../session/constants';

export type TraineeProfileProps = RouteComponentProps<{trainee: string}> & {
  isLoading: boolean,
  sessions: FullSessionType[],
  onComponentMounted: Function,
};

const mapStateToProps = (state: GeneralStoreType) => {
  const sessionList = state.get('sessionList');

  const { isLoading, sessions } = sessionList;

  return {
    isLoading,
    sessions,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  onComponentMounted: () => { dispatch(sessionListActions.sessionListScreenLoaded()); },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-ignore
)(withImmutablePropsToJS(TraineeProfile));
