import React from 'react';
import styled from 'styled-components';
import {
  gradeLevel5,
  gradeLevel9,
  lightestGrey,
  lightGrey,
  mediumGrey,
} from '../../theme/palette';
import { SectionTitle } from '../../summary/components/TaskList';
import { NormType, NormVisualizationType } from '../visualization/constants';
import { NormCodeToNameMap, NormOrderingFromCodeMap } from './constants';

// @ts-ignore
const NormSummary = data => {
  const norms: NormVisualizationType[] = [];

  data.payload.forEach((norm: NormType) => {
    NormCodeToNameMap.get(norm.normCode)
      && norms.push({
        // @ts-ignore
        name: NormCodeToNameMap.get(norm.normCode),
        pass: norm.pass,
        // @ts-ignore
        ordering: NormOrderingFromCodeMap.get(norm.normCode),
      });
  });

  norms.sort((a, b) => a.ordering - b.ordering);
  return (
    <NormCardWrapper>
      <SectionTitle top>Performance Assessment</SectionTitle>
      <NormTitle>
        <LeftColumn />
        <RightColumn>
          <div>OUT OF NORM</div>
          <div>WITHIN NORM</div>
        </RightColumn>
      </NormTitle>
      {norms.map(
        norm => typeof norm.pass === 'boolean' && norm.pass !== null && (
        <Norm key={`${norm.name}_${norm.ordering}`}>
          <LeftColumn>{norm.name}</LeftColumn>
          <RightColumn>
            <Fail fail={typeof norm.pass === 'boolean' && !norm.pass} />
            <Pass pass={typeof norm.pass === 'boolean' && norm.pass} />
          </RightColumn>
        </Norm>
        ),
      )}
    </NormCardWrapper>
  );
};

export default NormSummary;

const NormCardWrapper = styled.div`
  width: 100%;
  margin: 30px auto 0 auto;
  background-color: ${mediumGrey.string()};
  border-radius: 3px;
  max-height: 770px;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const Norm = styled.div`
  display: flex;
  line-height: 60px;
  border-top: 1px solid ${lightGrey.string()};
  font-size: 16px;
`;

const NormTitle = styled(Norm)`
  color: ${lightestGrey.string()};
  font-size: 12px;
  line-height: 30px;
  border-top: 0;
  margin-top: 60px;
  max-width: 100%;
`;

const LeftColumn = styled.div`
  min-width: 60%;
  text-align: left;
  padding-left: 20px;
`;

const RightColumn = styled.div`
  width: 40%;
  display: flex;
  justify-content: space-around;
`;

const Dot = styled.div`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  margin-top: 22.5px;
`;

const Fail = styled(Dot)<{ fail: boolean }>`
  background-color: ${props => (props.fail ? gradeLevel5.string() : '')};
`;

const Pass = styled(Dot)<{ pass: boolean }>`
  background-color: ${props => (props.pass ? gradeLevel9.string() : '')};
`;
