import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

import Header from '../../../ui/headers/Header';
import PageHeader from '../../../ui/PageHeader';
import Loading from '../../../ui/Loading';
import CompetencyRadarPlot from '../../../competency/components/CompetencyRadarPlot';
import { DynamicColumnSelect } from '../../../ui/form/DynamicSelect';
import { TraineeProfileProps } from './index';

type SessionOptionType = {
  session: string,
  sessionRunId: number,
};

const TraineeProfile = ({
  match,
  isLoading,
  sessions,
  onComponentMounted,
}: TraineeProfileProps) => {
  const [traineeName, setTraineeName] = React.useState<string | null>(null);
  const [runId, setRunId] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    onComponentMounted();
  }, [onComponentMounted]);

  if (isLoading) {
    return <Loading />;
  }
  const trainee = parseInt(match.params.trainee, 10);
  const handleSetTraineeName = (t: string) => setTraineeName(t);

  const traineeSessions: Array<{ session: string | undefined, sessionRunId: number | undefined }> = [];

  sessions.forEach(session => {
    if (session.trainees.pilotFlying.id === trainee || session.trainees.pilotMonitoring.id === trainee) {
      if (!session.plannedRuns) {
        return;
      }

      if (traineeName === null) {
        handleSetTraineeName(
          session.trainees.pilotFlying.id === trainee
            ? session.trainees.pilotFlying.name
            : session.trainees.pilotMonitoring.name,
        );
      }
      if (session.trainees.pilotFlying.id === trainee) {
        traineeSessions.push({
          session: `${session.lessonPlanName} - ${moment(String(session.scheduledFor)).format('MMMM Do, h:mm A')}`,
          sessionRunId: session.plannedRuns[0],
        });
      } else {
        traineeSessions.push({
          session: `${session.lessonPlanName} - ${moment(String(session.scheduledFor)).format('MMMM Do, h:mm A')}`,
          sessionRunId: session.plannedRuns[1] ? session.plannedRuns[1] : session.plannedRuns[0],
        });
      }
    }
  });
  if (!runId) {
    setRunId(traineeSessions[0].sessionRunId);
  }

  const getLabel = (session: SessionOptionType): string => session.session;

  const getValue = (session: SessionOptionType): number => session.sessionRunId;

  const handleChangeSession = (session: SessionOptionType) => {
    setRunId(session.sessionRunId);
  };

  return (
    <>
      <Header back />
      <PageHeader title={traineeName} />
      <SelectWrapper>
        <DynamicColumnSelect
          options={traineeSessions}
          isSearchable={false}
          defaultValue={traineeSessions[0]}
          getOptionLabel={getLabel}
          getOptionValue={getValue}
          onChange={handleChangeSession}
        />
      </SelectWrapper>
      {runId
      // @ts-ignore
      && (<CompetencyRadarPlot runId={runId} />)}
    </>
  );
};

export default TraineeProfile;

const SelectWrapper = styled.div`
  width: 50%;
  margin: 160px auto 0 auto;
`;
