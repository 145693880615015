import { connect } from 'react-redux';

import Button from './Button';
import { MarkerButtonProps } from './types';
import * as actions from '../../../../training/actions';

const mapDispatchToProps = (dispatch: Function): MarkerButtonProps => ({
  onRecordEvent: event => dispatch(actions.recordEvent(event)),
});

export default connect(null, mapDispatchToProps)(Button);
