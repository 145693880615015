// @ts-nocheck
import React from 'react';
import { DragDropContext, OnDragStartResponder, OnDragEndResponder } from 'react-beautiful-dnd';
import { NavLink } from 'react-router-dom';
import { Motion, spring } from 'react-motion';
import { throttle } from 'lodash';
import * as Scroll from 'react-scroll';
import styled from 'styled-components';

import ActivityWasteBin from './ActivityWasteBin';
import Title from '../../../ui/head/Title';
import Header from '../../../ui/headers/Header';
import PageHeader from '../../../ui/PageHeader';
import Body from '../../../ui/Body';
import ActivitiesColumn from './ActivitiesColumn';
import Loading from '../../../ui/Loading';
import ScrollToTop from '../../../utils/ScrollToTop';
import { Button, Wrapper as ButtonWrapper } from '../../../ui/Button';
// noinspection TypeScriptPreferShortImport
import { DynamicColumnSelect } from '../../../ui/form/DynamicSelect';
import { sessionOverview, viewSession } from '../../../utils/routes';
import * as alerts from '../../../ui/alerts';

import { EditSessionScreenProps } from './index';
import { LessonPlanActivityType } from '../../constants';

const springConfig = { damping: 14, stiffness: 80 };
const scroll = Scroll.animateScroll;

const enabledBtnStyles = {
  borderRadius: spring(60, springConfig),
  height: spring(60, springConfig),
  width: spring(80, springConfig),
  right: spring(60, springConfig),
  bottom: spring(60, springConfig),
  paddingTop: spring(20, springConfig),
};

const disabledBtnStyles = {
  borderRadius: spring(3, springConfig),
  height: spring(200, springConfig),
  width: spring(650, springConfig),
  right: spring(40, springConfig),
  bottom: spring(80, springConfig),
  paddingTop: spring(10, springConfig),
};

const getName = (activity: LessonPlanActivityType): string => activity.name;
const getValue = (activity: LessonPlanActivityType): number => activity.ordering;
type AddActivityProps = {
  activityOptions: LessonPlanActivityType[],
  onCancel: (event: React.MouseEvent) => void,
  onSubmit: (event: React.FormEvent) => void,
};

const AddActivity = (props: AddActivityProps) => (
  <form onSubmit={props.onSubmit}>
    <DynamicColumnSelect
      label="SELECT ACTIVITY"
      name="selectActivity"
      options={props.activityOptions}
      getOptionLabel={getName}
      getOptionValue={getValue}
      menuPlacement="top"
      required
    />
    <ButtonWrapper>
      <Button onClick={props.onCancel}>CANCEL</Button>
      <Button>ADD</Button>
    </ButtonWrapper>
  </form>
);

const EditSessionScreen = ({
  match,
  history,
  lessonPlan,
  currentVariationId,
  activityOptions,
  onComponentMounted,
  onSaveLessonPlanVariation,
  onDone,
} : EditSessionScreenProps) => {
  const title = 'Edit Session';

  const [newActivitiesOrder, setNewActivitiesOrder] = React.useState<LessonPlanActivityType[]>([]);
  const [addActivity, setAddActivity] = React.useState(false);
  const [isWasteBinVisible, setWasteBinVisibility] = React.useState(false);
  const [wasteBinScrollTrigger, setWasteBinScrollTrigger] = React.useState(window.pageYOffset);

  const scrollListener = throttle(() => {
    // Only trigger the visibility if it's ON and
    // the page has scrolled by 50 pixels or more.
    if (!isWasteBinVisible || Math.abs(window.pageYOffset - wasteBinScrollTrigger) < 50) {
      return;
    }

    setWasteBinVisibility(false);
  }, 1000);

  React.useEffect(() => {
    onComponentMounted(parseInt(match.params.sessionId, 10));
  }, [match, onComponentMounted]);

  React.useEffect(() => {
    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, [scrollListener, isWasteBinVisible, wasteBinScrollTrigger]);

  if (!lessonPlan || Object.entries(lessonPlan).length < 1) {
    return <Loading />;
  }

  let { name, activities } = lessonPlan;

  const currentVariation = lessonPlan.variations.find(({ id }) => id === currentVariationId);
  if (currentVariationId && currentVariation) {
    name = currentVariation.name;
    activities = currentVariation.activities;
  }

  if (newActivitiesOrder.length === 0) {
    setNewActivitiesOrder(activities);
  }

  const onDragStart: OnDragStartResponder = () => {
    setWasteBinVisibility(true);
    setWasteBinScrollTrigger(window.pageYOffset);
  };

  const onDragEnd: OnDragEndResponder = ({ destination, source }) => {
    setWasteBinVisibility(false);

    if (!destination) {
      return;
    }

    switch (destination.droppableId) {
      case 'remove-activity': {
        const activity = newActivitiesOrder[source.index];

        if (!activity) {
          return;
        }

        if (alerts.confirmation(`Remove "${activity.name}" from lesson plan?`)) {
          const updatedList = [...newActivitiesOrder];
          updatedList.splice(source.index, 1);
          setNewActivitiesOrder(updatedList);
        }

        break;
      }

      case 'droppable': {
        if (destination.index === source.index) {
          return;
        }

        const activitiesOrder = [...newActivitiesOrder];
        const splicedActivity = activitiesOrder.splice(source.index, 1);
        activitiesOrder.splice(destination.index, 0, splicedActivity[0]);

        setNewActivitiesOrder(activitiesOrder);
        break;
      }

      default:
    }
  };

  const targetStyle = addActivity ? disabledBtnStyles : enabledBtnStyles;

  activityOptions.forEach((activityOption: LessonPlanActivityType, index: number) => {
    /* eslint-disable-next-line no-param-reassign */ // @ts-ignore
    activityOptions[index].ordering = index + 1;
  });

  const handleClick = () => {
    if (addActivity) {
      return;
    }

    setAddActivity(true);
  };

  const onCancel = () => {
    setAddActivity(false);
  };

  const scrollToBottom = () => {
    scroll.scrollToBottom();
  };

  const handleAddActivity = (event: React.FormEvent) => {
    event.preventDefault();

    const { selectActivity } = event.target as HTMLFormElement;

    const activityId = parseInt(selectActivity.value, 10);
    if (!activityId) {
      alerts.message('Please select an Activity to add.');

      return false;
    }

    const newActivity = {
      code: '',
      name: '',
      ordering: lessonPlan.activities.length + 1,
    };

    activityOptions.forEach((activity: LessonPlanActivityType) => {
      if (activity.ordering === activityId) {
        newActivity.code = activity.code;
        newActivity.name = activity.name;
      }
    });

    lessonPlan.activities.push(newActivity);
    setNewActivitiesOrder(lessonPlan.activities);

    scrollToBottom();

    return setAddActivity(false);
  };

  const handleSaveLessonPlan = (event: React.FormEvent) => {
    event.preventDefault();

    const { lessonPlanVariationName } = event.target as HTMLFormElement;
    const updatedActivities = newActivitiesOrder.map((activity: LessonPlanActivityType, index: number) => ({
      ...activity,
      ordering: index + 1,
    }));

    onDone();

    onSaveLessonPlanVariation({
      lessonPlanId: lessonPlan.id,
      sessionId: match.params.sessionId,
      name: lessonPlanVariationName.value,
      activities: updatedActivities,
      onComplete: () => history.push(sessionOverview(parseInt(match.params.sessionId, 10))),
    });
  };

  return (
    <>
      <ScrollToTop />
      <Title title={title} />
      <Header />
      <PageHeader title={title} />

      <Body>
        <form onSubmit={handleSaveLessonPlan}>
          <SubheaderWrapper>
            <Subheader
              name="lessonPlanVariationName"
              placeholder="Lesson Plan Variation Name"
              defaultValue={`${name} (Variation)`}
            />
          </SubheaderWrapper>

          <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
            <ActivityWasteBin isVisible={isWasteBinVisible} />
            <ActivitiesColumn activities={newActivitiesOrder} />
          </DragDropContext>

          <ButtonWrapper>
            <NavLink to={viewSession(parseInt(match.params.sessionId, 10))}>
              <Button onClick={onDone} width="180px">CANCEL</Button>
            </NavLink>
            <Button width="180px">SAVE</Button>
          </ButtonWrapper>
        </form>
        <Motion style={targetStyle}>
          {style => (
            <AddActivityWrapper
              onClick={handleClick}
              style={{
                borderRadius: `${style.borderRadius}px`,
                height: `${style.height}px`,
                width: `${style.width}px`,
                right: `${style.right}px`,
                bottom: `${style.bottom}px`,
                paddingTop: `${style.paddingTop}px`,
              }}
            >
              {addActivity
                ? (
                  <AddActivity
                    onSubmit={handleAddActivity}
                    activityOptions={activityOptions}
                    onCancel={onCancel}
                  />
                )
                : 'ADD ACTIVITY'}
            </AddActivityWrapper>
          )}
        </Motion>
      </Body>
    </>
  );
};

export default EditSessionScreen;

const SubheaderWrapper = styled.div`
  position: sticky;
  top: 153px;
  background-color: black;
  padding-bottom: 10px;
  width: 100%;
  text-align: center

  // Pull the activity list up, while leaving the waste bin
  // in it's current position.
  margin-bottom: -20vh;
`;

const Subheader = styled.input`
  color: ${props => props.theme.button.textColor};
  background-color: black;
  width: 300px;
  padding: 5px 10px;
`;

const AddActivityWrapper = styled.div`
  background:
    linear-gradient(
      ${props => props.theme.background.string()},
      ${props => props.theme.background.string()}
    ) padding-box,
    linear-gradient(
      to right,
      ${props => props.theme.primary.string()},
      ${props => props.theme.tertiary.string()}
    ) border-box;
  border: 5px solid transparent;
  border-radius: 50%;
  box-shadow: 0 0 10px ${props => props.theme.background.string()};
  color: ${props => props.theme.button.textColor};
  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeightNormal};
  line-height: 1.3em;
  height: 80px;
  width: 80px;
  transition: background-color 1s;
  text-align: center;

  position: fixed;
  right: 90px;
  bottom: 40px;
`;
