const { version } = require('../package.json');

const config = {
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:8000/api',
  appName: 'InstructIQ™',
  bugsnagApiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  clientName: process.env.REACT_APP_CLIENT_NAME || 'paladin',
  datadogConfig: {
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    enabled: process.env.REACT_APP_DATADOG_ENABLED !== 'false',
    forwardErrorsToLogs: true,
    resourceSampleRate: 100,
    sampleRate: 100,
  },
  debugEnvs: ['development', 'local', 'staging'],
  deployEnv: process.env.REACT_APP_PAI_ENV || 'local',
  environment: process.env.NODE_ENV,
  gitHash: process.env.REACT_APP_GIT_HASH || 'dev',
  isDemo: process.env.REACT_APP_PAI_ENV === 'demo',
  isDev: process.env.NODE_ENV !== 'production',
  version,

  // Timeout in milliseconds for transitioning to a new activity during a live
  // training session.
  activityLaunchTimeout: 1000,

  // Timeout in milliseconds for cancelling competency inference polling.
  competencyInferenceTimeout: 150000,

  // Delay in milliseconds to wait before re-enabling the event marker during
  // a live training session.
  eventMarkerTimeout: 2000,

  // Interval in milliseconds to ping the API during a live training session.
  pingRate: 2000,

  // Interval in milliseconds to ping the API for simulator status during a live training session.
  simulatorStatusPingRate: 5000,
};

export default config;
