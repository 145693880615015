import * as Plotly from 'plotly.js';
import { SessionEventType } from '../../../session/constants';
import { FrameType } from '../../../frames/constants';

const getMarker = (
  frames: FrameType[],
  data: Plotly.Data[],
  layout: Partial<Plotly.Layout>,
  markers: SessionEventType[],
) => {
  let markerIndex: number | null = null;

  Object.values(markers).forEach(marker => {
    for (let i = 0; i < frames.length; i++) {
      // @ts-ignore
      if (markerIndex === null && frames[i].timestamp > marker.timestamp.toISOString()) {
        markerIndex = i;

        if (markerIndex === 0) {
          markerIndex = null;
          return;
        }

        const xPaperValue = markerIndex / frames.length;

        let yEnd = 0.95;

        if (data[0].y && data[0].y[0] === null) {
          yEnd = 0.9;
        }

        layout.shapes && layout.shapes.push({
          xref: 'paper',
          yref: 'paper',
          type: 'line',
          x0: xPaperValue,
          x1: xPaperValue,
          y0: 0,
          y1: yEnd,
          line: {
            color: '#2e3192',
            width: 2,
          },
        });
      }
    }
    markerIndex = null;
  });
};

export default getMarker;
