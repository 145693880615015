import { call, put, take, Effect, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as summaryActions from '../summary/actions';
import api from '../api';
import { FullSessionType } from '../session/constants';
import { getInstructorInfo } from '../instructor/sagas';
import { getUserInfo } from '../userProfile/sagas';
import { selectInstructorId } from '../instructor/selectors';
import { currentSessionsCount } from './selectors';
/**
 * Retrieves a list of FullSession records.
 */
type GetListType = Generator<Effect, { results: FullSessionType[], count: number }, any>;
export function* getSessionList({
  payload,
  type,
}: actions.GetFilteredSessionsAction): GetListType {
  try {
    if (payload && payload.sessionId !== undefined) {
      const session = yield call(api.sessions.get, payload.sessionId);
      return { results: [session], count: 1 };
    }
    if (
      type.toString() === actions.LIST_SCREEN_LOADED
      || (payload && payload.showInstructorSessions)
    ) {
      const instructorId = yield select(selectInstructorId);
      const { results, count } = yield call(
        api.sessions.list,
        undefined,
        undefined,
        undefined,
        undefined,
        instructorId,
      );
      return { results, count };
    }
    const { results, count } = yield call(api.sessions.list);
    return { results, count };
  } catch (error) {
    yield call(api.logError, error as Error);
    return { results: [], count: 0 };
  }
}

export function* getExtendedSessionList({ payload, type }: actions.ExtendSessionListAction): GetListType {
  try {
    const offset: number = yield select(currentSessionsCount);

    if (
      type.toString() === actions.LIST_SCREEN_LOADED
      || (payload && payload.showInstructorSessions)
    ) {
      const instructorId = yield select(selectInstructorId);
      const { results, count } = yield call(
        api.sessions.list,
        undefined,
        offset,
        undefined,
        undefined,
        instructorId,
      );
      return { results, count };
    }
    const { results, count } = yield call(api.sessions.list, undefined, offset);
    return { results, count };
  } catch (error) {
    yield call(api.logError, error as Error);
    return { results: [], count: 0 };
  }
}

/**
 * Watchers.
 */
export default {
  * expectsSessionList(): Generator<Effect, void, any> {
    while (true) {
      const action = yield take([
        actions.LIST_SCREEN_LOADED,
        actions.SESSION_OVERVIEW_LOADED,
        summaryActions.GRADING_SCREEN_LOADED,
        actions.GET_FILTERED__SESSIONS,
      ]);

      if (action.type === actions.LIST_SCREEN_LOADED) {
        yield call(getUserInfo);
        yield call(getInstructorInfo);
      }

      if (action.type === actions.EXTEND_SESSION_LIST) {
        yield put(actions.setExtendedLoadingSessionList());
        const { results, count } = yield call(getExtendedSessionList, action);
        yield put(actions.extendedSessionListReceived(results, count));
      } else {
        yield put(actions.setLoadingSessionList());
        const { results, count } = yield call(getSessionList, action);
        yield put(actions.sessionListReceived(results, count));
      }
    }
  },

  * watchExtendSessionList() :Generator<Effect, void, any> {
    while (true) {
      const action = yield take([actions.EXTEND_SESSION_LIST]);

      if (action.type === actions.EXTEND_SESSION_LIST) {
        yield put(actions.setExtendedLoadingSessionList());
        const { results, count } = yield call(getExtendedSessionList, action);
        yield put(actions.extendedSessionListReceived(results, count));
      }
    }
  },
};
