import React from 'react';
import { LandingNormType } from '../../../utils/visualization/constants';

export const getRunwayLines = (
  x: number,
  y1: number,
  y2: number,
  numberOfLines: number,
  lineWidth: number,
  spacing: number,
  centerSpacing: number,
) => {
  const lines = [];

  let newX = x;

  for (let i = 1; i <= numberOfLines; i++) {
    lines.push(
      <line
        x1={newX}
        x2={newX}
        y1={y1}
        y2={y2}
        stroke="#979797"
        strokeWidth={lineWidth}
        strokeLinecap="square"
        key={newX}
      />,
    );

    newX += i === numberOfLines / 2 ? centerSpacing : spacing;
  }

  return lines;
};

export const getRunwayCenterLine = (x: number, y1: number, y2: number) => {
  const lines = [];

  let newY1 = y1;
  let newY2 = y2;

  for (let i = 1; i <= 11; i++) {
    lines.push(
      <line
        x1={x}
        x2={x}
        y1={newY1}
        y2={newY2}
        stroke="#979797"
        strokeLinecap="square"
        key={newY1}
      />,
    );

    newY1 += 49.225474;
    newY2 += 49.225474;
  }

  return lines;
};

export const getColor = (pass: boolean | null) => {
  switch (pass) {
    case true:
      return '#00ff00';

    case false:
      return '#ffbf00';

    default:
      return '#979797';
  }
};

export const getNormMarkers = (
  norm1: LandingNormType,
  xPosition: number,
  getY1Position: Function,
  y2Position: number,
  norm2?: LandingNormType,
) => {
  if (norm2) {
    const color = !norm1.pass || !norm2.pass ? getColor(false) : getColor(true);

    return (
      <>
        <circle
          cx={xPosition}
          cy={getY1Position(norm1.position)}
          r="5"
          fill={color}
        />
        <line
          x1={xPosition}
          y1={getY1Position(norm1.position)}
          x2={xPosition}
          y2={getY1Position(norm2.position)}
          stroke={color}
          strokeLinecap="square"
        />
        <circle
          cx={xPosition}
          cy={getY1Position(norm2.position)}
          r="5"
          fill={color}
        />
      </>
    );
  }

  return (
    <>
      <circle
        cx={xPosition}
        cy={getY1Position(norm1.position)}
        r="5"
        fill={getColor(norm1.pass)}
      />
      <line
        x1={xPosition}
        y1={getY1Position(norm1.position)}
        x2={xPosition}
        y2={y2Position}
        stroke={getColor(norm1.pass)}
        strokeLinecap="square"
      />
    </>
  );
};

export const RunwayContaminantNameMap = new Map([
  [0, 'DRY'],
  [1, 'DAMP'],
  [2, 'WET'],
  [3, 'FLOODED'],
  [4, 'NO WATER DEPTH'],
  [5, 'FLOODED DEPTH'],
  [6, 'MELTING SLUSH'],
  [7, 'FREEZING SLUSH'],
  [8, 'WET SNOW'],
  [9, 'DRY SNOW'],
  [10, 'WET ICE'],
  [11, 'COLD ICE'],
  [12, 'ICY'],
  [13, 'PATCHY WET'],
  [14, 'PATCHY ICE'],
]);

export const RunwayContaminantXMap = new Map([
  [0, 148],
  [1, 143],
  [2, 147],
  [3, 133],
  [4, 109],
  [5, 112],
  [6, 114],
  [7, 111],
  [8, 128],
  [9, 129],
  [10, 135],
  [11, 133],
  [12, 149],
  [13, 123],
  [14, 126],
]);
