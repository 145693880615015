// @ts-nocheck
import React from 'react';
import Body from '../../../ui/Body';
import Form from '../SessionForm';
import Header from '../../../ui/headers/Header';
import PageHeader from '../../../ui/PageHeader';
import { schedule } from '../../../utils/routes';
import Title from '../../../ui/head/Title';
import { CreateSessionScreenProps } from './types';
import Loading from '../../../ui/Loading';
import ContentContainer from '../../../ui/ContentContainer';

const CreateSessionScreen = ({
  courseList,
  equipmentList,
  history,
  isLoading,
  onComponentMounted,
  onScheduleSession,
  onDone,
}: CreateSessionScreenProps) => {
  const title = 'Schedule a New Session';

  // Trigger "component mounted" callback.
  React.useEffect(() => {
    onComponentMounted();
  }, [onComponentMounted]);

  // Cancel handler.
  const cancelHandler = () => {
    // TODO: trigger "cancel" saga, to cancel ongoing sagas and potentially
    // rollback committed changes.
    onDone();
    history.push(schedule());
  };

  // Submit handler.
  const submitHandler = (event: React.FormEvent) => {
    event.preventDefault();

    const {
      course,
      lessonPlan,
      pilotFlying: pilotFlyingInput,
      pilotMonitoring: pilotMonitoringInput,
      equipment,
      scheduledDate,
      scheduledTime,
      isCrossTrainingSession,
    } = event.target as HTMLFormElement;

    const courseId = parseInt(course.value, 10);
    if (!courseId) {
      // eslint-disable-next-line no-alert
      alert('Please select a Course and Lesson Plan.');

      return false;
    }

    const lessonKey = lessonPlan.value.split(/\s*-\s*/g);
    const lessonPlanData = {
      type: lessonKey[0],
      id: parseInt(lessonKey[1], 10),
    };

    let lessonPlanId = lessonPlanData.id;
    let lessonPlanVariationId: number | null = null;

    if (lessonPlanData.type === 'variation') {
      lessonPlanVariationId = lessonPlanData.id;
      courseList.forEach(_course => {
        _course.lessonPlans.forEach(lesson => {
          lesson.variations.forEach(variation => {
            if (variation.id === lessonPlanVariationId) {
              lessonPlanId = lesson.id;
            }
          });
        });
      });
    }

    if (!lessonPlanId) {
      // eslint-disable-next-line no-alert
      alert('Please select a Course and Lesson Plan.');

      return false;
    }

    const pilotFlying = pilotFlyingInput.value.trim();
    const pilotMonitoring = pilotMonitoringInput.value.trim();
    if (!pilotFlying) {
      // eslint-disable-next-line no-alert
      alert('Please select a Pilot Flying.');
      return false;
    }

    if (!pilotMonitoring) {
      // eslint-disable-next-line no-alert
      alert('Please select a Pilot Monitoring.');
      return false;
    }

    const equipmentId = parseInt(equipment.value, 10);
    if (!equipmentId) {
      // eslint-disable-next-line no-alert
      alert('Please select an equipment.');
      return false;
    }

    if (scheduledDate.value.length !== 10) {
      // eslint-disable-next-line no-alert
      alert('Please input a valid date.');

      return false;
    }

    if (scheduledTime.value.length < 5) {
      // eslint-disable-next-line no-alert
      alert('Please input a valid time.');

      return false;
    }

    onScheduleSession({
      course,
      lessonPlanId,
      lessonPlanVariationId,
      pilotFlying,
      pilotMonitoring,
      equipmentId,
      scheduledDate: scheduledDate.value,
      scheduledTime: scheduledTime.value,
      shouldCreateRelatedRun: isCrossTrainingSession.checked,
      onComplete: () => history.push(schedule()),
    });

    return false;
  };

  if (equipmentList.length < 1) {
    return <Loading />;
  }

  return (
    <>
      <Title title={title} />
      <Header />
      <PageHeader title={title} />

      <ContentContainer>
        <Body>
          <Form
            courses={courseList}
            equipment={Object.values(equipmentList)}
            isLoading={isLoading}
            onCancel={cancelHandler}
            onSubmit={submitHandler}
          />
        </Body>
      </ContentContainer>
    </>
  );
};

export default CreateSessionScreen;
