import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled, { DefaultTheme } from 'styled-components';
import { gradeLevel1 } from '../../../theme/palette';
import { ActivityType } from '../../../activities/types';

type Props = {
  activity: Partial<ActivityType>,
  index: number,
};

const Activity = ({ activity, index }: Props) => (
  <Draggable
    draggableId={(activity.ordering && activity.ordering.toString()) || ''}
    index={index}
  >
    {(provided, snapshot) => (
      <ActivityContainer
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
        isDragging={snapshot.isDragging}
        isRemoving={snapshot.draggingOver === 'remove-activity'}
      >
        {activity.name}
        <ScrollHamburger>&#9776;</ScrollHamburger>
      </ActivityContainer>
    )}
  </Draggable>
);

type ActivityContainerProps = {
  isDragging: boolean,
  isRemoving: boolean,
  theme: DefaultTheme,
};

const getBorderColor = (props: ActivityContainerProps): string => {
  switch (true) {
    case props.isRemoving:
      return gradeLevel1.darken(0.2).string();

    case props.isDragging:
      return props.theme.accent.string();

    default:
      return 'transparent';
  }
};

const getBoxShadow = (props: ActivityContainerProps): string => {
  const shadowParams = '0 0 20px ';

  switch (true) {
    case props.isRemoving:
      return `${shadowParams} ${gradeLevel1.darken(0.2).string()}`;

    case props.isDragging:
      return `${shadowParams} ${props.theme.accent.string()}`;

    default:
      return 'none';
  }
};

const ActivityContainer = styled.div<ActivityContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0px 20px;

  background-color: ${props => props.theme.textColor.darken(0.9).string()};
  border: 2px solid ${props => getBorderColor(props)};
  border-radius: ${props => (props.isDragging ? '5px' : 'inherit')};
  box-shadow: ${props => getBoxShadow(props)};
  color: ${props => props.theme.textColor.string()};
  margin: 10px 15vw;
  user-select: none;
  transition: all ${props => props.theme.transitionTime};
`;

const ScrollHamburger = styled.div`
  font-size: 24px;
  color: ${props => props.theme.textColor.darken(0.5).string()};
`;

export default Activity;
