import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import ActivityList from './ActivityList';
import { GeneralStoreType } from '../../../store/constants';
import { ActivityListProps } from './types';

const mapStateToProps = (state: GeneralStoreType) => {
  const activities = state.get('activities');
  const competency = state.get('competency');
  const frames = state.get('frames');

  const { spiderGraph: spiderGraphCompetency } = competency;
  const { list } = frames;

  return {
    activities: activities.get('map'),
    currentActivityId: activities.get('currentActivityId'),
    currentRunId: activities.get('currentRunId'),
    activityOptions: activities.get('activityOptions'),
    spiderGraphCompetency,
    frames: list,
  };
};

export default connect(
  mapStateToProps,
  // @ts-ignore
)(withImmutablePropsToJS<ActivityListProps>(ActivityList));
