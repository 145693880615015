import { connect } from 'react-redux';
import withImmutablePropsToJS from 'with-immutable-props-to-js';
import CreateSessionScreen from './CreateSessionScreen';
import * as actions from '../../actions';
import * as dashboardActions from '../../../dashboard/actions';
import { ScheduleSessionActionProps as ScheduleType } from '../../actions';
import { GeneralStoreType } from '../../../store/constants';

const mapStateToProps = (state: GeneralStoreType) => {
  const schedule = state.get('schedule');
  const dashboard = state.get('dashboard');

  const {
    isLoading,
    isReady,
    courseList,
    lessonPlan,
  } = schedule;

  const { equipment: equipmentList } = dashboard;

  return {
    isLoading,
    isReady,
    courseList,
    lessonPlan,
    equipmentList,
  };
};

const mapDispatchToProps = (dispatch: Function) => ({
  onComponentMounted: () => dispatch(actions.createSessionScreenLoaded()),
  onScheduleSession: (data: ScheduleType) => dispatch(actions.scheduleSession(data)),
  onDone: () => {
    dispatch(actions.setCoursePlans([]));
    dispatch(dashboardActions.equipmentDetailsUpdated([]));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withImmutablePropsToJS(CreateSessionScreen));
