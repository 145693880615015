import React from 'react';
import styled from 'styled-components';

import LogoSpinnerSVG from './svg/LogoSpinner';
import LogoPulseSVG from './svg/LogoPulse';
import { Logo, reloadHandler } from './headers/Header';

export const ANIMATION_SPINNER = 'spinner';
export const ANIMATION_PULSE = 'pulse';

export type LoadingAnimationType =
  typeof ANIMATION_SPINNER |
  typeof ANIMATION_PULSE;

type Props = {
  animation?: LoadingAnimationType,
};

const Loading = (props: Props) => (
  <Wrapper>
    <LoadingHeader>
      <Logo onClick={reloadHandler} />
    </LoadingHeader>
    <LogoWrapper>
      {props.animation === ANIMATION_PULSE
        ? <LogoPulseSVG />
        : <LogoSpinnerSVG />}
    </LogoWrapper>
  </Wrapper>
);

Loading.defaultProps = {
  animation: ANIMATION_PULSE,
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 15;
`;

const LoadingHeader = styled.div`
  height: 85px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LogoWrapper = styled.div`
  height: 50%;
  width: 50%;
  margin: auto;
`;

export default Loading;
