import * as actions from './actions';
import defaultState, { ImmutableUserInfoStore } from './store';

export default (
  state: ImmutableUserInfoStore = defaultState,
  action: actions.UserAction,
): ImmutableUserInfoStore => {
  switch (action.type) {
    case actions.SET_USER_INFO: {
      const { username, id } = action.payload;
      return state.merge({
        username,
        id,
      });
    }

    default:
      return state;
  }
};
